import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './messages-body.scss';

// Components
import { MessagesList } from '../messages-list/messages-list';
import { MessageInput } from '../message-input/message-input';
import { MessagesEmpty } from '../messages-empty/messages-empty';
import { FeedbackModal } from '../../../../modals/feedback-modal/feedback-modal';
import { CheckoutModal } from '../../../../modals/checkout-modal/checkout-modal';
import { ConsultationInfo } from '../../../payment-components';
import { StayWithUsModal } from '../../../../modals/stay-with-us-modal/stay-with-us-modal';

// Store entities
import { getMessagesState } from '../../../../store/messages/messages.selectors';
import { markAsReadMessages, sendPaidMessage } from '../../../../store/messages/messages.thunks';
import { getCoreState } from '../../../../store/core/core.selectors';
import { getCurrencyState } from '../../../../store/currency/currency.selectors';
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";

// Models
import { IMessageItem } from '../../../../interfaces/chat.interfaces';

type TMessagesBodyProps = {};

export const MessagesBody: FC<TMessagesBodyProps> = (
  {}: TMessagesBodyProps
) => {
  const token = localStorage.getItem('token');

  const [isResend, setIsResend] = useState<boolean>(false);
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageItem, setMessageItem] = useState<IMessageItem | null>(null);
  const [files, setFiles] = useState<Array<File | string>>([]);
  const [stayWithUsModalOpen, setStayWithUsModalOpen] = useState(false);

  const {
    chat,
    total,
    loading,
    loadingMessages,
    messages,
  } = useSelector(getMessagesState);
  const { companyInfo } = useSelector(getCoreState);
  const { expert } = useSelector(getCurrentExpertState);
  const { currentCurrency, company_account_id } = useSelector(getCurrencyState);
  const { businessTerms, illustrations } = useSelector(getGlobalConfigState);

  const dispatch = useDispatch<any>();

  const isEmpty = !loading && !loadingMessages && (!total || total === 0);
  const full_free_service_enabled = !!expert?.full_free_service_enabled;

  const workerId = chat?.worker.id;
  const chatId = chat?.id;

  const toggleCheckoutModal = (isPaidClosing: boolean = true): void => {
    if (checkoutOpen && isPaidClosing) {
      setMessageItem(null);
      setMessage('');
    }
    setCheckoutOpen(!checkoutOpen);
  }

  const toggleFeedbackModal = (): void => {
    setFeedbackOpen(!feedbackOpen);
  }

  const sendMessage = async (pi: string): Promise<void> => {
    if (!chatId) return;

    toggleCheckoutModal();
    await dispatch(sendPaidMessage({
      id: chatId,
      message: isResend ? messageItem?.message ?? '' : message,
      files,
      pi,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      route_type: 'chat',
      ...(isResend && { resent_message_id: messageItem?.id })
    }));

    if (!token && total === 0) {
      const timerId = setTimeout(() => {
        setStayWithUsModalOpen(true);
        clearTimeout(timerId);
      }, 3000)
    }
    setMessage('');
    setMessageItem(null);
    setFiles([]);
    setIsResend(false);
  }

  const sendFreeMessage = async (message: string, files: Array<File | string>, resent_message_id?: number): Promise<void> => {
    if (!chatId) return;

    await dispatch(sendPaidMessage({
      id: chatId,
      message,
      files,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      route_type: 'chat',
      ...(resent_message_id && { resent_message_id })
    }));

    if (!token && total === 0) {
      const timerId = setTimeout(() => {
        setStayWithUsModalOpen(true);
        clearTimeout(timerId);
      }, 3000)
    }
    setMessage('');
    setMessageItem(null);
    setFiles([]);
    setIsResend(false);
  }

  const payMessage = (message: string, files: Array<File | string>): void => {
    if (full_free_service_enabled) {
      setMessage(message);
      setMessageItem(null);
      setFiles(files);
      setIsResend(false);
      sendFreeMessage(message, files);
      return;
    }
    toggleCheckoutModal();
    setMessage(message);
    setMessageItem(null);
    setFiles(files);
    setIsResend(false);
  }

  const payResendMessage = (message: IMessageItem, files: Array<File | string>): void => {
    if (full_free_service_enabled) {
      setMessageItem(message);
      setMessage('');
      setFiles(files);
      setIsResend(true);
      sendFreeMessage(message.message, files, message.id);
      return;
    }
    toggleCheckoutModal();
    setMessageItem(message);
    setMessage('');
    setFiles(files);
    setIsResend(true);
  }

  const checkUnreadMessages = (): void => {
    const lastMessage = messages[messages.length] ?? null;
    if (feedbackOpen || !lastMessage?.worker) return;
    toggleFeedbackModal();
  }

  useEffect(() => {
    if (!chatId || !workerId) return;
    dispatch(markAsReadMessages({
      chat_id: chatId,
      workerId,
      temporary_token: localStorage.getItem('temporary_token') ?? ''
    }));
  }, [chatId]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      checkUnreadMessages();
    }, 30000);

    return () => {
      clearTimeout(timerId);
    }
  }, []);

  return (<div className="messages--body">
    { (workerId && chatId) &&
      <>
        <CheckoutModal open={ checkoutOpen }
                       mode={ 'white' }
                       currency={ currentCurrency }
                       company_account_id={ company_account_id }
                       consultation_type={ 'message' }
                       worker_id={ workerId }
                       rate={ chat?.message_rate?.value }
                       btn_text={ `Pay ${ chat?.total_message_price.text } and Send Request` }
                       onCancel={ () => toggleCheckoutModal(false) }
                       onPaid={ sendMessage }>
          <ConsultationInfo type={ 'message' } mode={ 'white' }
                            name={ chat?.worker?.first_name + ' ' + chat?.worker?.last_name }
                            rate={ chat?.message_rate?.text }
                            correspondence_time={ companyInfo?.company?.company_profile.correspondence_time }
          />
        </CheckoutModal>
        <FeedbackModal open={ feedbackOpen }
                       onCancel={ toggleFeedbackModal }
                       consultation_type={ 'message' }
                       worker_id={ workerId }
                       consultation_id={ chatId }/>
      </>
    }

    { (!isEmpty && chatId) &&
      <MessagesList chat_id={ chatId }
                    isActiveCounsellor={ (!!expert?.is_active && !expert?.is_blocked) }
                    messages={ messages }
                    loading={ loadingMessages }
                    total={ total }
                    rate={ chat?.message_rate?.text }
                    resendMessage={ payResendMessage }/>
    }

    { isEmpty && <MessagesEmpty entityName={ businessTerms.Consultant } bgUrl={ illustrations?.correspondence }/> }

    { ((loading && !chatId) || loadingMessages) && <div className="messages--body-loader">
      <SpinElement fullHeight={ true }/>
    </div> }

    { (expert?.is_active && !expert?.is_blocked && !!expert?.has_available_requests) &&
      <MessageInput currValue={ message }
                    placeholder={ `Please provide a description of the request to help the ${ businessTerms.Consultant } understand your question and the purpose of the professional advice` }
                    currency={ currentCurrency }
                    loading={ loading || loadingMessages }
                    full_free_service_enabled={ full_free_service_enabled }
                    totalSpent={ chat?.messages_total_cost }
                    rate={ chat?.message_rate?.text }
                    entityName={ businessTerms.Consultant }
                    showFiles={ false }
                    sendMessage={ payMessage }/>
    }

    <StayWithUsModal open={ stayWithUsModalOpen } onCancel={ () => setStayWithUsModalOpen(false) }/>
  </div>);
}
