import { TLinksInAppointmentsType } from "../types/links-in.types";
import { TServiceType } from "@ppmcore/seven-ppm-core-shared-components-react";

export const CLinksInAppointmentsType: TLinksInAppointmentsType[] = [
  'video',
  'voice',
  // TODO for future deploys
  // 'text-chat'
];

export const CLinksInAppointmentsTypeToServiceType: { [key in TLinksInAppointmentsType]: TServiceType } = {
  video: 'video_call',
  voice: 'audio_call',
  'text-chat': 'conversation',
};
