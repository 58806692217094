import React, { FC, useState } from 'react';
import { Modal } from 'antd';

// Components
import { TuFirstStep } from './tu-first-step/tu-first-step';
import { TuSecondStep } from './tu-second-step/tu-second-step';
import { TuLogIn } from "./tu-log-in/tu-log-in";

// Styles
import './temp-user-sign-up-modal.scss';

// Models
import { TUserMenuActions } from "../../types/user-actions.types";

interface ITempUserSignUpModalProps {
  open: boolean;
  companyCode: string;
  isMessagesAction: boolean;
  type?: TUserMenuActions;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const TempUserSignUpModal: FC<ITempUserSignUpModalProps> = (
  {
    open = false,
    companyCode,
    isMessagesAction,
    type = null,
    onSubmit = () => {},
    onCancel = () => {}
  }: ITempUserSignUpModalProps
) => {

  const [completeFirstStep, setCompleteFirstStep] = useState<boolean>(false);
  const [viewType, setViewType] = useState<'sign-up' | 'sign-in'>('sign-up');

  const completeFirstStepAction = (): void => {
    setCompleteFirstStep(true);
  }

  const onChangeViewTypeHandler = (): void => {
    setViewType(prevState => prevState === "sign-up" ? "sign-in" : "sign-up");
  }

  const onCancelAction = (): void => {
    setCompleteFirstStep(false);
    onCancel();
  }

  return (
    <Modal
      rootClassName="temp-user-sign-up-modal"
      width={ '394px' }
      open={ open }
      centered
      footer={ [] }
      onCancel={ onCancelAction }
    >

      { viewType === 'sign-up' && <div className="modal-body">
        { !completeFirstStep
          && <TuFirstStep
            type={ type }
            companyCode={ companyCode }
            isMessagesAction={ isMessagesAction }
            onChangeViewType={ onChangeViewTypeHandler }
            onSubmit={ completeFirstStepAction }
            onCancel={ onCancelAction }/> }

        { completeFirstStep && <TuSecondStep type={ type } onSubmit={ onSubmit } onCancel={ onCancelAction }/> }
      </div> }

      { viewType === 'sign-in' && <div className="modal-body">
        <TuLogIn onChangeViewType={ onChangeViewTypeHandler }/>
      </div> }
    </Modal>
  )
};
