import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Shared UI Library Components
import {
  CustomButton, CustomIcon, EIconName, InfoMessage,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './book-appointment-checkout-trigger.scss';

// Store entities
import { getCurrencyState } from "../../../../../../store/currency/currency.selectors";
import { getUserState } from "../../../../../../store/user/user.selectors";

// Components
import { CheckoutModal } from "../../../../../../modals/checkout-modal/checkout-modal";
import { SuccessCheckoutModal } from "../../../../../../modals/success-checkout-modal/success-checkout-modal";
import { SuccessCheckout } from "../../../../../payment-components/success-checkout/success-checkout";
import { StayWithUsModal } from "../../../../../../modals/stay-with-us-modal/stay-with-us-modal";
import { StripeFooter } from "../../../../../payment-components";
import { PaypalFooter } from "../../../../../paypal/paypal-footer/paypal-footer";

// Models
import { TCallType } from "../../../../../../interfaces/call.interfaces";
import { IExpertProfile } from "../../../../../../interfaces/experts.interfaces";
import { IAppointment, IAppointmentRate } from "../../../../../../interfaces/appointments.interfaces";
import { IKeywords } from "../../../../../../interfaces/keywords.interfaces";
import { ELocalStorageKeys } from "../../../../../../enums/storage.enums";

type TBookAppointmentCheckoutTriggerConfig = {
  businessTerms: IKeywords,
  name: string,
  cost: string,
  date: string,
  duration: ReactNode,
  period: number,
  rate: IAppointmentRate,
};

export type TBookAppointmentCheckoutTriggerRef = {};

type TBookAppointmentCheckoutTriggerProps = {
  expert: IExpertProfile | null;
  config: TBookAppointmentCheckoutTriggerConfig;
  type?: TCallType;
  loading?: boolean;
  disabled?: boolean;
  onBookAppointment?: (payment_method_nonce?: string) => Promise<IAppointment | null>;
};

export const BookAppointmentCheckoutTrigger = forwardRef<TBookAppointmentCheckoutTriggerRef, TBookAppointmentCheckoutTriggerProps>((
  {
    expert,
    config,
    type = 'video_call',
    disabled = false,
    loading = false,
    onBookAppointment = async () => null,
  }: TBookAppointmentCheckoutTriggerProps,
  _ref
) => {
  const { currentCurrency, company_account_id } = useSelector(getCurrencyState);
  const { user } = useSelector(getUserState);

  const [inlineConfig, setInlineConfig] = useState<TBookAppointmentCheckoutTriggerConfig | null>(null);
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [successCheckoutOpen, setSuccessCheckoutOpen] = useState<boolean>(false);
  const [stayWithUsModalOpen, setStayWithUsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleCheckoutModal = (): void => {
    setCheckoutOpen((prev) => !prev);
  }

  const toggleSuccessCheckoutModal = (): void => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
    if (!temporary_token && successCheckoutOpen) {
      navigate('/appointments');
    }

    setSuccessCheckoutOpen((prev) => !prev);
  }

  const openStayWithUsModel = (): void => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
    if (!temporary_token) return;

    const timerId = setTimeout(() => {
      setStayWithUsModalOpen(true);
      clearTimeout(timerId);
    }, 3000);
  }

  const onOpenCheckoutHandler = async (): Promise<void> => {
    if ((config.rate?.free_minutes_enabled && !config.rate?.prepaid_enabled) || config.rate?.full_free_service_enabled) {
      const payload = await onBookAppointment();
      if (!payload) return;

      const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
      if (!temporary_token) {
        navigate('/appointments');
        return;
      }

      openStayWithUsModel();
      return;
    }

    toggleCheckoutModal();
  }

  const onPaidHandler = async (payment_method_nonce: string): Promise<void> => {
    toggleCheckoutModal();
    const payload = await onBookAppointment(payment_method_nonce);
    if (!payload) return;
    openStayWithUsModel();
    toggleSuccessCheckoutModal();
  }

  const getTitleType = (): { icon: string, text: string } => {
    const types = {
      conversation: { icon: EIconName.Chat, text: 'Text Chat' },
      video_call: { icon: EIconName.CameraOn, text: `Video ${ config.businessTerms.Consultation }` },
      audio_call: { icon: EIconName.Call, text: `Voice ${ config.businessTerms.Consultation }` },
    };

    return types[type as keyof typeof types] || { icon: EIconName.Message, text: 'Correspondence' };
  }

  useEffect(() => {
    if (!config.cost || !config.date) return;
    setInlineConfig(config);
  }, [config]);

  return (
    <>
      <StayWithUsModal open={ stayWithUsModalOpen } onCancel={ () => setStayWithUsModalOpen(false) }/>

      <CheckoutModal open={ checkoutOpen }
                     mode={ 'white' }
                     currency={ currentCurrency }
                     company_account_id={ company_account_id }
                     consultation_type={ type }
                     worker_id={ expert?.id || '' }
                     rate={ config?.rate?.rates?.value }
                     rate_type={ 'pay_per_session' }
                     call_duration={ config?.rate?.call_duration }
                     btn_text={ `Pay ${ inlineConfig?.cost } and Confirm` }
                     onCancel={ toggleCheckoutModal }
                     onPaid={ onPaidHandler }>


        <div className="book-appointment-checkout-trigger--checkout-info">
          <div className="checkout-info--title">
            <CustomIcon name={ getTitleType().icon }/> { getTitleType().text }
          </div>
          <div className="checkout-info--subtitle">
            with { config.name }
          </div>
          <div className="checkout-info--date">
            { config.date }
          </div>
          <div className="checkout-info--cost">
            { config.duration }
          </div>
          <div className="checkout-info--reminder">
            <InfoMessage
              infoMessage={ `The ${ config.businessTerms.Consultant } has a period of <b>${ inlineConfig?.period } minutes</b> to confirm the booking. If the confirmation is not made within this timeframe, the full amount of money will be refunded to you.` }
              mode={ 'white' }/>
          </div>
          <div className="checkout-info--footer">
            { user?.payment_provider === 'stripe' && <StripeFooter/> }
            { user?.payment_provider === 'paypal' && <PaypalFooter/> }
          </div>
        </div>

      </CheckoutModal>

      { inlineConfig && <SuccessCheckoutModal open={ successCheckoutOpen }
                                              onCancel={ toggleSuccessCheckoutModal }
                                              onSubmit={ toggleSuccessCheckoutModal }
      >
        <SuccessCheckout type={ type } config={ inlineConfig }/>
      </SuccessCheckoutModal> }

      <CustomButton disabled={ disabled }
                    loading={ loading }
                    onClick={ onOpenCheckoutHandler }
      >
        Book Appointment
      </CustomButton>
    </>
  );
});
