import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Shared UI Library Components
import {
  CustomIcon,
  CustomTooltip,
  EIconName,
  ListEllipsis,
  Loader
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './back-action.scss';

// Components
import { DeleteFavoriteModal } from '../../../../modals/delete-favorite-modal/delete-favorite-modal';

// Thunks
import { bookmarkWorker } from '../../../../store/currentExpert/currentExpert.thunks';

// Models
import { IExpertProfile } from '../../../../interfaces/experts.interfaces';
import { TSidebarType } from '../../../../types/sidebar.types';
import { IKeywords } from "../../../../interfaces/keywords.interfaces";

interface ILogoBlockProps {
  expert: IExpertProfile;
  businessTerms: IKeywords;
  sidebarType: TSidebarType;
  prevPath?: string;
  isTemporary?: boolean;
}

export const BackAction: FC<ILogoBlockProps> = (
  { expert, businessTerms, sidebarType, prevPath, isTemporary = false }: ILogoBlockProps
): JSX.Element => {

  const [deleteFavoriteOpen, setDeleteFavoriteOpen] = useState<boolean>(false);
  const [makingFavorite, setMakingFavorite] = useState<boolean>(false);

  const params = useParams<{ companyCode?: string, personalLink?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const token = localStorage.getItem('token');
  const emptySpecialities = !expert?.specialities?.length;

  const toggleDeleteFavoriteModal = (): void => {
    setDeleteFavoriteOpen(!deleteFavoriteOpen);
  }

  const makeFavorite = async (): Promise<void> => {
    const currentStatus = expert.is_bookmarked;
    setMakingFavorite(true);
    if (currentStatus) {
      toggleDeleteFavoriteModal();
    }
    await dispatch(bookmarkWorker({
      id: expert.id,
      bookmark: !currentStatus,
      isFavoritesSidebarState: sidebarType === 'favorites'
    }));
    setMakingFavorite(false);
  }

  const checkFavoriteStatus = (): void => {
    if (expert.is_bookmarked) {
      toggleDeleteFavoriteModal();
      return;
    }
    makeFavorite();
  }

  const navigateToBack = (): void => {
    if (params?.companyCode && isTemporary) {
      navigate(`/${ params?.companyCode }`);
      return;
    }

    if (prevPath?.includes('call')) {
      navigate('/home');
      return;
    }
    if (sidebarType === 'home') {
      navigate('/home');
      return;
    }
    if (sidebarType === 'history') {
      navigate('/history');
      return;
    }
    if (sidebarType === 'favorites') {
      navigate('/favorites');
      return;
    }
    navigate(prevPath ?? '');
  }

  return (
    <div className="back-action">

      <DeleteFavoriteModal open={ deleteFavoriteOpen }
                           title={ `Are you sure you want to remove this ${ businessTerms.consultant } from your favorites ${ businessTerms.consultants } list?` }
                           onSubmit={ makeFavorite }
                           onCancel={ toggleDeleteFavoriteModal }/>

      <div className="back-action--arrow" onClick={ navigateToBack }>
        <CustomIcon name={ EIconName.ArrowBackIos }/>
      </div>
      <div className="back-action--info">
        <div className="info-title">
          <CustomTooltip title={ `${ expert.first_name } ${ expert.last_name }` }>
            { expert.first_name } { expert.last_name }
          </CustomTooltip>
        </div>
        { !emptySpecialities && <div className="info-subtitle">
          <ListEllipsis items={ expert?.specialities.map(s => s.name) } hasCounter={ true }/>
        </div> }
      </div>
      { (token && (!!expert?.is_active && !expert?.is_blocked)) && <CustomTooltip placement={ 'bottom' }
                                                                                  title={ expert.is_bookmarked ? 'Remove from Favorites' : 'Add to Favorites' }
                                                                                  className="back-action--mark">
        { !makingFavorite && <CustomIcon name={ expert.is_bookmarked ? EIconName.LikeFill : EIconName.Like }
                                         onClick={ checkFavoriteStatus }/> }
        { makingFavorite && <Loader/> }
      </CustomTooltip> }
    </div>
  )
};
