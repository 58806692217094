import React, { Dispatch, FC } from 'react';
import { usePayPalCardFields } from "@paypal/react-paypal-js";

// Shared UI Library Components
import { CustomButton, TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './submit-payment-paypal.scss';

type TSubmitPaymentPaypalProps = {
  setIsProcess: Dispatch<React.SetStateAction<boolean>>;
  isProcess: boolean;
  disabled?: boolean;
  mode?: TElementMode;
  btnText?: string;
};

export const SubmitPaymentPaypal: FC<TSubmitPaymentPaypalProps> = (
  { isProcess, setIsProcess, disabled = false, mode, btnText = 'Add' }: TSubmitPaymentPaypalProps
) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();

  const onAddPaymentHandler = async (): Promise<void> => {
    if (!cardFieldsForm) {
      const childErrorMessage =
        "Unable to find any child components in the <PayPalCardFieldsProvider />";

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      console.log("The payment form is invalid");
      return;
    }

    try {
      setIsProcess(true);
      await cardFieldsForm?.submit();
      fields.NumberField?.removeClass('display-icon');
      fields.NumberField?.clear();
      fields.ExpiryField?.clear();
      fields.CVVField?.clear();
    } catch (error) {
      console.log('cardFieldsForm error', error);
      setIsProcess(false);
    }
  }

  return (
    <CustomButton text={ btnText }
                  loading={ isProcess }
                  disabled={ isProcess || disabled }
                  onClick={ onAddPaymentHandler }
                  { ...(mode && { mode: mode }) }
    />
  );
}
