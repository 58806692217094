import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './payment-failed.scss';

// Store actions
import { getCallState } from "../../../store/call/call.selectors";
import { getCurrencyState } from "../../../store/currency/currency.selectors";
import { chargeCallTimeslot } from "../../../store/call/call.thunks";
import { getUserState } from "../../../store/user/user.selectors";

// Components
import { PaymentForm } from "../payment-form/payment-form";
import { FailedStatus } from "../failed-status/failed-status";
import { FormProvider } from '../../paypal/form-provider/form-provider';

// Models
import { TCallType } from '../../../interfaces/call.interfaces';
import { IPaymentFailed } from "../../../interfaces/payments.interfaces";

export type TPaymentFailedProps = {
  paymentError: IPaymentFailed;
  onOk?: () => void;
};

export const PaymentFailed: FC<TPaymentFailedProps> = (
  { paymentError, onOk = () => {} }: TPaymentFailedProps
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { callProcess } = useSelector(getCallState);
  const { currentCurrency } = useSelector(getCurrencyState);
  const { user } = useSelector(getUserState);

  const dispatch = useDispatch<any>();

  const onUpdatePayment = async (pi: string): Promise<void> => {
    if (!callProcess?.call_id) return;
    const temporaryToken = localStorage.getItem('temporary_token');
    setLoading(true);

    const { payload } = await dispatch(chargeCallTimeslot({
      call_id: callProcess.call_id,
      payment_method_nonce: pi,
      temporary_token: temporaryToken || ''
    }));

    setLoading(false);

    if (!payload) return;

    onOk();
  }

  return (
    <div className="payment-failed">
      <div className="payment-failed--info">
        <FailedStatus paymentError={ paymentError }/>
      </div>
      <div className="payment-failed--body">
        {
          (callProcess && user?.payment_provider === 'stripe') && <PaymentForm consultation_type={ callProcess.call_type as TCallType }
                                      worker_id={ callProcess.worker?.id as number }
                                      rate={ callProcess.worker_rate?.value }
                                      rate_type={ callProcess.call_session_type }
                                      call_duration={ callProcess.call_session_type === 'pay_per_session' ? callProcess.worker_rate?.call_duration : '' }
                                      currency={ currentCurrency }
                                      mode={ 'white' }
                                      btn_text={ 'Update' }
                                      cancelBtn={ <CustomButton disabled={ loading } type={ 'text' }
                                                                onClick={ onOk }>Ok</CustomButton>
                                      }
                                      onPaid={ onUpdatePayment }
          />
        }
        {
          (callProcess && user?.payment_provider === 'paypal') && <FormProvider consultation_type={ callProcess.call_type as TCallType }
                                      worker_id={ callProcess.worker?.id as number }
                                      rate={ callProcess.worker_rate?.value }
                                      rate_type={ callProcess.call_session_type }
                                      call_duration={ callProcess.call_session_type === 'pay_per_session' ? callProcess.worker_rate?.call_duration : '' }
                                      currency={ currentCurrency }
                                      mode={ 'white' }
                                      btn_text={ 'Update' }
                                      cancelBtn={ <CustomButton disabled={ loading } type={ 'text' }
                                                                onClick={ onOk }>Ok</CustomButton>
                                      }
                                      onPaid={ onUpdatePayment }
          />
        }
      </div>
    </div>
  );
};
