import React, { FC, useState } from 'react';

// Styles
import './auth-steps.scss';

// Components
import { TuFirstStep } from "./tu-first-step/tu-first-step";
import { TuSecondStep } from "./tu-second-step/tu-second-step";
import { TuLogIn } from "./tu-log-in/tu-log-in";

type TAuthStepsProps = {
  companyCode: string;
  onRedirectToService: () => void;
};

export const AuthSteps: FC<TAuthStepsProps> = (
  { companyCode, onRedirectToService }: TAuthStepsProps
): JSX.Element => {
  const [completeFirstStep, setCompleteFirstStep] = useState<boolean>(false);
  const [viewType, setViewType] = useState<'sign-up' | 'sign-in'>('sign-up');

  const completeFirstStepAction = (): void => {
    setCompleteFirstStep(true);
  }

  const onChangeViewTypeHandler = (): void => {
    setViewType(prevState => prevState === "sign-up" ? "sign-in" : "sign-up");
  }

  const onCancelAction = (): void => {
    setCompleteFirstStep(false);
  }

  return (
    <div className="auth-steps">
      { viewType === 'sign-up' && <div className="auth-steps--body">
        { !completeFirstStep
          && <TuFirstStep
            companyCode={ companyCode }
            onChangeViewType={ onChangeViewTypeHandler }
            onSubmit={ completeFirstStepAction }/> }

        { completeFirstStep && <TuSecondStep onSubmit={ onRedirectToService } onCancel={ onCancelAction }/> }
      </div> }

      { viewType === 'sign-in' && <div className="mauth-steps-body">
        <TuLogIn onChangeViewType={ onChangeViewTypeHandler } onSubmit={ onRedirectToService }/>
      </div> }
    </div>
  )
};
