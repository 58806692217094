import React from 'react';
import { useSelector } from "react-redux";

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-not-answered.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const StatusNotAnswered = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;

  return (<CallNotifications
    title={ 'No Answer' }
    text={ `We apologize for the inconvenience. The ${ businessTerms.Consultant } could not respond to your call, but rest assured that the payment for the ${ businessTerms.Consultation } will be refunded to you.` }
    navigateAction={ returnPathname }/>);
}
