import { FC } from 'react';
import { useSelector } from "react-redux";

// Styles
import './transactions-empty.scss';

// Store entities
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";

type TTransactionsEmptyProps = {
  expertPage?: boolean;
};

export const TransactionsEmpty: FC<TTransactionsEmptyProps> = ({ expertPage = false }: TTransactionsEmptyProps): JSX.Element => {
  const { illustrations } = useSelector(getGlobalConfigState);

  return (
    <div className="transactions-empty--inside">
      <div className={ `inside--title ${ expertPage ? 'small-title' : '' }` }>
        Transactions
      </div>
      <div className="inside--subtitle">
        You don’t have any transactions yet. Start communicating!
      </div>
      <div className="inside--img">
        <img src={ illustrations?.transactions } alt={ 'no-transactions-icon' }/>
      </div>
    </div>
  );
};
