import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces
import { IKeywords } from "../../interfaces/keywords.interfaces";
import { IIllustrations } from "../../interfaces/illustrations.interfaces";
import { ELocalStorageKeys } from "../../enums/storage.enums";

// Configs
import { CKeywordsDefault } from "../../constantes/keywords.constants";
import { CIllustrationDefault } from "../../constantes/illustrations.constants";

export interface IGlobalConfigState {
  businessTerms: IKeywords,
  illustrations: IIllustrations,
  loadingTerms: boolean,
  errorTerms: boolean,
  loadingIllustrations: boolean,
  errorIllustrations: boolean,
}

const initialBusinessTerms = localStorage.getItem(ELocalStorageKeys.BusinessTerms);
const initialIllustrations = localStorage.getItem(ELocalStorageKeys.Illustrations);

const initialState: IGlobalConfigState = {
  businessTerms: initialBusinessTerms && initialBusinessTerms !== 'undefined' ? JSON.parse(initialBusinessTerms) : CKeywordsDefault,
  illustrations:  initialIllustrations && initialIllustrations !== 'undefined' ? JSON.parse(initialIllustrations) : CIllustrationDefault,
  loadingTerms: false,
  errorTerms: false,
  loadingIllustrations: false,
  errorIllustrations: false,
};

export const globalConfigSlice = createSlice({
  name: 'globalConfig',
  initialState,
  reducers: {
    updateBusinessTermsFromSocket(state, { payload: businessTerms }: PayloadAction<IKeywords>) {
      state.businessTerms = businessTerms;
    },
    updateIllustrationsFromSocket(state, { payload: illustrations }: PayloadAction<IIllustrations>) {
      state.illustrations = illustrations;
    },
    clearGlobalConfigState(state) {
      state.businessTerms = CKeywordsDefault;
      state.illustrations = CIllustrationDefault;
      state.loadingTerms = false;
      state.errorTerms = false;
      state.loadingIllustrations = false;
      state.errorIllustrations = false;
    },
  }
});

export const GlobalConfigSliceActions = globalConfigSlice.actions;
