import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { UserService } from '../../services/user.service';

// Slices
import { UserSliceActions } from './user.slice';
import { CoreSliceActions } from '../core/core.slice';

// Thunks
import { setLoaderState } from '../loader/loader.thunks';
import { openNotification } from '../app-notifications/app-notifications.thunks';
import {
  fetchCompanies,
  fetchCountries,
  fetchLanguages,
  fetchSpecialities,
  fetchUserCompany, setCompanyColors
} from '../core/core.thunks';
import { fetchExpertsList, setExpertsFilters } from '../experts/experts.thunks';
import { fetchCurrentCurrency } from '../currency/currency.thunks';
import { setSidebarType } from '../sidebar/sidebar.thunks';
import { setBusinessTerms, setIllustrations } from "../global-config/global-config.thunks";
import { fetchPaymentsCards } from "../payments/payments.thunks";

// Models
import { IUserData } from '../../interfaces/user.interfaces';
import { ELocalStorageKeys } from "../../enums/storage.enums";

export enum EUserProfileThunks {
  GetProfile = 'USER/getProfile',
  UpdateProfile = 'USER/updateProfile',
  DeleteProfile = 'USER/deleteProfile',
  UserPhotoUpdate = 'USER/userPhotoUpdate',
  UserPhotoDelete = 'USER/userPhotoDelete',
  UserLogout = 'USER/userLogout',
  UserLogoutNotValidToken = 'USER/userLogoutNotValidToken',
  UserDeleteLogout = 'USER/userDeleteLogout',
  UpdateLastCompany = 'USER/updateLastCompany',
  RefreshStoreEntitiesByCompany = 'USER/refreshStoreEntitiesByCompany',
  SaveUserProfile = 'USER/saveUserProfile',
}

export const getUserProfile = createAsyncThunk(
  EUserProfileThunks.GetProfile,
  async (isTemporary: boolean, { dispatch }: any) => {
    dispatch(UserSliceActions.profileRequest());
    const res = await UserService.profile(isTemporary);
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.profileFailure(res.response.data.error));
      return;
    }
    dispatch(UserSliceActions.profileSuccess(res.data));
    return res.data;
  }
);

export const saveUserProfile = createAsyncThunk(
  EUserProfileThunks.SaveUserProfile,
  async (user: IUserData, { dispatch }: any) => {
    dispatch(UserSliceActions.profileSuccess(user));
  }
);

export const updateProfile = createAsyncThunk(
  EUserProfileThunks.UpdateProfile,
  async (user: IUserData, { dispatch }: any) => {
    dispatch(UserSliceActions.profileUpdateRequest());
    const res = await UserService.updateProfile(user);
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.profileUpdateFailure());
      return res.response.data;
    }
    dispatch(UserSliceActions.profileUpdateSuccess(res.data.user_profile));
    return res.data;
  }
);

export const deleteProfile = createAsyncThunk(
  EUserProfileThunks.DeleteProfile,
  async (_, { dispatch }: any) => {
    dispatch(UserSliceActions.profileDeleteRequest());
    const res = await UserService.deleteProfile();
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.profileDeleteFailure());
      return res.response.data;
    }
    dispatch(UserSliceActions.profileDeleteSuccess());
    return res.data;
  }
);

export const userPhotoUpdate = createAsyncThunk(
  EUserProfileThunks.UserPhotoUpdate,
  async (file: File, { dispatch }) => {
    dispatch(UserSliceActions.updatePhotoRequest());
    const res = await UserService.updatePhoto(file);
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.updatePhotoFailure(res.response.data.error));
      return res.response.data;
    }
    dispatch(UserSliceActions.updatePhotoSuccess(res.data.user_profile));
    return res.data;
  }
);

export const userPhotoDelete = createAsyncThunk(
  EUserProfileThunks.UserPhotoDelete,
  async (_, { dispatch }) => {
    dispatch(UserSliceActions.deletePhotoRequest());
    const res = await UserService.deletePhoto();
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.deletePhotoFailure());
      return res.response.data;
    }
    dispatch(UserSliceActions.deletePhotoSuccess());
    return res.data;
  }
);

export const userLogout = createAsyncThunk(
  EUserProfileThunks.UserLogout,
  async (_, { dispatch }: any) => {
    dispatch(UserSliceActions.logoutRequest());
    const res = await UserService.logout();
    if (res.response && res.response.status !== 200) {
      dispatch(UserSliceActions.logoutFailure());
      return res.response.data;
    }
    const widgetType = localStorage.getItem('widgetType');
    const companyHash = localStorage.getItem('companyHash');
    // localStorage.clear();
    localStorage.smartClear();
    localStorage.setItem('widgetType', widgetType || '');
    localStorage.setItem('companyHash', companyHash || '');
    dispatch(UserSliceActions.logoutSuccess());
    return res.data;
  }
);

export const userDeleteLogout = createAsyncThunk(
  EUserProfileThunks.UserDeleteLogout,
  async (_, { dispatch }: any) => {
    // localStorage.clear();
    localStorage.smartClear();
    dispatch(UserSliceActions.deleteLogoutRequest());
  }
);

export const userLogoutNotValidToken = createAsyncThunk(
  EUserProfileThunks.UserLogoutNotValidToken,
  async (_, { dispatch }: any) => {
    dispatch(UserSliceActions.logoutSuccess());
    localStorage.removeItem('token');
  }
);

export const updateLastCompany = createAsyncThunk(
  EUserProfileThunks.UpdateLastCompany,
  async (company_id: number, { dispatch }: any) => {
    dispatch(setLoaderState({ show: true, text: 'Switching Company, please wait for the process to end!' }));

    const { error, message, company_profile } = await UserService.updateLastCompany(company_id);

    if (company_profile) {
      dispatch(CoreSliceActions.updateSingleCompanyInfo(company_profile));

      dispatch(setCompanyColors({ company_profile, company_code: company_profile.url_code, is_individual: false }));
      dispatch(setBusinessTerms(company_profile?.terminology_settings));
      dispatch(setIllustrations(company_profile?.illustration_photos));

      localStorage.setItem(ELocalStorageKeys.Company, JSON.stringify(company_profile));
    }

    dispatch(setSidebarType({ type: 'home' }));

    await new Promise(res => setTimeout(() => res(true), 1000));

    if (error) {
      dispatch(setLoaderState({ show: false }));
      dispatch(openNotification({ type: 'error', description: error }))
      return null;
    }

    await Promise.all([
      dispatch(getUserProfile(false)),
      dispatch(fetchPaymentsCards()),
      dispatch(fetchUserCompany()),
      dispatch(fetchCompanies()),
      dispatch(fetchLanguages({ company_code: null })),
      dispatch(fetchCountries({ company_code: null })),
      dispatch(fetchCurrentCurrency()),
      dispatch(fetchSpecialities({ company_code: null })),
      dispatch(setExpertsFilters({ filters: { offset: 0, limit: 20 }, type: 'all', company_code: null })),
      dispatch(fetchExpertsList({ filters: { offset: 0, limit: 20 }, type: 'all', isLoadMore: false }))
    ]);

    dispatch(setLoaderState({ show: false }));

    dispatch(openNotification({ type: 'success', description: message }));
    return dispatch(UserSliceActions.changeCompany({ company_id }));
  }
);

export const refreshStoreEntitiesByCompany = createAsyncThunk(
  EUserProfileThunks.RefreshStoreEntitiesByCompany,
  async (_, { dispatch }: any) => {
    dispatch(setLoaderState({ show: true, text: 'Switching Company, please wait for the process to end!' }));
    dispatch(setSidebarType({ type: 'home' }));

    await new Promise(res => setTimeout(() => res(true), 1000));

    window.location.assign(`${ window.location.origin }/home`);

    return true;
  }
);

// export const updateLastCompanyByLink = createAsyncThunk(
//   EUserProfileThunks.UpdateLastCompanyByLink,
//   async ({ company_code }: { company_code: string }, { dispatch }: any) => {
//     dispatch(setLoaderState({ show: true, text: 'Switching Company, please wait for the process to end!' }));
//
//     const data = await CoreService.fetchTempUserCompany(company_code);
//     if (data?.company_profile.id) {
//       const { error, message } = await UserService.updateLastCompany(data.company_profile.id);
//
//       dispatch(setSidebarType({ type: 'home' }));
//
//       await new Promise(res => setTimeout(() => res(true), 1000));
//
//       if (error) {
//         dispatch(setLoaderState({ show: false }));
//         return dispatch(openNotification({ type: 'error', description: error }));
//       }
//
//       await Promise.all([
//         dispatch(fetchLanguages({ company_code: null })),
//         dispatch(fetchCountries({ company_code: null })),
//         dispatch(fetchSpecialities({ company_code: null })),
//         dispatch(setExpertsFilters({ filters: { offset: 0, limit: 20 }, type: 'all', company_code: null })),
//         dispatch(fetchExpertsList({ filters: { offset: 0, limit: 20 }, type: 'all', isLoadMore: false }))
//       ]);
//
//       dispatch(setLoaderState({ show: false }));
//
//       dispatch(openNotification({ type: 'success', description: message }));
//       return dispatch(UserSliceActions.changeCompany({ company_id: data.company_profile.id }));
//     }
//   }
// );
