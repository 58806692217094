import React, { FC, useMemo } from 'react';

// Styles
import './tu-title.scss';

// Models
import { TUserMenuActions } from "../../../../types/user-actions.types";

interface ITuTitleProps {
  type: TUserMenuActions;
  currentStep: string;
  totalSteps: string;
}

export const TuTitle: FC<ITuTitleProps> = ({ type, currentStep, totalSteps }: ITuTitleProps) => {
  const titleMemo = useMemo(() => {
    if (type === 'messages') {
      return 'Send a Paid Message';
    }
    if (type === 'book-a-call') {
      return 'Book an Appointment';
    }
    return 'Start a Paid Consultation';
  }, [type]);

  return (
    <div className={ 'tu-title' }>
      <div className={ 'text' }>{ titleMemo }</div>
      <div className={ 'title-steps' }><span>{ currentStep }</span>/{ totalSteps }</div>
    </div>)
};
