import { FC, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Dropdown, FormInstance, Table, TableProps } from 'antd';
import Column from 'antd/es/table/Column';

// Shared UI Library Components
import {
  CustomIcon,
  EIconName,
  InputSearch,
  ListEllipsis,
  Loader,
  SpinElement,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './transactions-table.scss';

// Components
import { TransactionsFiltersModal } from '../../../../modals/transactions-filters-modal/transactions-filters-modal';
import { RefundModal } from '../../../../modals/refund-modal/refund-modal';
import { TransactionsInfoModal } from '../../../../modals/transactions-info-modal/transactions-info-modal';

// Store
import { getTransactionsState } from '../../../../store/transactions/transactions.selectors';
import {
  getReceiptUrl,
  getTransactions,
  refundTransaction,
  saveTransactionsSetting
} from '../../../../store/transactions/transactions.thunks';
import { getUserState } from '../../../../store/user/user.selectors';
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";
import { TransactionsSliceActions } from '../../../../store/transactions/transactions.slice';

// Models
import { ISorter, ITransactionItem } from '../../../../interfaces/transactions.interfaces';
import { TCallType } from '../../../../interfaces/call.interfaces';
import { ISpeciality } from '../../../../interfaces/core.interfaces';

// Utils
import { getPageSize } from '../../../../utils/transactions.utils';

interface ITransactionsTableProps {
  worker_id?: number;
  expertPage?: boolean;
}

export const TransactionsTable: FC<ITransactionsTableProps> = (
  { worker_id, expertPage }: ITransactionsTableProps
): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [activeRefundId, setActiveRefundId] = useState<number>(0);
  const [activeInvoiceId, setActiveInvoiceId] = useState<string | number | null>(null);
  const [activeTransaction, setActiveTransaction] = useState<ITransactionItem | null>(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const {
    isLoading,
    isFirstLoad,
    total,
    total_all,
    transactionsList,
    settings: transactionsSettings
  } = useSelector(getTransactionsState);
  const { user } = useSelector(getUserState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const {
    date_to,
    date_from,
    show_audio_calls,
    show_calls,
    show_messages
  } = transactionsSettings;
  const hasSettings = !!date_to || !!date_from || show_audio_calls === false || show_calls === false || show_messages === false;

  const dispatch = useDispatch<any>();

  const toggleFiltersModal = (): void => {
    setOpenFilters(!openFilters);
  }

  const onSubmitFiltersHandler = (): void => {
    toggleFiltersModal();
    setPage(1);
  }

  const toggleRefundModal = (): void => {
    setOpenRefund(!openRefund);
  }

  const toggleInfoModal = (): void => {
    setOpenInfo(!openInfo);
  }

  const searchTransactionsHandler = (search: string): void => {
    dispatch(saveTransactionsSetting({
      settings: {
        ...transactionsSettings,
        worker_name: search,
      }
    }));
  }

  const openRefundModal = (id: number): void => {
    toggleRefundModal();
    setActiveRefundId(id);
  }

  const openInfoModal = (transaction: ITransactionItem): void => {
    toggleInfoModal();
    setActiveTransaction(transaction);
  }

  const refundHandler = (reason: string, form: FormInstance): void => {
    toggleRefundModal();
    dispatch(refundTransaction({ payment_id: activeRefundId, refund_comment: reason }));
    form.resetFields();
  }

  const getSortParams = ({ column, order, columnKey }: ISorter): {
    sort_field: string,
    sort_direction: string
  } => {
    if (!column) return {
      sort_field: '',
      sort_direction: '',
    };
    return {
      sort_field: columnKey as string,
      sort_direction: order ? order.slice(0, -3) : '', // 'ascend' | 'descend'
    };
  }

  const sortTableByField = (sorter: ISorter): void => {
    if (!Object.keys(sorter).length) return;
    const sortParams = getSortParams(sorter);
    const settings = { ...transactionsSettings, ...sortParams, offset: 0, ...(worker_id && { worker_id }) };
    dispatch(TransactionsSliceActions.setSettings(settings));
    dispatch(getTransactions({ settings }));
  }

  const onChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter: any,
    extra
  ) => {
    const currentPage = pagination.current ? pagination.current : 1;
    if (page !== currentPage) {
      setPage(currentPage);
      dispatch(getTransactions({
        settings: {
          ...transactionsSettings,
          offset: (currentPage - 1) * getPageSize(expertPage),
          limit: getPageSize(expertPage),
          ...(worker_id && { worker_id })
        }
      }));
      return;
    }
    setPage(1);
    sortTableByField(sorter);
  };

  const getConsultationIconName = (type: TCallType): ReactNode => {
    if (type === 'message') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.Message }/>;
    }
    if (type === 'audio_call') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.MicOn }/>;
    }
    if (type === 'conversation') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.Chat }/>;
    }
    return <CustomIcon size={ 'size-md' } name={ EIconName.CameraOn }/>;
  }

  const openInvoice = async (payment_id: string | number): Promise<void> => {
    setActiveInvoiceId(payment_id);
    const data = await dispatch(getReceiptUrl({ payment_id }));
    if (!data.payload) return setActiveInvoiceId(null);
    window.open(data.payload, '_blank');
    setActiveInvoiceId(null);
  }

  return (
    <div className={ `transactions-table ${ expertPage ? 'transactions-table-expert' : '' }` }>
      <RefundModal open={ openRefund } onCancel={ toggleRefundModal } onSubmit={ refundHandler }/>
      { activeTransaction &&
        <TransactionsInfoModal onCancel={ toggleInfoModal } open={ openInfo } transaction={ activeTransaction }/> }

      { isFirstLoad && <SpinElement fullHeight={ true }/> }
      { !isFirstLoad && <>
        <div className="transactions-table--header">
          <div className={ `header-title ${ expertPage ? 'small-title' : '' }` }>
            Transactions
          </div>
          <div className="header-search" hidden={ !total_all || !!worker_id || expertPage }>
            <InputSearch placeholder={ `Search by ${ businessTerms.Consultant }` } loading={ isLoading }
                         onDebounceChange={ searchTransactionsHandler }/>
          </div>
          <div className={ `header-filters ${ hasSettings ? 'has-filters' : '' }` } hidden={ !total_all }>
            { !!user?.created_at && <TransactionsFiltersModal userCreatedAt={ user?.created_at } open={ openFilters }
                                                              onCancel={ toggleFiltersModal }
                                                              onSubmit={ onSubmitFiltersHandler }
                                                              settings={ transactionsSettings }
                                                              worker_id={ worker_id }/> }
            <CustomIcon name={ EIconName.Filters } onClick={ toggleFiltersModal }/>
          </div>
        </div>
        <div className="transactions-table--body" hidden={ !total_all }>
          <Table
            loading={ isLoading }
            locale={ { emptyText: 'No matching items' } }
            dataSource={ transactionsList }
            rowKey="id"
            pagination={ total > getPageSize(expertPage) ? {
              current: page,
              pageSize: getPageSize(expertPage),
              total,
              showSizeChanger: false,
              position: ['bottomRight']
            } : false }
            onChange={ onChange }>

            <Column
              title="Date"
              className={ 'date' }
              key={ 'created_at' }
              dataIndex={ 'created_at' }
              sorter={ true }
              showSorterTooltip={ false }
              width="15%"
              render={ (created_at) => <div className="date--body">
                <div className="body-item">{ dayjs(created_at).format('DD MMM YYYY') }</div>
              </div> }
            />

            { !expertPage && <Column
              title={ businessTerms.Consultant }
              className={ 'counsellor' }
              key={ 'worker_name' }
              dataIndex={ 'worker' }
              sorter={ true }
              showSorterTooltip={ false }
              width={ '35%' }
              render={ (worker) => <div className="counsellor--body">
                <div className="body-avatar">
                  <UserAvatar avatarUrl={ worker?.photo } sizeClass={ 'small' } hasVideo={ false }/>
                </div>
                <div className="body-info">
                  <Link to={ `/expert/${ worker?.id }` }
                        className="body-info--name">{ worker.first_name + ' ' + worker.last_name }</Link>
                  <ListEllipsis items={ worker?.specialities?.map((spec: ISpeciality) => spec?.name) }
                                hasCounter={ true }/>
                </div>
              </div> }
            /> }

            <Column
              title="Service Details"
              className={ 'details' }
              key={ 'details' }
              width={ expertPage ? '65%' : '25%' }
              render={ ({ duration, created_at, total, consultation_type, free_duration }) => <div className="details--body">
                <div className="body--item">
                  <span>{ getConsultationIconName(consultation_type) }</span>
                  <span className="bold-text">{ dayjs(created_at).format('DD MMM YYYY') }</span>
                  <span>{ dayjs(created_at).format('hh:mm A') }</span>
                  { duration?.text && <span>{ duration?.text }</span> }
                  { total?.text && <span className="bold-text">{ total?.text }</span> }
                  { free_duration && <div className="bold-text">
                    Free: { free_duration }
                  </div> }
                </div>
              </div> }
            />

            <Column
              title="Amount"
              className={ 'cost' }
              key={ 'cost' }
              dataIndex={ 'total' }
              width="12%"
              render={ (total) => total?.text }
            />

            <Column
              title="Actions"
              className={ 'actions' }
              key={ 'actions' }
              align={ 'center' }
              width="10%"
              render={ (transaction) => <div className="actions--body">
                { !!transaction.refund_status &&
                  <div className={ `body--item ${ transaction.refund_status }` }>
                    <CustomIcon name={ EIconName.Info } onClick={ () => openInfoModal(transaction) }/>
                  </div> }

                { <div className="body--item actions">
                  { activeInvoiceId === transaction.id ? <Loader className={ 'actions-spin' }/> : <Dropdown menu={ {
                    items: [
                      // {
                      //   key: 'refund',
                      //   label: (
                      //     <div className="refund-menu-item">
                      //       <CustomIcon name={ EIconName.Refund } size={'size-md'}/>
                      //       Request Refund
                      //     </div>),
                      //   disabled: transaction.is_refund_requested,
                      //   onClick: () => openRefundModal(transaction.id)
                      // },
                      {
                        key: 'invoice',
                        label: (
                          <div className="refund-menu-item">
                            <CustomIcon size={ 'size-lg' } name={ EIconName.ReceiptOff }/>
                            See Invoice
                          </div>),
                        onClick: () => openInvoice(transaction.id)
                      },
                    ]
                  } } trigger={ ['click'] } placement="bottomRight" arrow>
                    <CustomIcon name={ EIconName.BurgerMenu }/>
                  </Dropdown> }
                </div> }
              </div> }
            />
          </Table>
        </div>
      </> }
    </div>
  );
};
