import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { CustomIcon, DiscardModal, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './menu-list.scss';

// Store entities
import { userLogout } from '../../../../../../store/user/user.thunks';
import { openNotification } from '../../../../../../store/app-notifications/app-notifications.thunks';
import { getCoreState } from '../../../../../../store/core/core.selectors';

// Components
import { MenuItem } from '../menu-item/menu-item';

// Models
import { ERightMenuType } from '../../right-menu.enums';
import {
  CRightTopMenu,
  CRightCenterMenu,
  CRightBottomMenu
} from '../../../../../../configs/components/menu-right.config';

interface IMenuListProps {
  menuType: ERightMenuType,
  unreadCount: number;
  currentPath: string;
  withLabels?: boolean;
  onClickMenuItem?: () => void;
}

export const MenuList: FC<IMenuListProps> = ({
    menuType,
    unreadCount,
    currentPath,
    withLabels = false,
    onClickMenuItem = () => {}
  }: IMenuListProps
): JSX.Element => {

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const params = useParams<{ companyCode?: string, personalLink?: string }>();

  const [discardModalOpen, setDiscardModalOpen] = useState<boolean>(false);
  const [notDivideMenu, setNotDivideMenu] = useState<boolean>(false);

  const { companyInfo } = useSelector(getCoreState);

  const getDynamicActiveIcon = (path: string, icon: ReactNode, isActive: boolean): ReactNode => {
    if (path === 'notifications' && !!unreadCount && !isActive) {
      return <CustomIcon name={ EIconName.NotificationExist }/>
    }
    return icon;
  }

  useEffect(() => {
    menuResizeHandler();
    window.addEventListener('resize', menuResizeHandler);

    return () => {
      window.removeEventListener('resize', menuResizeHandler);
    };
  }, []);

  const menuResizeHandler = () => {
    setNotDivideMenu(window.innerHeight < 732);
  }

  const changeMenuHandler = (path: string, label: string) => {
    onClickMenuItem();

    if (path === 'logout') {
      setDiscardModalOpen(true);
      return;
    }
    //
    // if (params?.companyCode) {
    //   navigate(`/${ path }`);
    //   return;
    // }
    //
    navigate(`/${ path }`);
  }

  const onCancel = (value: boolean) => {
    if (value) {
      setDiscardModalOpen(false);

      dispatch(userLogout()).then((res: any) => {
        if (res.payload?.error) {
          dispatch(openNotification({
            description: res.payload.error,
            type: 'error'
          }));
          return;
        }

        if (companyInfo?.company?.company_profile?.url_code) {
          navigate(`/${ companyInfo?.company?.company_profile?.url_code }`)
          return;
        }
        navigate('/sign-in');
      });
    }
    setDiscardModalOpen(false);
  }

  return (
    <div className={ `menu-list ${ notDivideMenu ? 'menu-list-not-divided' : '' }` }>
      { CRightTopMenu && <div className={ 'section-item top-section' }>
        { CRightTopMenu.map(({ icon, path, activeIcon, label }, idx) => {
          const isActive = currentPath.includes('transactions') ? path === currentPath : currentPath.includes(path);
          const dynamicIcon = getDynamicActiveIcon(path, icon, isActive);
          return (
            <MenuItem
              key={ idx }
              icon={ dynamicIcon }
              path={ path }
              activeIcon={ activeIcon }
              label={ label }
              withLabels={ withLabels }
              isActive={ isActive }
              onClickMenuItem={ changeMenuHandler }/>
          )
        }) }
      </div> }

      { CRightCenterMenu && <div className={ 'section-item center-section' }>
        { CRightCenterMenu.map(({ icon, path, activeIcon, label }, idx) => {
          const isActive = currentPath.includes(path);
          return (
            <MenuItem
              key={ idx }
              icon={ icon }
              path={ path }
              activeIcon={ activeIcon }
              label={ label }
              withLabels={ withLabels }
              isActive={ isActive }
              onClickMenuItem={ changeMenuHandler }/>
          )
        }) }
      </div> }

      { CRightBottomMenu && <div className={ 'section-item bottom-section' }>
        { CRightBottomMenu.map(({ icon, path, activeIcon, label }, idx) => {
          const isActive = currentPath.includes(path);
          return (
            <MenuItem
              key={ idx }
              icon={ icon }
              path={ path }
              activeIcon={ activeIcon }
              label={ label }
              withLabels={ withLabels }
              isActive={ isActive }
              onClickMenuItem={ changeMenuHandler }/>
          )
        }) }
      </div> }

      <DiscardModal open={ discardModalOpen }
                    onCancel={ (value) => onCancel(value) }
                    title={ 'Are you sure you want to log out?' }/>
    </div>
  )
};
