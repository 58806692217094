import React, { useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import {
  CustomButton,
  defaultValidateMessages,
  DiscardModal,
  InputPassword,
  passwordPattern,
  PasswordValidationTooltip, ServerErrors
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Thunks
import { clearAuthState, signUpUser } from '../../../store/auth/auth.thunks';

// Styles
import './sign-up-confirm.scss';

// Models
import { ELocalStorageKeys } from "../../../enums/storage.enums";
import { ICompanyProfile } from "../../../interfaces/company.interfaces";

type TFormValue = {
  password: string | undefined,
  password_confirmation: string | undefined,
  username: string | undefined
};

export const SignUpConfirm = () => {
  const [confirmForm] = Form.useForm<TFormValue>();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [discardModalOpen, setDiscardModalOpen] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const onCancel = (value: boolean) => {
    if (value) {
      confirmForm.resetFields();
      dispatch(clearAuthState());
      // localStorage.clear();
      localStorage.smartClear();
      navigate('/sign-up');
    }
    setDiscardModalOpen(false);
  }

  const onValuesChange = (
    _: { [key in keyof TFormValue]: string | undefined }, { password_confirmation, password }: TFormValue
  ): void => {
    const isValid = !!password && !!password_confirmation
      && new RegExp(passwordPattern).test(password)
      && password_confirmation === password;

    setBtnDisabled(!isValid);
  };

  const onFinish = async (): Promise<void> => {
    try {
      const values = confirmForm.getFieldsValue();

      const { password, password_confirmation } = values;
      const userData = localStorage.getItem('signUp');

      const companyData = localStorage.getItem(ELocalStorageKeys.Company);
      const companyProfile: ICompanyProfile | null = companyData && companyData !== 'undefined' ? JSON.parse(companyData) : null;
      const invitation_code = companyProfile?.url_code || null;

      if (!userData || userData === 'undefined') return;

      setIsLoading(true);

      const userDataParsed = JSON.parse(userData);
      const temporaryToken = localStorage.getItem('temporary_token');

      const { payload } = await dispatch(signUpUser({
        first_name: userDataParsed.first_name,
        last_name: userDataParsed.last_name,
        // username: username,
        email: userDataParsed.email,
        is_anonymous: false,
        invitation_code,
        password, password_confirmation,
        temporary_token: temporaryToken ?? ''
      }));

      setServerError(null);
      setIsLoading(false);

      if (payload?.error) {
        setBtnDisabled(true);
        setServerError(payload.error);
        return;
      }

      if (payload) {
        localStorage.removeItem('temporary_token');
        localStorage.setItem('temporaryToken', payload.temporary_token);
        localStorage.setItem('authVerificationResendAccess', payload?.resend_access);
        navigate('/account-verification');
      }
    } catch (error) {
      console.error('SignUp onFinish error', error);
      setIsLoading(false);
    }
  };

  return (<AuthLayout cardTitle="Sign Up" rightBarIcon="auth" cardCurrentStep={ '2' } cardTotalSteps={ '2' }>
    { serverError && <ServerErrors serverError={ serverError }/> }
    <Form
      form={ confirmForm }
      className="sign-up-confirm-password"
      name="sign-up-confirm-password"
      onFinish={ onFinish }
      onValuesChange={ onValuesChange }
      autoComplete="off"
      validateMessages={ defaultValidateMessages }>

      {/*<Form.Item*/ }
      {/*  name="username"*/ }
      {/*  messageVariables={{fieldLabel: 'Nickname'}}*/ }
      {/*  rules={[{required: true, max: 60}]}>*/ }
      {/*  <CustomInput type={'text'} placeholder={'Nickname*'} suffix={<CustomIcon name={EIconName.PersonOff}/>}/>*/ }
      {/*</Form.Item>*/ }

      <Form.Item
        name="password"
        messageVariables={ { fieldLabel: 'Password' } }
        dependencies={ ['password_confirmation'] }
        rules={ [
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(passwordPattern).test(getFieldValue('password'))) {
                setShowPasswordError(false);
                return Promise.resolve();
              }
              setShowPasswordError(true);
              return Promise.reject(new Error());
            }
          })
        ] }>
        <div>
          { showPasswordError && <PasswordValidationTooltip/> }
          <InputPassword placeholder={ 'Password*' }/>
        </div>
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={ ['password'] }
        messageVariables={ { fieldLabel: 'Confirm Password' } }
        rules={ [
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.passwordsDontMatch));
            }
          })] }>
        <InputPassword placeholder={ 'Confirm Password*' }/>
      </Form.Item>

      <div className="form-bottom">
        <CustomButton text="Cancel" type={ 'text' } disabled={ isLoading } onClick={ () => setDiscardModalOpen(true) }/>
        <CustomButton htmlType={ 'submit' } disabled={ btnDisabled } loading={ isLoading } text="Sign Up"/>
      </div>
    </Form>

    <DiscardModal open={ discardModalOpen }
                  onCancel={ (value) => onCancel(value) }
                  title={ 'Are you sure you want to discard your Sign Up?' }/>
  </AuthLayout>);
}
