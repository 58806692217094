import React from 'react';

// Styles
import './messages-empty.scss';

type TMessagesEmptyProps = {
  entityName: string,
  bgUrl: string,
}

export const MessagesEmpty = ({ entityName, bgUrl }: TMessagesEmptyProps) => {

  return (<div className="messages--body-empty">
    <div className="empty-img">
      <img src={ bgUrl }/>
    </div>
    <div className="empty-subtitle">
      Write your request to the { entityName } to start conversation
    </div>
  </div>);
}
