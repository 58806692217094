import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input } from 'antd';

// Shared UI Library Components
import { CustomButton, CustomIcon, EIconName, TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './message-input.scss';

// Store
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

// Models
import { ICurrency } from '../../../../interfaces/company.interfaces';

const TextArea = Input.TextArea;

interface IMessageInputProps {
  currValue: string;
  totalSpent?: string;
  currency: ICurrency;
  rate?: string;
  showFiles?: boolean;
  mode?: TElementMode,
  disabled?: boolean;
  loading?: boolean;
  full_free_service_enabled?: boolean;
  placeholder?: string;
  entityName?: string;
  sendMessage?: (message: string, files: File[]) => void;
}

export const MessageInput: FC<IMessageInputProps> = (
  {
    currValue,
    currency,
    showFiles = true,
    totalSpent = '',
    rate = '',
    mode = 'white',
    placeholder = 'Please provide a description of the request to help the Counsellor understand your question and the purpose of the professional advice',
    entityName = 'Counsellor',
    disabled = false,
    loading = false,
    full_free_service_enabled = false,
    sendMessage = () => {
    }
  }: IMessageInputProps
) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);

  const dispatch = useDispatch<any>();

  const onUploadFileHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;

    if (e.target.files.length > 3) {
      dispatch(openNotification({
        type: 'warning',
        description: 'We can upload a maximum of 3 first files at a time'
      }));
    }

    const files = Array.from(e.target.files).slice(0, 3);

    const filesSize = files.reduce((size, file) => (size += file.size), 0);

    if (filesSize > 1048576) { // 10mb
      dispatch(openNotification({
        type: 'error',
        description: 'The maximum file size should not exceed 10 MB'
      }));
      return;
    }

    setFiles(files);

    // Clear files, if same without clear, input not emit uploading
    inputFileRef.current!.value = '';
  }

  const openUploadFileWindow = (): void => {
    inputFileRef.current?.click();
  }

  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const currValue = event.target.value;
    setInputValue(currValue);
  }

  // const onKeyDownHandler = (event: KeyboardEvent): void => {
  // Enter -> next line
  // Shift + Enter -> next line
  // }

  const onSendMessageHandler = (): void => {
    sendMessage(inputValue, files);
  }

  useEffect(() => {
    if (!currValue) {
      setInputValue('');
      setFiles([]);
    }
  }, [currValue]);


  return (
    <div className={ `messages--body-input messages--body-input-${ mode }` }>
      <div className="input-item">
        { showFiles && <CustomIcon name={ EIconName.AttachFile } onClick={ openUploadFileWindow }/> }
        <TextArea
          onChange={ onChangeHandler }
          // onKeyDown={ onKeyDownHandler }
          value={ inputValue }
          showCount={ true }
          maxLength={ 2500 }
          autoSize={ {
            minRows: 3,
            maxRows: 8
          } }
          placeholder={ placeholder }
          disabled={ disabled }
        />
        { showFiles && <div className="input-item--files">Files { files.length }/3 (max size 10Mb each)</div> }
      </div>
      <div className="input-item">
        { !!totalSpent && <div className="input-item--price">{ `${ currency.symbol }${ totalSpent ?? '0.00' }` } spent on requests with this { entityName }</div> }
        <CustomButton text={ (rate && !full_free_service_enabled) ? `${ rate } /request` : 'Send request' } disabled={ loading || !inputValue.length }
                      loading={ loading }
                      onClick={ onSendMessageHandler }
                      icon={ <CustomIcon name={ EIconName.Send }/> }/>
      </div>
      { showFiles && <input
        accept={ [
          'image/*',
          'application/pdf',
          '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ].join(',') }
        ref={ inputFileRef }
        type={ 'file' }
        multiple={ true }
        hidden={ true }
        onChange={ onUploadFileHandler }/> }
    </div>
  )
};
