import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Shared UI Library Components
import { THeaderInfoBlock } from '@ppmcore/seven-ppm-core-shared-components-react';

// Models
import {
  ICompany, ICompanyProfile,
  ICountry,
  ILanguage,
  ISingleCompany,
  ISpeciality,
  ISpecialization
} from '../../interfaces/core.interfaces';
import { ELocalStorageKeys } from "../../enums/storage.enums";

export interface ICoreState {
  infoBlockType: THeaderInfoBlock;
  path: {
    current: string;
    prev: string;
  },
  languages: {
    list: ILanguage[];
    isLoad: boolean;
    isError: boolean;
  };
  countries: {
    list: ICountry[];
    isLoad: boolean;
    isError: boolean;
  };
  speciality: {
    list: ISpeciality[];
    isLoad: boolean;
    isError: boolean;
  };
  specializations: {
    list: ISpecialization[];
    isLoad: boolean;
    isError: boolean;
  };
  companies: {
    list: ICompany[];
    isLoad: boolean;
    isError: boolean;
  }
  companyInfo: {
    company: ISingleCompany | null;
    isLoad: boolean;
    isError: boolean;
  }
}

const initialCompany = localStorage.getItem(ELocalStorageKeys.Company);

const initialState: ICoreState = {
  infoBlockType: 'base',
  path: {
    current: '',
    prev: '',
  },
  languages: {
    list: [],
    isLoad: false,
    isError: false
  },
  countries: {
    list: [],
    isLoad: false,
    isError: false
  },
  speciality: {
    list: [],
    isLoad: false,
    isError: false
  },
  specializations: {
    list: [],
    isLoad: false,
    isError: false
  },
  companies: {
    list: [],
    isLoad: false,
    isError: false
  },
  companyInfo: {
    company: initialCompany ? { company_profile: JSON.parse(initialCompany) } : null,
    isLoad: false,
    isError: false
  }
};

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setHeaderInfoBlockType: (state, { payload }: PayloadAction<THeaderInfoBlock>) => {
      state.infoBlockType = payload;
    },
    setCurrentPath: (state, { payload }: PayloadAction<string>) => {
      state.path = { current: payload, prev: state.path.current };
    },

    loadLanguages: (state) => {
      state.languages = { ...state.languages, isLoad: true, isError: false };
    },
    loadLanguagesSuccess: (state, { payload }: PayloadAction<ILanguage[]>) => {
      state.languages = { list: payload, isLoad: false, isError: false };
    },
    loadLanguagesError: (state) => {
      state.languages = { ...state.languages, isLoad: false, isError: true };
    },

    loadCountries: (state) => {
      state.countries = { ...state.countries, isLoad: true, isError: false };
    },
    loadCountriesSuccess: (state, { payload }: PayloadAction<ICountry[]>) => {
      state.countries = { list: payload, isLoad: false, isError: false };
    },
    loadCountriesError: (state) => {
      state.countries = { ...state.countries, isLoad: false, isError: true };
    },

    loadSpecialities: (state) => {
      state.speciality = { ...state.speciality, isLoad: true, isError: false };
    },
    loadSpecialitiesSuccess: (state, { payload }: PayloadAction<ISpeciality[]>) => {
      state.speciality = { list: payload, isLoad: false, isError: false };
    },
    loadSpecialitiesError: (state) => {
      state.speciality = { ...state.speciality, isLoad: false, isError: true };
    },

    loadSpecializations: (state) => {
      state.specializations = { ...state.specializations, isLoad: true, isError: false };
    },
    loadSpecializationsSuccess: (state, { payload }: PayloadAction<ISpecialization[]>) => {
      state.specializations = { list: payload, isLoad: false, isError: false };
    },
    loadSpecializationsError: (state) => {
      state.specializations = { ...state.specializations, isLoad: false, isError: true };
    },
    clearSpecializations: (state) => {
      state.specializations = { list: [], isLoad: false, isError: false };
    },

    loadCompanies: (state) => {
      state.companies = { ...state.companies, isLoad: true, isError: false };
    },
    loadCompaniesSuccess: (state, { payload }: PayloadAction<ICompany[]>) => {
      state.companies = { list: payload, isLoad: false, isError: false };
    },
    loadCompaniesError: (state) => {
      state.companies = { ...state.companies, isLoad: false, isError: true };
    },

    loadSingleCompany: (state) => {
      state.companyInfo = { ...state.companyInfo, isLoad: true, isError: false };
    },
    loadSingleCompanySuccess: (state, { payload }: PayloadAction<ISingleCompany | null>) => {
      state.companyInfo = { company: payload, isLoad: false, isError: false };
    },
    loadCSingleCompanyError: (state) => {
      state.companyInfo = { ...state.companyInfo, isLoad: false, isError: true };
    },
    updateSingleCompanyInfo: (state, { payload: company }: PayloadAction<ICompanyProfile>) => {
      state.companyInfo = {
        ...state.companyInfo,
        company: state.companyInfo.company ? {
          ...state.companyInfo.company,
          company_profile: { ...state.companyInfo.company.company_profile, ...company }
        } : null
      };
    },

    assignNewCompany: (state) => {
      state.companyInfo = { ...state.companyInfo, isLoad: true, isError: false };
    },
    assignNewCompanySuccess: (state, { payload }: PayloadAction<ISingleCompany>) => {
      state.companyInfo = { company: payload, isLoad: false, isError: false };
    },
    assignNewCompanyError: (state) => {
      state.companyInfo = { ...state.companyInfo, isLoad: false, isError: true };
    },
    updateCorrespondenceTimeFromSocket: (state, { payload: company_profile }: PayloadAction<ICompanyProfile>) => {
      if (!state.companyInfo?.company) return;
      state.companyInfo.company.company_profile = company_profile;
    },

    updateCompanyStatus(state, { payload: { is_blocked } }: PayloadAction<{ is_blocked: number }>) {
      if (!state.companyInfo?.company) return;
      state.companyInfo.company.company_profile.is_blocked = is_blocked;
    },
    updateCompanyBilling(state, { payload: { is_not_enough_minutes, is_not_enough_request } }:
      PayloadAction<{ is_not_enough_minutes: boolean, is_not_enough_request: boolean }>) {
      if (!state.companyInfo?.company) return;
      state.companyInfo.company.company_profile.is_not_enough_minutes = is_not_enough_minutes;
      state.companyInfo.company.company_profile.is_not_enough_request = is_not_enough_request;
    },
  }
});

export const CoreSliceActions = coreSlice.actions;
