import React, { FC } from 'react';
import {
  PayPalNumberField,
  PayPalExpiryField,
  PayPalCVVField,
} from "@paypal/react-paypal-js";
import { PayPalCardFieldsStateObject } from "@paypal/paypal-js/types/components/card-fields";

// Styles
import './card-fields.scss';

type TCardFieldsProps = {
  cardState: PayPalCardFieldsStateObject | null,
};

export const CardFields: FC<TCardFieldsProps> = ({ cardState }: TCardFieldsProps) => {
  return (
    <div className="card-fields--form">
      <div className="form-row">
        <div className="form-row--item">
          <PayPalNumberField/>
          <span className="error-hint">
                  { (cardState?.errors?.includes('INVALID_NUMBER') && !cardState?.fields?.cardNumberField?.isEmpty && !cardState?.fields?.cardNumberField?.isFocused)
                    && 'Your card number is invalid.' }
                  </span>
        </div>
      </div>
      <div className="form-row flex-items">
        <div className="form-row--item">
          <PayPalExpiryField/>
          <span className="error-hint">
                    { (cardState?.errors?.includes('INVALID_EXPIRY') && !cardState?.fields?.cardExpiryField?.isEmpty && !cardState?.fields?.cardExpiryField?.isFocused)
                      && 'Your card\'s expiration year is invalid.' }
                    </span>
        </div>
        <div className="form-row--item">
          <PayPalCVVField/>
          <span className="error-hint">
                       { (cardState?.errors?.includes('INVALID_CVV') && !cardState?.fields?.cardCvvField?.isEmpty && !cardState?.fields?.cardCvvField?.isFocused)
                         && 'Your card\'s security code is invalid.' }
                    </span>
        </div>
      </div>
    </div>
  );
}
