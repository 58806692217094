import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Howl } from 'howler';
import dayjs from 'dayjs';

// Shared UI Library Components
import { tzDayjs } from "@ppmcore/seven-ppm-core-shared-components-react";

// Store actions & selectors
import { setSocketLastMessage } from '../store/socket/socket.thunks';
import { addPaidMessageFromSocket, addPaidMessageNotificationFromSocket } from '../store/messages/messages.thunks';
import { openNotification } from '../store/app-notifications/app-notifications.thunks';
import { getSocketToken } from '../store/socket/socket.selectors';
import { addNotificationFromSocket, notificationsUnreadCount } from '../store/notifications/notifications.thunks';
import { addCallMessageFromSocket } from '../store/call/call.thunks';
import {
  deleteExpertStatusFromSocket,
  updateExpertMessagesHasUnreadState,
  updateExpertStatusFromSocket
} from '../store/experts/experts.thunks';
import {
  addHistoryAppointmentFromSocket,
  addHistoryFromSocket,
  addHistoryNotificationFromSocket
} from '../store/history/history.thunks';
import { getCompanyProfileState, updateCorrespondenceTimeFromSocket } from '../store/core/core.thunks';
import { updateCurrentCurrencyFromSocket } from '../store/currency/currency.thunks';
import { addChatMessageFromSocket } from '../store/chat/chat.thunks';
import { refreshStoreEntitiesByCompany } from '../store/user/user.thunks';
import { updateActiveAppointmentFromSocket } from "../store/active-appointments/active-appointments.thunks";
import { updateAppointmentFromSocket } from "../store/appointments/appointments.thunks";
import { getGlobalConfigState } from "../store/global-config/global-config.selectors";
import { updateSoonAppointmentCall } from "../store/ongoing-consultation/ongoing-consultation.thunks";
import {
  updateBusinessTermsFromSocket,
  updateIllustrationsFromSocket
} from "../store/global-config/global-config.thunks";

// Socket entity
import { EWebSocketEvent, EWebSocketPackageType } from '../enums/web-socket.enums';
import { IWebSocketDataEvent } from '../interfaces/web-socket.interfaces';
import { IAppointmentHistory, ICallMessage, IMessageItem } from '../interfaces/chat.interfaces';
import { IExpertProfile } from '../interfaces/experts.interfaces';
import { INotificationItem, INotificationMessagePing } from '../interfaces/notifications.interfaces';
import { ICompanyProfile } from '../interfaces/core.interfaces';
import { ICurrency } from '../interfaces/company.interfaces';
import { IHistory } from '../interfaces/history.interfaces';
import { IAppointmentCallStarted } from "../interfaces/appointments.interfaces";
import { ICallProcess } from "../interfaces/call.interfaces";
import { ITerminologySettings } from "../interfaces/keywords.interfaces";
import { IIllustrations } from "../interfaces/illustrations.interfaces";
import { IChangePaymentProvider } from "../interfaces/payments.interfaces";

/**
 * Custom React Hook for adding a WebSockets connection.
 */
export const useWebSockets = () => {
  const faviconElement: HTMLLinkElement | null = document.head.querySelector('[rel="icon"]');

  const timerIds: { pong: NodeJS.Timer | null, ping: NodeJS.Timer | null } = {
    pong: null,
    ping: null
  };

  const socketToken = useSelector(getSocketToken);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const socket = useMemo(() => {
    if (!socketToken || !process.env.REACT_APP_SOCKET_URL) return null;

    const token = localStorage.getItem('token');
    const temporaryToken = localStorage.getItem('temporary_token');

    return io(process.env.REACT_APP_SOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      auth: {
        channel: socketToken,
        app: 'user',
        token: token || temporaryToken
      }
    });
  }, [socketToken]);

  /**
   * Plays an audio notification using the Howler library.
   * @function
   * @returns {void}
   */
  const playAudio = (): void => {
    const audio = new Howl({
      src: ['/assets/audio/notification.mp3'],
    });
    audio.play();
  }

  /**
   * Changes the tab title and browser favicon icon.
   * @param {string} faviconHref - The URL of the favicon to change.
   * @param {string} title - The new tab title.
   * @param {boolean} [disableTimer=false] - Flag to disable the timer for changing the favicon and title.
   * @returns {void}
   */
  const changeTabTitle = (faviconHref: string, title: string, disableTimer: boolean = false): void => {
    if (faviconElement) {
      faviconElement.href = faviconHref;
    }
    window.document.title = title;

    if (disableTimer) return;

    /**
     * Timer to revert the favicon and title to default values after 10 seconds.
     * @type {number}
     */
    const timerId = setTimeout((): void => {
      changeTabTitle('/favicon.ico', 'Paypertok - Consumer')
      clearTimeout(timerId);
    }, 10000);
  }

  /**
   * Window focus event handler.
   * @returns {void}
   */
  const handleWindowFocusEvent = (): void => {
    changeTabTitle('/favicon.ico', 'Paypertok - Consumer', true);
  }

  /**
   * Clear Pong Timer.
   */
  const clearPongTimer = (): void => {
    if (!timerIds.pong) return;
    clearTimeout(timerIds.pong);
    timerIds.pong = null;
  }

  /**
   * Clear Ping Timer.
   */
  const clearPingTimer = (): void => {
    if (!timerIds.ping) return;
    clearInterval(timerIds.ping);
    timerIds.ping = null;
  }

  /**
   * Starts the pong waiting timer.
   */
  const startPongWaitingTimer = (): void => {
    clearPongTimer();
    timerIds.pong = setTimeout(() => {
      reconnectSockets();
    }, 15000);
  }

  /**
   * Initializes the ping event to keep the WebSocket connection alive.
   */
  const initPingEvent = (): void => {
    clearPingTimer();
    timerIds.ping = setInterval(() => {
      socket?.emit(EWebSocketEvent.Ping);
      startPongWaitingTimer();
    }, 20000);
  }

  /**
   * Handles the 'Connect' event of the web socket.
   */
  const onConnectEvent = (): void => {}

  /**
   * Handles chat-message type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The message data  the web socket by user channel.
   */
  const onCallChatMessageEvent = ({ data }: { data: ICallMessage }): void => {
    dispatch(addCallMessageFromSocket({ message: data }));
  }

  /**
   * Handles conversation-message type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The message data  the web socket by user channel.
   */
  const onConversationChatMessageEvent = ({ data }: { data: ICallMessage }): void => {
    dispatch(addChatMessageFromSocket({ message: data }));
  }

  /**
   * Handles worker-status-changed type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The worker status data the web socket by user channel.
   */
  const onWorkerStatusChangedEvent = ({ data }: { data: IExpertProfile }): void => {
    dispatch(updateExpertStatusFromSocket(data));
  }

  /**
   * Handles paid-message type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The message data the web socket by user channel.
   */
  const onPaidMessageEvent = ({ data }: { data: IMessageItem }): void => {
    const expertId = data.worker?.id as number;
    const isCurrentCounsellorPage = window.location.href.includes(`expert/${ expertId }/messages`);
    dispatch(addPaidMessageFromSocket({ message: data }));
    dispatch(updateExpertMessagesHasUnreadState({ expertId, has_unread_messages: !isCurrentCounsellorPage }));
    dispatch(notificationsUnreadCount());
  }

  /**
   * Handles new-message-ping type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The message data the web socket by user channel.
   */
  const onNewPingEvent = ({ data, type }: {
    data: INotificationMessagePing,
    type: 'message_ping' | 'call_ping'
  }): void => {
    dispatch(addNotificationFromSocket(data));
    const transformData: any = {
      worker: data.worker,
      consultation_type: type === 'message_ping' ? 'message' : 'call_ping',
      id: data.id,
      created_at: type === 'message_ping' ? data?.message_created_at : data?.call_created_at,
      ...(type === 'message_ping' ? {
        message_pinged: data?.message_replied,
      } : {
        call_pinged: data?.call_pinged
      })
    };
    dispatch(addPaidMessageNotificationFromSocket({ notification: transformData }));
    dispatch(addHistoryNotificationFromSocket({
      history: {
        ...transformData,
        consultation_type: type === 'message_ping' ? 'message' : 'audio_call'
      }
    }));

    dispatch(openNotification({
      type: 'open',
      noIcon: true,
      description: `You received a ping from --b->${ data?.worker?.first_name + ' ' + data?.worker?.last_name }<-b--`
    }));
  }

  /**
   * Handles new-conversation-ping type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IMessageItem} data - The message data the web socket by user channel.
   */
  const onNewConversationPingEvent = ({ data, type }: {
    data: INotificationMessagePing,
    type: 'conversation_ping'
  }): void => {
    dispatch(addNotificationFromSocket(data));
    const transformData: any = {
      worker: data.worker,
      consultation_type: 'conversation',
      id: data.id,
      created_at: data?.conversation_created_at,
      conversation_pinged: data?.conversation_pinged,
    };
    dispatch(addHistoryNotificationFromSocket({
      history: {
        ...transformData,
        consultation_type: 'conversation'
      }
    }));

    dispatch(openNotification({
      type: 'open',
      noIcon: true,
      description: `You received a ping from --b->${ data?.worker?.first_name + ' ' + data?.worker?.last_name }<-b--`
    }));
  }

  /**
   * Handles message replied type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {INotificationItem} data - The notification data  the web socket by user channel.
   */
  const onMessageRepliedEvent = ({ data }: { data: INotificationItem }): void => {
    // dispatch(addNotificationFromSocket(data));

    dispatch(openNotification({
      type: 'open',
      noIcon: true,
      description: `New Response to Request
        from ${ dayjs().format('DD MMM YYYY [at] hh:mm A') } from --b->${ data?.worker?.first_name + ' ' + data?.worker?.last_name }<-b--`
    }));
  }

  /**
   * Handles favorite change status type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {INotificationItem} data - The notification data  the web socket by user channel.
   */
  const onFavoriteChangeStatusEvent = ({ data }: { data: INotificationItem }): void => {
    dispatch(addNotificationFromSocket(data));

    dispatch(openNotification({
      type: 'open',
      noIcon: true,
      description: data.notification_type === 'worker_deactivated' ?
        `Deactivated ${ businessTerms.Consultant } --b->${ data?.worker?.first_name + data?.worker?.last_name }<-b-- from Favorites list` :
        `Blocked ${ businessTerms.Consultant } --b->${ data?.worker?.first_name + data?.worker?.last_name }<-b-- from Favorites list`
    }));
  }

  /**
   * Handles correspondence-time-changed type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {INotificationItem} data - The notification data  the web socket by user channel.
   */
  const onCorrespondenceTimeChangedEvent = ({ data }: { data: ICompanyProfile }): void => {
    dispatch(updateCorrespondenceTimeFromSocket(data));
  }

  /**
   * Handles company-currency-updated type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param Object data - The notification data  the web socket by user channel.
   */
  const onCompanyCurrencyUpdatedEvent = ({ data }: { data: { currency: ICurrency } }): void => {
    dispatch(updateCurrentCurrencyFromSocket(data.currency));
  }

  /**
   * Handles company-changed type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param Object data - The company data the web socket by user channel.
   */
  const onCompanyChangedEvent = async ({ data }: { data: ICompanyProfile }): Promise<void> => {
    const { payload: company } = await dispatch(getCompanyProfileState());
    if (!company || company?.id === data?.id) return;

    dispatch(refreshStoreEntitiesByCompany());
  }

  /**
   * Handles worker-deactivated type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IUserData} data - The worker data  the web socket by user channel.
   */
  const onWorkerDeactivatedEvent = ({ data }: { data: IExpertProfile }): void => {
    dispatch(updateExpertStatusFromSocket(data));
  }

  /**
   * Handles worker-blocked type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IUserData} data - The worker data  the web socket by user channel.
   */
  const onWorkerBlockedEvent = ({ data }: { data: IExpertProfile }): void => {
    dispatch(updateExpertStatusFromSocket(data));
  }

  /**
   * Handles worker-unblocked type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IUserData} data - The worker data  the web socket by user channel.
   */
  const onWorkerUnblockedEvent = ({ data }: { data: IExpertProfile }): void => {
    dispatch(updateExpertStatusFromSocket(data));
  }

  /**
   * Handles worker-deleted type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IUserData} data - The worker data  the web socket by user channel.
   */
  const onWorkerDeletedEvent = async ({ data }: { data: IExpertProfile }): Promise<void> => {
    const { payload } = await dispatch(deleteExpertStatusFromSocket(data));
    if (!payload) return;
    navigate('/home');
  }

  /**
   * Handles worker-activated type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IUserData} data - The worker data  the web socket by user channel.
   */
  const onWorkerActivatedEvent = ({ data }: { data: IExpertProfile }): void => {
    dispatch(updateExpertStatusFromSocket(data));
  }

  /**
   * Handles history message type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {IHistory} data - The history data  the web socket by user channel.
   */
  const onHistoryMessageEvent = ({ data }: { data: IHistory }): void => {
    dispatch(addHistoryFromSocket(data));
  }

  /**
   * Handles message notification type event.
   * @param {EWebSocketPackageType} type - The type from the web socket by user channel.
   * @param {INotificationItem} data - The notification data  the web socket by user channel.
   */
  const onMessageNotificationEvent = ({ data }: { data: INotificationItem }): void => {
    dispatch(addNotificationFromSocket(data));
  }

  /**
   * Handles appointment-start-soon type event.
   */
  const onAppointmentStartSoonEvent = (data: INotificationItem): void => {
    const dateString = data.hasOwnProperty('appointment_notified') ?
      `${ tzDayjs(data.appointment_notified?.started_at).format('dddd, MMM D [at] hh:mm A') }
       -
       ${ tzDayjs(data.appointment_notified?.finished_at).format('hh:mm A') }`
      : '';
    dispatch(addNotificationFromSocket(data));
    dispatch(openNotification({
      type: 'info',
      description: `Appointment with ${ data.worker.first_name } ${ data.worker.last_name } for ${ dateString } will start soon. Don't miss it`,
    }));
  }

  /**
   * Handles appointment-approved type event.
   */
  const onAppointmentApprovedEvent = (data: INotificationItem): void => {
    dispatch(updateAppointmentFromSocket(data));
    const dateString = data.hasOwnProperty('appointment_notified') ?
      `${ tzDayjs(data.appointment_notified?.started_at).format('dddd, MMM D [at] hh:mm A') }
       -
       ${ tzDayjs(data.appointment_notified?.finished_at).format('hh:mm A') }`
      : '';
    dispatch(addNotificationFromSocket(data));
    dispatch(openNotification({
      type: 'info',
      description: `Appointment with ${ data.worker.first_name } ${ data.worker.last_name } for ${ dateString } was approved by ${ businessTerms.consultant }.`,
    }));
  }

  /**
   * Handles appointment-canceled type event.
   */
  const onAppointmentCanceledEvent = (data: INotificationItem): void => {
    dispatch(updateAppointmentFromSocket(data));
    const dateString = data.hasOwnProperty('appointment_notified') ?
      `${ tzDayjs(data.appointment_notified?.started_at).format('dddd, MMM D [at] hh:mm A') }
       -
       ${ tzDayjs(data.appointment_notified?.finished_at).format('hh:mm A') }`
      : '';
    dispatch(addNotificationFromSocket(data));
    dispatch(openNotification({
      type: 'info',
      description: `Appointment with ${ data.worker.first_name } ${ data.worker.last_name } for ${ dateString } was canceled by ${ businessTerms.consultant }.`,
    }));
  }

  /**
   * Handles appointment-call-started type event.
   */
  const onAppointmentCallStartedEvent = (data: IAppointmentCallStarted): void => {
    dispatch(updateActiveAppointmentFromSocket(data));
  }

  /**
   * Handles appointment-history type event.
   */
  const onAppointmentHistoryEvent = (data: IAppointmentHistory): void => {
    dispatch(addHistoryAppointmentFromSocket(data));
  }

  /**
   * Handles appointment-call-soon type event.
   */
  const onAppointmentCallSoonEvent = (data: ICallProcess): void => {
    dispatch(updateSoonAppointmentCall(data));
  }

  /**
   * Handles company-terminology-updated type event.
   */
  const onCompanyTerminologyUpdatedEvent = (data: ITerminologySettings): void => {
    dispatch(updateBusinessTermsFromSocket(data));
  }

  /**
   * Handles company-illustration-updated type event.
   */
  const onCompanyIllustrationUpdatedEvent = (data: IIllustrations): void => {
    dispatch(updateIllustrationsFromSocket(data));
  }

  /**
   * Handles change_payment_provider type event.
   */
  const onChangePaymentProviderEvent = (data: IChangePaymentProvider): void => {
    dispatch(openNotification({
      type: 'info',
      description: `Please note that the payment provider for the current company has changed, the page will reload automatically in a few seconds to update the configurations correctly`,
    }));

    const timerId = setTimeout(() => {
      clearTimeout(timerId);
      window.location.reload();
    }, 5000);
  }

  /**
   * Handles incoming data events from the web socket.
   * @param {IWebSocketDataEvent} dataEvent - The data received from the web socket by user channel.
   */
  const onDataEvent = (dataEvent: IWebSocketDataEvent<any>): void => {
    if (!Object.keys(dataEvent).length) {
      return;
    }
    dispatch(setSocketLastMessage(dataEvent));

    if (dataEvent.type === EWebSocketPackageType.PaidMessage) {
      onPaidMessageEvent({ data: dataEvent.data });

      changeTabTitle('/favicon-message.ico', 'You have new message!');
      playAudio();
    }

    if (dataEvent.type === EWebSocketPackageType.NewMessagePing) {
      onNewPingEvent({ data: dataEvent.data, type: 'message_ping' });
    }

    if (dataEvent.type === EWebSocketPackageType.NewCallPing) {
      onNewPingEvent({ data: dataEvent.data, type: 'call_ping' });
    }

    if (dataEvent.type === EWebSocketPackageType.NewConversationPing) {
      onNewConversationPingEvent({ data: dataEvent.data, type: 'conversation_ping' });
    }

    if (dataEvent.type === EWebSocketPackageType.MessageReplied) {
      onMessageRepliedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.ChatMessage) {
      onCallChatMessageEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.ConversationMessage) {
      onConversationChatMessageEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerStatusChanged || dataEvent.type === EWebSocketPackageType.WorkerRateChanged) {
      onWorkerStatusChangedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerDeactivated) {
      onWorkerDeactivatedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerActivated) {
      onWorkerActivatedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerBlocked) {
      onWorkerBlockedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerUnblocked) {
      onWorkerUnblockedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.WorkerDeleted) {
      onWorkerDeletedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.CorrespondenceTimeChanged) {
      onCorrespondenceTimeChangedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.CompanyCurrencyUpdated) {
      onCompanyCurrencyUpdatedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.CompanyChanged) {
      onCompanyChangedEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.FavoriteDeactivated) {
      onFavoriteChangeStatusEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.FavoriteBlocked) {
      onFavoriteChangeStatusEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.HistoryMessage) {
      onHistoryMessageEvent({ data: dataEvent.data });
    }

    if (dataEvent.type === EWebSocketPackageType.MessageNotification) {
      onMessageNotificationEvent({ data: dataEvent.data });
    }

    // Appointments
    if (dataEvent.type === EWebSocketPackageType.AppointmentStartSoon) {
      onAppointmentStartSoonEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.AppointmentApproved) {
      onAppointmentApprovedEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.AppointmentCanceled) {
      onAppointmentCanceledEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.AppointmentCallStarted) {
      onAppointmentCallStartedEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.AppointmentHistory) {
      onAppointmentHistoryEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.AppointmentCallSoon) {
      onAppointmentCallSoonEvent(dataEvent.data);
    }

    // Improvements
    if (dataEvent.type === EWebSocketPackageType.CompanyTerminologyUpdated) {
      onCompanyTerminologyUpdatedEvent(dataEvent.data);
    }

    if (dataEvent.type === EWebSocketPackageType.CompanyIllustrationUpdated) {
      onCompanyIllustrationUpdatedEvent(dataEvent.data);
    }

    // Payment Providers
    if (dataEvent.type === EWebSocketPackageType.ChangePaymentProvider) {
      onChangePaymentProviderEvent(dataEvent.data);
    }
  }

  /**
   * Handles connect to room event from the web socket.
   * @param {string} dataEvent - The message from the web socket by user channel.
   */
  const onConnectToRoomEvent = ({ type, data }: { type: EWebSocketPackageType, data: { message: string } }): void => {}

  /**
   * Handles the 'Pong' event of the web socket.
   */
  const onPongEvent = (): void => {
    clearPongTimer();
  };

  /**
   * Unsubscribes from events of the web socket.
   */
  const unsubscribeFromEvents = (): void => {
    socket?.off(EWebSocketEvent.Connect, onConnectEvent);
    socket?.off(EWebSocketEvent.ConnectToRoom, onConnectToRoomEvent);
    socket?.off(EWebSocketEvent.Pong, onPongEvent);
    socket?.off(`${ process.env.REACT_APP_SOCKET_CHANNEL_PREFIX }${ socketToken }`, onDataEvent);
  };

  /**
   * Subscribes to events of the web socket.
   */
  const subscribeForEvents = (): void => {
    socket?.on(EWebSocketEvent.Connect, onConnectEvent);
    socket?.on(EWebSocketEvent.ConnectToRoom, onConnectToRoomEvent);
    socket?.on(EWebSocketEvent.Pong, onPongEvent);
    socket?.on(`${ process.env.REACT_APP_SOCKET_CHANNEL_PREFIX }${ socketToken }`, onDataEvent);
  };

  const disconnectSockets = (): void => {
    socket?.disconnect();
    clearPongTimer();
    clearPingTimer();
    unsubscribeFromEvents();
  }

  const reconnectSockets = (): void => {
    disconnectSockets();
    connectSockets();
  }

  const connectSockets = (): void => {
    if (!socket) return;
    socket?.connect();
    subscribeForEvents();
    initPingEvent();
  }

  useEffect((): void => {
    connectSockets();
  }, [socket]);

  useEffect(() => {
    window.addEventListener('focus', handleWindowFocusEvent);
    /**
     Cleaning function to destroy socket connection when component is unmounted.
     @function
     */

    return () => {
      disconnectSockets();
      window.removeEventListener('focus', handleWindowFocusEvent);
    };
  }, []);
};
