import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { IGlobalConfigState } from './global-config.slice';

/**
 A selector function to retrieve the global config data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IGlobalConfigState} The global config state object from the Redux store
 */
const selectGlobalConfigState = (state: RootState): IGlobalConfigState => state.globalConfig;

/**
 A memoized selector function to retrieve the global config data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IGlobalConfigState, (res: IGlobalConfigState) => IGlobalConfigState>}
 */
export const getGlobalConfigState = createSelector(
  [selectGlobalConfigState],
  (state): IGlobalConfigState => state
);
