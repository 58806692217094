import { createAsyncThunk } from '@reduxjs/toolkit';

// Shared UI Library Components
import { CThemeConfig, setUpThemeColors, THeaderInfoBlock } from '@ppmcore/seven-ppm-core-shared-components-react';

// Services
import { CoreService } from '../../services/core.service';

// Slices & Thunks
import { CoreSliceActions } from './core.slice';
import { setBusinessTerms, setIllustrations } from "../global-config/global-config.thunks";

// Models
import { ICompanyProfile, ISingleCompany, ISpeciality } from '../../interfaces/core.interfaces';
import { ELocalStorageKeys } from "../../enums/storage.enums";

// Utils
import { isWidgetAppType } from '../../utils/widget.utils';

// Configs
import { CKeywordsDefault } from "../../constantes/keywords.constants";
import { CIllustrationDefault } from "../../constantes/illustrations.constants";
import { GlobalConfigSliceActions } from "../global-config/global-config.slice";
import { isArray } from "node:util";

export enum ECoreThunks {
  SetHeaderInfoBlockType = 'CORE/setHeaderInfoBlockType',
  SetCurrentPath = 'CORE/setCurrentPath',
  FetchLanguagesList = 'CORE/fetchLanguagesList',
  FetchCountriesList = 'CORE/fetchCountriesList',
  FetchUserCompany = 'CORE/fetchUserCompany',
  FetchTempUserCompany = 'CORE/fetchTempUserCompany',
  AssignNewCompany = 'CORE/assignNewCompany',
  FetchSpecializations = 'CORE/fetchSpecializations',
  ClearSpecializations = 'CORE/clearSpecializations',
  FetchSpecialities = 'CORE/fetchSpecialities',
  FetchCompanies = 'CORE/fetchCompanies',
  UpdateCorrespondenceTimeFromSocket = 'CORE/updateCorrespondenceTimeFromSocket',
  UpdateCompanyStatus = 'CORE/updateCompanyStatus',
  UpdateCompanyBilling = 'CORE/updateCompanyBilling',
  GetCompanyProfileState = 'CORE/getCompanyProfileState',
  SetCompanyColors = 'CORE/setCompanyColors',
  ValidateUserToken = 'CORE/validateUserToken',
}

export const fetchLanguages = createAsyncThunk(
  ECoreThunks.FetchLanguagesList,
  async ({ company_code }: { company_code: string | null }, { dispatch }) => {
    dispatch(CoreSliceActions.loadLanguages());
    const data = await CoreService.fetchLanguagesList({ company_code });
    if (!data?.languages) {
      return dispatch(CoreSliceActions.loadLanguagesError());
    }
    return dispatch(CoreSliceActions.loadLanguagesSuccess(data.languages));
  }
);

export const fetchCountries = createAsyncThunk(
  ECoreThunks.FetchCountriesList,
  async ({ company_code }: { company_code: string | null }, { dispatch }) => {
    dispatch(CoreSliceActions.loadCountries());
    const data = await CoreService.fetchCountriesList({ company_code });
    if (!data?.countries) {
      return dispatch(CoreSliceActions.loadCountriesError());
    }
    return dispatch(CoreSliceActions.loadCountriesSuccess(data.countries));
  }
);

export const fetchSpecializations = createAsyncThunk(
  ECoreThunks.FetchSpecializations,
  async ({ speciality, company_code }: { speciality: ISpeciality, company_code: string | null }, { dispatch }) => {
    dispatch(CoreSliceActions.loadSpecializations());
    const data = await CoreService.fetchSpecializationsList(speciality, company_code);
    if (!data?.specializations) {
      return dispatch(CoreSliceActions.loadSpecializationsError());
    }
    return dispatch(CoreSliceActions.loadSpecializationsSuccess(data.specializations));
  }
);

export const clearSpecializations = createAsyncThunk(
  ECoreThunks.ClearSpecializations,
  async (_, { dispatch }) => {
    return dispatch(CoreSliceActions.clearSpecializations());
  }
);

export const fetchSpecialities = createAsyncThunk(
  ECoreThunks.FetchSpecialities,
  async ({ company_code }: { company_code: string | null }, { dispatch }) => {
    dispatch(CoreSliceActions.loadSpecialities());
    const data = await CoreService.fetchSpecialitiesList({ company_code });
    if (!data?.specialities) {
      return dispatch(CoreSliceActions.loadSpecialitiesError());
    }
    return dispatch(CoreSliceActions.loadSpecialitiesSuccess(data.specialities));
  }
);

export const fetchCompanies = createAsyncThunk(
  ECoreThunks.FetchCompanies,
  async (_, { dispatch }) => {
    dispatch(CoreSliceActions.loadCompanies());
    const data = await CoreService.fetchCompaniesList();
    if (!data?.companies) {
      return dispatch(CoreSliceActions.loadCompaniesError());
    }

    // await dispatch(fetchLanguages());
    // await dispatch(fetchCountries());
    // await dispatch(fetchSpecialities());
    //
    // await dispatch(fetchExpertsList({ filters: { }, type: 'all' }));

    return dispatch(CoreSliceActions.loadCompaniesSuccess(data.companies));
  }
);

export const setCompanyColors = createAsyncThunk(
  ECoreThunks.SetCompanyColors,
  async (company: ISingleCompany, { dispatch }) => {
    const isWidget = isWidgetAppType();
    if (!isWidget) return;

    const themeSettings = company.company_profile?.widget_settings.theme_settings;

    document.documentElement.style.setProperty("--widget-header-bg", themeSettings?.elemental_header_color);
    document.documentElement.style.setProperty("--call-bg", themeSettings?.elemental_call_color);

    const newThemeConfig = {
      name: 'custom',
      colors: CThemeConfig.colors.map((color => {
        const prefix = color.prefix;
        if (prefix === 'primary') {
          return { ...color, base: themeSettings?.primary_system_color }
        }
        if (prefix === 'primary-accent') {
          return { ...color, base: themeSettings?.primary_system_color }
        }
        if (prefix === 'orange') {
          return { ...color, base: themeSettings?.primary_accent_color }
        }
        if (prefix === 'blue') {
          return { ...color, base: themeSettings?.primary_accent_second_color }
        }
        if (prefix === 'green') {
          return { ...color, base: themeSettings?.secondary_success_color }
        }
        if (prefix === 'red') {
          return { ...color, base: themeSettings?.secondary_error_color }
        }
        if (prefix === 'yellow') {
          return { ...color, base: themeSettings?.secondary_warning_color }
        }
        return color;
      }))
    };

    setUpThemeColors(newThemeConfig);
    localStorage.setItem('themeConfig', JSON.stringify(newThemeConfig));
  }
);

export const fetchUserCompany = createAsyncThunk(
  ECoreThunks.FetchUserCompany,
  async (_, { dispatch }) => {
    dispatch(CoreSliceActions.loadSingleCompany());
    const data = await CoreService.fetchUserCompany();
    if (!data) {
      return dispatch(CoreSliceActions.loadCSingleCompanyError());
    }

    if (!Array.isArray(data?.company_profile)) {
      dispatch(setCompanyColors(data));
      dispatch(setBusinessTerms(data?.company_profile?.terminology_settings));
      dispatch(setIllustrations(data?.company_profile?.illustration_photos));

      localStorage.setItem(ELocalStorageKeys.Company, JSON.stringify(data?.company_profile));
    } else {
      localStorage.setItem(ELocalStorageKeys.BusinessTerms, JSON.stringify(CKeywordsDefault));

      dispatch(GlobalConfigSliceActions.updateBusinessTermsFromSocket(CKeywordsDefault));
      dispatch(setIllustrations(CIllustrationDefault));
    }

    return dispatch(CoreSliceActions.loadSingleCompanySuccess(data?.company_profile ? data : null));
  }
);

export const fetchTempUserCompany = createAsyncThunk(
  ECoreThunks.FetchTempUserCompany,
  async ({ company_code }: { company_code: string }, { dispatch }) => {
    dispatch(CoreSliceActions.loadSingleCompany());
    const data = await CoreService.fetchTempUserCompany(company_code);
    if (!data) {
      dispatch(CoreSliceActions.loadCSingleCompanyError())
      return null;
    }

    if (!Array.isArray(data?.company_profile)) {
      dispatch(setCompanyColors(data));
      dispatch(setBusinessTerms(data?.company_profile?.terminology_settings));
      dispatch(setIllustrations(data?.company_profile?.illustration_photos));

      localStorage.setItem(ELocalStorageKeys.Company, JSON.stringify(data?.company_profile));
    } else {
      localStorage.setItem(ELocalStorageKeys.BusinessTerms, JSON.stringify(CKeywordsDefault));

      dispatch(GlobalConfigSliceActions.updateBusinessTermsFromSocket(CKeywordsDefault));
      dispatch(setIllustrations(CIllustrationDefault));
    }

    dispatch(CoreSliceActions.loadSingleCompanySuccess(data?.company_profile ? data : null));
    return data;
  }
);

export const assignNewCompanyThunk = createAsyncThunk(
  ECoreThunks.AssignNewCompany,
  async ({ company_code }: { company_code: string }, { dispatch }) => {
    dispatch(CoreSliceActions.assignNewCompany());
    const data = await CoreService.assignNewCompany(company_code);
    if (!data) {
      return dispatch(CoreSliceActions.assignNewCompanyError());
    }
    localStorage.setItem(ELocalStorageKeys.Company, JSON.stringify(data?.company_profile));
    return dispatch(CoreSliceActions.assignNewCompanySuccess(data));
  }
);

export const validateUserToken = createAsyncThunk(
  ECoreThunks.ValidateUserToken,
  async () => {
    const res = await CoreService.validateUserToken();
    if (!res) {
      return false;
    }
    return res.valid_token;
  }
);

export const updateCorrespondenceTimeFromSocket = createAsyncThunk(
  ECoreThunks.UpdateCorrespondenceTimeFromSocket,
  (companyProfile: ICompanyProfile, { dispatch }) => {
    localStorage.setItem(ELocalStorageKeys.Company, JSON.stringify(companyProfile));
    return dispatch(CoreSliceActions.updateCorrespondenceTimeFromSocket(companyProfile));
  }
);

export const setHeaderInfoBlockType = createAsyncThunk(
  ECoreThunks.SetHeaderInfoBlockType,
  (type: THeaderInfoBlock, { dispatch }) => {
    return dispatch(CoreSliceActions.setHeaderInfoBlockType(type));
  }
);

export const setCurrentPath = createAsyncThunk(
  ECoreThunks.SetCurrentPath,
  (path: string, { dispatch }) => {
    return dispatch(CoreSliceActions.setCurrentPath(path));
  }
);

export const updateCompanyStatus = createAsyncThunk(
  ECoreThunks.UpdateCompanyStatus,
  async ({ is_blocked }: { is_blocked: number }, { dispatch }) => {
    dispatch(CoreSliceActions.updateCompanyStatus({ is_blocked }));
  }
);

export const updateCompanyBilling = createAsyncThunk(
  ECoreThunks.UpdateCompanyBilling,
  async ({ is_not_enough_minutes, is_not_enough_request }:
           { is_not_enough_minutes: boolean, is_not_enough_request: boolean }, { dispatch }) => {
    dispatch(CoreSliceActions.updateCompanyBilling({ is_not_enough_minutes, is_not_enough_request }));
  }
);

export const getCompanyProfileState = createAsyncThunk(
  ECoreThunks.GetCompanyProfileState,
  async (_, { dispatch, getState }) => {
    return (getState() as any)?.core?.companyInfo?.company?.company_profile;
  }
);
