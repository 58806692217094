import React from 'react';
import { useSelector } from "react-redux";

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-unavailable.scss';

// StoreEntities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const StatusUnavailable = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<CallNotifications
    title={'Availability Issue'}
    text={`The ${ businessTerms.Consultant } is not available at the moment. You may try to contact them later. The payment for the ${ businessTerms.Consultation } will be refunded to you.`}
    navigateAction={returnPathname}  />);
}
