import { FC } from 'react';
import { Statistic } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './failed-status.scss';

// Models
import { IPaymentFailed } from "../../../interfaces/payments.interfaces";

// Configs
import { CPaymentDeclinedMessages } from "../../../constantes/payment-form.constantes";

export type TFailedStatusProps = {
  paymentError: IPaymentFailed;
  onFinishTimer?: () => void;
};

export const FailedStatus: FC<TFailedStatusProps> = (
  { paymentError, onFinishTimer = () => {} }: TFailedStatusProps
) => {

  return (
    <div className="failed-status">
      <div className="failed-status--title">
        <CustomIcon name={ EIconName.Info }/> { CPaymentDeclinedMessages[paymentError.error_type] } ****{ paymentError?.card?.last4 }
      </div>
      <div className="failed-status--content">
        Unable to reserve the required amount on your card for the next <b>{ paymentError?.max_payment_minutes } minutes</b>. Please select a different
        card or
        enter new details to continue. <b>Otherwise your call will conclude in <Statistic.Countdown onFinish={ onFinishTimer }
        value={ paymentError?.finished_at } format="mm:ss"/></b>
      </div>
    </div>
  );
};
