import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomInput,
  defaultValidateMessages,
  EIconName,
  emailPattern,
  ServerErrors,
  stringPattern
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';
import { FormBottomActions } from '../auth-modules/form-bottom-actions/form-bottom-actions';
import { StaticTextModal } from '../../../modals/static-text-modal/static-text-modal';
import { StaticPages } from '../../../modules/static-pages/static-pages';

// Thunks
import { signUpUser } from '../../../store/auth/auth.thunks';

// Selectors
import { getAuthState } from '../../../store/auth/auth.selectors';
import { getCoreState } from "../../../store/core/core.selectors";

// Styles
import './sign-up.scss';

type TFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  termsService: boolean;
  privacyPolicy: boolean;
};

export const SignUp = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [form] = Form.useForm<TFormValues>();

  const [staticTermsModalOpen, setStaticTermsModalOpen] = useState(false);
  const [staticPrivacyModalOpen, setStaticPrivacyModalOpen] = useState(false);

  const [hasTemporaryData, setHasTemporaryData] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);

  const { loading } = useSelector(getAuthState);
  const { companyInfo } = useSelector(getCoreState);

  const skipForNowHandler = () => {
    navigate(`/${ companyInfo.company?.company_profile?.url_code }`);
  }

  const checkTemporaryData = (): void => {
    const data = localStorage.getItem('temporaryData');
    if (!data) return;

    const parseData = JSON.parse(data);
    form.setFields([
      {
        name: 'first_name',
        value: parseData?.first_name || '',
      },
      {
        name: 'last_name',
        value: parseData?.last_name || '',
      },
      {
        name: 'email',
        value: parseData?.email || '',
      },
    ]);
    setHasTemporaryData(true);
    setBtnDisabled(false);
  }

  useEffect(() => {
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');

    checkTemporaryData();
  }, []);

  const onValuesChange = (_: { first_name: string } | { last_name: string } | { email: string },
                          allFormValues: {
                            first_name: string | undefined,
                            last_name: string | undefined,
                            email: string | undefined
                          }) => {
    if (allFormValues.first_name !== undefined
      && allFormValues.last_name !== undefined
      && allFormValues.email !== undefined
      && allFormValues.first_name !== ''
      && allFormValues.last_name !== ''
      && allFormValues.email !== ''
      && allFormValues.first_name.length <= 30
      && allFormValues.last_name.length <= 30
      && allFormValues.email.length <= 60
      && new RegExp(emailPattern).test(allFormValues.email)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const onFinish = () => {
    if (btnDisabled) return;
    form.validateFields().then((values) => {
      const userData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email
      };
      localStorage.setItem('signUp', JSON.stringify(userData));
      setServerError(null);

      dispatch(signUpUser(userData)).then((res: any) => {
        // if (res.payload?.error && res.payload.error === 'The username cannot be empty.') {
        if (res.payload?.error && res.payload.error === 'The password cannot be empty.') {
          navigate(`/sign-up-confirm`);
          return;
        }

        if (res.payload?.error && res.payload.error === 'The account with this email already exists.') {
          setBtnDisabled(true);
          form.setFields([
            {
              name: 'email',
              value: values.email,
              errors: ['The account with this email already exists.']
            }
          ]);
          return;
        }

        if (res.payload?.error) {
          setBtnDisabled(true);
          setServerError(res.payload.error);
        }
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  return (<AuthLayout cardTitle="Sign Up" rightBarIcon='auth' cardCurrentStep={ '1' } cardTotalSteps={ '2' }>
    { serverError && <ServerErrors serverError={ serverError }/> }
    <Form
      form={ form }
      className={ 'sign-up-form' }
      name="sign-up"
      onFinish={ onFinish }
      onValuesChange={ onValuesChange }
      autoComplete="off"
      validateMessages={ defaultValidateMessages }>

      <FormBottomActions infoText={ 'Already have an account?' } isSignInLink={ true }/>

      <Form.Item
        name="first_name"
        messageVariables={ { fieldLabel: 'First Name' } }
        rules={ [
          { required: true, max: 30 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(stringPattern).test(getFieldValue('first_name'))) {
                return Promise.resolve();
              }
              if (value.length > 30) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidFirstNameFormat));
            }
          })
        ] }>
        <CustomInput type={ 'text' } placeholder={ 'First Name*' }
                     suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
      </Form.Item>
      <Form.Item
        name="last_name"
        messageVariables={ { fieldLabel: 'Last Name' } }
        rules={ [
          { required: true, max: 30 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(stringPattern).test(getFieldValue('last_name'))) {
                return Promise.resolve();
              }
              if (value.length > 30) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidLastNameFormat));
            }
          })
        ] }>
        <CustomInput type={ 'text' } placeholder={ 'Last Name*' }
                     suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
      </Form.Item>

      <Form.Item
        name="email"
        messageVariables={ { fieldLabel: 'Email' } }
        rules={ [
          { required: true },
          { max: 60 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                return Promise.resolve();
              }
              if (value.length > 60) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
            }
          })] }>
        <CustomInput type={ 'text' } shouldTrim={ true } placeholder={ 'Email*' } disabled={ hasTemporaryData }
                     suffix={ <CustomIcon name={ EIconName.Mail }/> }/>
      </Form.Item>

      <StaticTextModal modalTitle="Terms of Service"
                       open={ staticTermsModalOpen }
                       onSubmit={ () => setStaticTermsModalOpen(false) }
                       onCancel={ () => setStaticTermsModalOpen(false) }>
        <StaticPages staticPageId={ 2 } isDialog={ true }/>
      </StaticTextModal>

      <StaticTextModal modalTitle="Privacy Policy"
                       open={ staticPrivacyModalOpen }
                       onSubmit={ () => setStaticPrivacyModalOpen(false) }
                       onCancel={ () => setStaticPrivacyModalOpen(false) }>
        <StaticPages staticPageId={ 1 } isDialog={ true }/>
      </StaticTextModal>

      <div className="sign-up-form--footer">
        By clicking Continue you agree to <span className="modal-action"
                                                onClick={ () => setStaticTermsModalOpen(!staticTermsModalOpen) }>Terms of Service</span> and <span
        className="modal-action"
        onClick={ () => setStaticPrivacyModalOpen(!staticPrivacyModalOpen) }>Privacy Policy</span>, and
        to { (companyInfo?.company?.company_profile?.payment_provider === 'stripe' || !companyInfo?.company?.company_profile?.payment_provider) && <>
        Stripe
        Press’s <a
        className={ 'dialog-link' } href={ 'https://stripe.com/legal/ssa' } target={ '_blank' }>Terms of
        Service</a> and <a
        className={ 'dialog-link' } href={ 'https://stripe.com/privacy' } target={ '_blank' }>Privacy Policy</a>
      </> }
        { companyInfo?.company?.company_profile?.payment_provider === 'paypal' && <>
          Paypal
          Press’s <a
          className={ 'dialog-link' } href={ 'https://www.paypal.com/us/legalhub/useragreement-full' }
          target={ '_blank' }>Terms of
          Service</a> and <a
          className={ 'dialog-link' } href={ 'https://www.paypal.com/ua/legalhub/privacy-full' } target={ '_blank' }>Privacy
          Policy</a>
        </> }
      </div>

      <div className="form-bottom form-bottom-block">
        <div className={ 'actions' }>
          { (companyInfo.company?.company_profile && !Array.isArray(companyInfo.company?.company_profile)) &&
            <CustomButton text="Skip for Now" type={ 'text' } onClick={ skipForNowHandler }/> }
          <CustomButton htmlType={ 'submit' } disabled={ loading || btnDisabled } text="Continue"/>
        </div>
      </div>
    </Form>
  </AuthLayout>);
}
