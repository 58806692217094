import { IKeywords, ITerminologySettings } from "../interfaces/keywords.interfaces";

export const terminologyToKeywords = (
  {
    terminology_consultant,
    terminology_consultants,
    terminology_consultant_s,
    terminology_consultation,
    terminology_consultations,
    terminology_consultation_s
  }: ITerminologySettings
): IKeywords => {
  return {
    Consultation: terminology_consultation,
    Consultations: terminology_consultations,
    Consultations_S: terminology_consultation_s,
    Consultant: terminology_consultant,
    Consultants: terminology_consultants,
    Consultants_S: terminology_consultant_s,
    consultation: terminology_consultation.charAt(0).toLowerCase() + terminology_consultation.slice(1),
    consultations: terminology_consultation.charAt(0).toLowerCase() + terminology_consultation.slice(1),
    consultations_S: terminology_consultation_s.charAt(0).toLowerCase() + terminology_consultation_s.slice(1),
    consultant: terminology_consultant.charAt(0).toLowerCase() + terminology_consultant.slice(1),
    consultants: terminology_consultants.charAt(0).toLowerCase() + terminology_consultants.slice(1),
    consultants_S: terminology_consultant_s.charAt(0).toLowerCase() + terminology_consultant_s.slice(1),
  };
}
