import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { TPaymentErrorCode } from "../types/payments.types";

export const CPaymentRequestConfig = {
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Demo total',
    amount: 1099,
  },
  requestPayerName: false,
  requestPayerEmail: false,
}

export const CPaymentButtonConfig = {
  style: {

    borderRadius: '32px',
    paymentRequestButton: {
      type: 'default',
      // One of 'default', 'book', 'buy', or 'donate'
      // Defaults to 'default'

      theme: 'dark',
      // One of 'dark', 'light', or 'light-outline'
      // Defaults to 'dark'

      height: '44px',
      // Defaults to '40px'. The width is always '100%'.
    },
  }
}

export const CPaymentElementOptions: StripePaymentElementOptions = {
  layout: 'tabs',
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  fields: {
    billingDetails: {
      // address: 'never',
    },
  }
};

export const CPaymentDeclinedMessages: { [key in TPaymentErrorCode]: string } = {
  insufficient_funds: 'Insufficient Funds on Card',
  lost_card: 'Lost card decline',
  generic_decline: 'Generic decline',
  stolen_card: 'Stolen card decline',
  card_velocity_exceeded: 'Exceeding velocity limit decline',
  expired_card: 'Expired card decline',
  incorrect_cvc: 'Incorrect CVC decline',
  processing_error: 'Processing error decline',
  incorrect_number: 'Incorrect number decline',
}
