import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';

// I18
import { I18nextProvider } from 'react-i18next';

// i18n translation
import i18 from './configs/i18n/i18n.config';

// Redux
import { Provider } from 'react-redux';

// React router
import { BrowserRouter } from 'react-router-dom';

// Global styles
import './index.scss';

// Components
import { App } from './pages/app';
import { BrowserNotSupported } from './pages/browser-not-supported/browser-not-supported';

import reportWebVitals from './reportWebVitals';

// Store root reducer
import { store } from './store';

// Utils
import { isSupportedBrowser } from './utils/browser.utils';
// import { setUpThemeColors } from './utils/theme.utils';

// Configs
// import { CThemeConfig } from './constantes/theme.constants';

// Extensions
import { extendSmartClear } from "./utils/storage.utils";

// Call extendSmartClear to add the smartClear method to localStorage
extendSmartClear();

/**
 * Extends the global Storage interface to include the `smartClear` method.
 * This ensures that TypeScript is aware of the new method on the localStorage object.
 */
declare global {
  interface Storage {
    /**
     * Clears the localStorage, except for the specified keys to keep.
     * If no argument is provided, only predefined keys are kept.
     *
     * @param {string | string[]} [keysToKeep] - A single key or an array of keys to retain in localStorage.
     */
    smartClear(keysToKeep?: string | string[]): void;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Usage
const isBrowserSupported = isSupportedBrowser();

// Set up color palette
// setUpThemeColors(CThemeConfig);

root.render(
  <I18nextProvider i18n={ i18 }>
    <Provider store={ store }>
      <BrowserRouter>
        { isBrowserSupported ? <App/> : <BrowserNotSupported/> }
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
