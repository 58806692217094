import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from "react-redux";

// Shared UI Library Components
import { CardElement, SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './auth-layout.scss';

// Store entities
// import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";

type Props = {
  cardTitle: string;
  cardCurrentStep?: string;
  cardTotalSteps?: string;
  isLoading?: boolean;
  rightBarIcon: string;
  children: React.ReactNode;
};

export const AuthLayout = (
  {
    cardTitle,
    cardCurrentStep,
    cardTotalSteps,
    isLoading = false,
    rightBarIcon,
    children
  }: Props
) => {
  // const { illustrations } = useSelector(getGlobalConfigState);

  return (
    <div className="auth-inside">
      { isLoading && <div className={ 'auth-spinner' }><SpinElement fullHeight={ true }/></div> }
      { !isLoading && <div className="auth-inside-item left-section">
        <div className="forms-section">
          <img className={ 'logo' } src={ '/assets/images/logo.svg' } alt={ 'paypertok-logo' }/>
          { !isLoading && <CardElement
            cardTitle={ cardTitle }
            cardCurrentStep={ cardCurrentStep }
            cardTotalSteps={ cardTotalSteps }
            mobileAutoWidth={ true }
            mobileChangeBg={ true }
            titleCentered={ true }
            mobileImageSubtitle={ <img className={ 'card-logo' } src={ '/assets/icons/big/auth.svg' } alt={ 'welcome-auth-icon' }/> }
          >
            { children }
          </CardElement> }
        </div>
      </div> }
      { !isLoading && <div className="auth-inside-item right-section">
        { rightBarIcon && <img src={ '/assets/icons/big/auth.svg' } alt="welcome-auth-icon"/> }
      </div> }
    </div>);
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
};
