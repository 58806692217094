import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './call-checkout.scss';

// Components
import { ConsultationInfo, PaymentForm } from '../../../shared/payment-components';
import { FormProvider } from "../../../shared/paypal/form-provider/form-provider";

// Store entities
import { getCallInfo, startCall } from '../../../store/call/call.thunks';
import { getCallState } from '../../../store/call/call.selectors';
import { getCurrencyState } from '../../../store/currency/currency.selectors';
import { getUserState } from "../../../store/user/user.selectors";

export const CallCheckout = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const temporaryToken = localStorage.getItem('temporary_token');

  const { callProcess } = useSelector(getCallState);
  const { currentCurrency } = useSelector(getCurrencyState);
  const { user } = useSelector(getUserState);

  useEffect(() => {
    const callId = localStorage.getItem('call_id');
    if (!callProcess && callId) dispatch(getCallInfo({ call_id: +callId, temporary_token: temporaryToken ?? '' }));
  }, [callProcess])

  const onPaid = (payment_method_nonce: string) => {
    const callId = localStorage.getItem('call_id');
    if (callId) {
      dispatch(startCall({ call_id: +callId, payment_method_nonce, temporary_token: temporaryToken ?? '' }))
        .then((res: { payload: any }) => {
          navigate(`/call/outgoing`);
        })
    }
  }

  const onBack = () => {
    navigate('/call/payments-type');
  }

  return (<div className={ 'call-checkout' }>
    <div className={ 'title' }><CustomIcon className={ 'back-icon' } name={ EIconName.ArrowLeft2 } onClick={ onBack }/>Checkout
    </div>
    { callProcess && callProcess.worker?.id && callProcess.call_prebooked_time && <div className="call-checkout-inside">
      <div className="body-item body-item--info body-item--divider">
        <ConsultationInfo
          rate={ `${ callProcess.worker_rate?.text }` }
          rate_time={ callProcess.worker_rate?.call_duration ?? null }
          name={ `${ callProcess.worker.first_name } ${ callProcess.worker.last_name }` }
          type={ callProcess.call_type }
          call_session_type={ callProcess.call_session_type }
          prebooked_time={ callProcess.call_prebooked_time }
          mode={ 'dark' }
          free_status={ callProcess.free_status }
          free_service_minutes={ callProcess.worker.free_service_minutes }
          isPrepay={ true }
        />
      </div>
      <div className="body-item body-item--payment-form">
        { user?.payment_provider === 'stripe' && <PaymentForm
          currency={ currentCurrency }
          consultation_type={ callProcess.call_type ?? 'video_call' }
          rate_type={ callProcess.call_session_type }
          rate={ callProcess.worker_rate?.value }
          call_duration={ callProcess.call_session_type === 'pay_per_session' ? callProcess.worker_rate?.call_duration : '' }
          worker_id={ callProcess.worker.id }
          onPaid={ onPaid }
          btn_text={ `Pay ${ currentCurrency.symbol }${ ((callProcess.amount || 0) / 100).toFixed(2) } and Start` }
          mode={ 'dark' }/> }

        { user?.payment_provider === 'paypal' && <FormProvider
          currency={ currentCurrency }
          consultation_type={ callProcess.call_type ?? 'video_call' }
          rate_type={ callProcess.call_session_type }
          rate={ callProcess.worker_rate?.value }
          call_duration={ callProcess.call_session_type === 'pay_per_session' ? callProcess.worker_rate?.call_duration : '' }
          worker_id={ callProcess.worker.id }
          onPaid={ onPaid }
          btn_text={ `Pay ${ currentCurrency.symbol }${ ((callProcess.amount || 0) / 100).toFixed(2) } and Start` }
          mode={ 'dark' }/> }
      </div>
    </div> }
  </div>);
}
