import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './payment-methods.scss';

// Components
import { CardItem } from '../card-item/card-item';

// Store entities
import { getPaymentsData } from '../../../store/payments/payments.selectors';

type TPaymentMethodsProps = {};

export const PaymentMethods: FC<TPaymentMethodsProps> = (
  {}: TPaymentMethodsProps
) => {

  const { cards, defaultCard, isLoad } = useSelector(getPaymentsData);

  const sortedByDefaultCards = [...cards].sort((a, b) => a.id === defaultCard ? -1 : +1);

  return (
    <div className="payment-methods">
      <div className="payment-methods--description">
        Please note that if you save a payment card in your profile, it may not be displayed with other companies if
        they use a different payment provider. Additionally, if the company changes its payment system, any previously
        saved cards may no longer be available.
      </div>
      <div className="payment-methods--header">
        <div className="header-title">
          Your Payment Methods
        </div>

        { (!cards.length && !isLoad) && <div className="header-subtitle">
          No Payment Methods
        </div> }

      </div>

      { (!!cards.length && !isLoad) && <div className="payment-methods--cards">
        { sortedByDefaultCards.map(card => <CardItem key={ card.id } card={ card }
                                                     isSelected={ defaultCard === card.id }/>) }
      </div> }

      { isLoad && <div className="payment-methods--loading">
        <SpinElement/>
      </div> }
    </div>
  );
}
