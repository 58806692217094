import { FC, SyntheticEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomButton, SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './notifications.scss';

// Components
import { ClearNotificationsModal } from '../../modals/clear-notifications-modal/clear-notifications-modal';
import { NotificationItem } from '../../shared/ui-components/notifications';
import {
  ExpertAuthWidgetNavigation
} from '../../shared/ui-components/expert/expert-auth-widget-navigation/expert-auth-widget-navigation';

// Store entities
import { getNotificatiosState } from '../../store/notifications/notifications.selectors';
import { getCoreState } from '../../store/core/core.selectors';
import { getGlobalConfigState } from "../../store/global-config/global-config.selectors";
import {
  clearAllNotifications,
  clearNotificationsState,
  deleteNotification,
  fetchNotifications,
  markAsReadNotifications
} from '../../store/notifications/notifications.thunks';
import { updateLastCompany } from '../../store/user/user.thunks';

// Models
import { INotificationItem } from '../../interfaces/notifications.interfaces';

type TNotificationsProps = {};

export const Notifications: FC<TNotificationsProps> = (
  {}: TNotificationsProps
) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [allDeleting, setAllDeleting] = useState<boolean>(false);
  const [allDeletingModalOpen, setAllDeletingModalOpen] = useState<boolean>(false);

  const { notifications, loading, total } = useSelector(getNotificatiosState);
  const { companyInfo } = useSelector(getCoreState);
  const { businessTerms, illustrations } = useSelector(getGlobalConfigState);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const transformDatesObject: { [key: string]: INotificationItem[] } = useMemo(() => {
    const uniqueDates = Array.from(
      new Set(
        notifications.map(({ notification_created_at }) => dayjs(notification_created_at).format('DD MMM YYYY'))
      )
    );
    return uniqueDates.reduce((acc, date) => {
      acc[date] = notifications.filter(({ notification_created_at }) => dayjs(notification_created_at).isSame(date, 'day'));
      return acc;
    }, {} as { [key: string]: INotificationItem[] });
  }, [notifications]);

  const getDateTitle = (date: string): string => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
      return 'Today';
    }
    if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return date;
  }

  const loadNotifications = async (isLoadMore: boolean = false): Promise<void> => {
    await dispatch(fetchNotifications({
      offset: notifications.length,
      isLoadMore
    }));
    dispatch(markAsReadNotifications());
  }

  const onScrollListHandler = (event: SyntheticEvent): void => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target as HTMLDivElement;
    if ((Math.abs(scrollTop) + offsetHeight + 50 < scrollHeight) || loading || total <= notifications.length) return;
    loadNotifications(true);
  }

  const onViewHandler = async ({
    notification_type,
    worker,
    message_pinged,
    call_pinged,
    conversation_pinged,
    company
  }: INotificationItem): Promise<void> => {
    if (companyInfo.company?.company_profile.id !== company.id) {
      await dispatch(updateLastCompany(company.id));
    }
    if (notification_type.includes('appointment')) {
      navigate(`/appointments`);
      return;
    }
    if (notification_type === 'call_ping') {
      navigate(`/expert/${ worker?.id }/history?after=${ call_pinged?.messages_after }&id=${ call_pinged?.id }`);
      return;
    }
    if (notification_type === 'message_ping') {
      navigate(`/expert/${ worker?.id }/history?after=${ message_pinged?.messages_after }&id=${ message_pinged?.id }`);
      return;
    }
    if (notification_type === 'conversation_ping') {
      navigate(`/expert/${ worker?.id }/history?after=${ conversation_pinged?.messages_after }&id=${ conversation_pinged?.id }`);
      return;
    }
    if (notification_type.includes('message') && Number.isInteger(message_pinged?.messages_after)) {
      navigate(`/expert/${ worker?.id }/messages?after=${ message_pinged?.messages_after }&id=${ message_pinged?.id }`);
    }
  }

  const onDeleteHandler = async (id: number): Promise<void> => {
    setDeletingId(id);
    await dispatch(deleteNotification(id));
    setDeletingId(null);
  }

  const toggleClearAllNotificationModal = (): void => {
    setAllDeletingModalOpen(!allDeletingModalOpen);
  }

  const onClearAllNotifications = async (): Promise<void> => {
    toggleClearAllNotificationModal();
    setAllDeleting(true);
    await dispatch(clearAllNotifications());
    setAllDeleting(false);
  }

  useEffect(() => {
    loadNotifications();

    return () => {
      dispatch(clearNotificationsState());
    }
  }, []);

  return (
    <div className="notifications">
      <ClearNotificationsModal open={ allDeletingModalOpen }
                               onCancel={ toggleClearAllNotificationModal }
                               onSubmit={ onClearAllNotifications }/>

      {
        (loading) && <div className="notifications--loader">
          <SpinElement fullHeight={ true }/>
        </div>
      }

      <div className="notifications--header">
        <div className="header-title">
          Notifications
        </div>
        <div className="header-action" hidden={ !notifications.length }>
          <CustomButton text={ 'Clear All' } loading={ allDeleting } size={ 'small' }
                        onClick={ toggleClearAllNotificationModal }/>
        </div>
        <ExpertAuthWidgetNavigation />
      </div>

      { (!notifications.length && !loading) && <div className="notifications--empty">
        <div className="empty-title">
          You don’t have any notifications.
        </div>
        <div className="empty-img">
          <img src={ illustrations?.notifications } alt="notification-icon"/>
        </div>
      </div> }

      { (!!notifications.length || loading) &&
        <div className="notifications--list" ref={ bodyRef } onScroll={ onScrollListHandler }>
          {
            Object.keys(transformDatesObject).map((date) => {
              return <Fragment key={ date }>
                <div className="list-date--label">
                  { getDateTitle(date) }
                </div>
                <div className="list-date--notifications">
                  {
                    transformDatesObject[date].map((notification) =>
                      <NotificationItem key={ notification.id }
                                        businessTerms={ businessTerms }
                                        notification={ notification }
                                        loading={ deletingId === notification.id }
                                        onView={ onViewHandler }
                                        onDelete={ onDeleteHandler }
                      />)
                  }
                </div>
              </Fragment>
            })
          }
        </div> }
    </div>
  );
}
