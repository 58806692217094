import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import './tu-second-step.scss';

// Shared UI Library Components
import { VerificationCode } from '@ppmcore/seven-ppm-core-shared-components-react';
import { TuPowerBy } from '../modules/tu-power-by/tu-power-by';
import { TuTitle } from '../modules/tu-title/tu-title';

// Thunks
import { openNotification } from "../../../../store/app-notifications/app-notifications.thunks";
import { activateTempUserCode, resendTempUserCode } from "../../../../store/auth/auth.thunks";

// Models
import { EVerificationCodeTypes } from "../../../../pages/profile-settings/profile-settings.enums";

interface ITuSecondStepProps {
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const TuSecondStep: FC<ITuSecondStepProps> = (
  { onSubmit = () => {}, onCancel = () => {} }: ITuSecondStepProps
) => {

  const dispatch = useDispatch<any>();

  const [errorWithTimerTime, setErrorWithTimerTime] = useState<string>('');
  const [errorWithTimerText, setErrorWithTimerText] = useState<string | null>(null);
  const [errorAfter, setErrorAfter] = useState<string | null>(null);
  const [sendAgainTime, setSendAgainTime] = useState('');

  const onSendAgain = async (): Promise<void> => {
    const temporaryToken = localStorage.getItem('tempSignUpToken');
    if (!temporaryToken) return;

    const { payload } = await dispatch(resendTempUserCode({
      temporary_token: temporaryToken,
      type: EVerificationCodeTypes.Authenticate
    }));

    if (payload && payload?.error) {
      localStorage.setItem('tempSignUpResendAccess', payload.access_after)
      setSendAgainTime(payload.access_after);
      dispatch(openNotification({
        description: payload.error,
        type: 'error'
      }));
      return;
    }

    localStorage.setItem('tempSignUpResendAccess', payload.resend_access)
    setSendAgainTime(payload.resend_access);
    dispatch(openNotification({
      description: payload.message,
      type: 'success'
    }));
  }

  const onErrorTimerEnd = (): void => {
    localStorage.removeItem('tempSignUpAccessTime');
    localStorage.removeItem('tempSignUpAccessMessage');
  }

  const sendAgainTimerEnd = () => {}

  const onCancelAction = () => {
    // localStorage.clear();
    localStorage.smartClear();
    onCancel();
  }

  const onFinish = async (code: string): Promise<void> => {
    const temporaryToken = localStorage.getItem('tempSignUpToken');
    if (!temporaryToken) return;

    const { payload } = await dispatch(activateTempUserCode({
      temporary_token: temporaryToken,
      code,
      actionType: EVerificationCodeTypes.Authenticate
    }));

    if (payload.error && payload.access_after) {
      setErrorWithTimerTime(payload.access_after);
      setErrorWithTimerText(payload.error);
      localStorage.setItem('tempSignUpAccessTime', payload.access_after);
      localStorage.setItem('tempSignUpAccessMessage', payload.error);
      return;
    }

    if (payload && payload?.error) {
      setErrorAfter(payload.error);
      return;
    }

    localStorage.removeItem('tempSignUpAccessTime');
    localStorage.removeItem('tempSignUpAccessMessage');
    localStorage.removeItem('tempSignUpToken');
    localStorage.removeItem('tempSignUpResendAccess');
    onSubmit();
  };

  useEffect(() => {
    const tempSignUpAccessTime = localStorage.getItem('tempSignUpAccessTime');
    const tempSignUpAccessMessage = localStorage.getItem('tempSignUpAccessMessage');

    if (!tempSignUpAccessTime || !tempSignUpAccessMessage) return;

    setErrorWithTimerTime(tempSignUpAccessTime);
    setErrorWithTimerText(tempSignUpAccessMessage);
  }, []);

  useEffect(() => {
    const resendAccess = localStorage.getItem('tempSignUpResendAccess');

    if (!resendAccess) return;

    setSendAgainTime(resendAccess);
  }, []);

  return (<div className="links-in-tu-second-step">
    <TuTitle currentStep={ '2' } totalSteps={ '2' }/>
    <VerificationCode
      discardModalText={ 'Are you sure you want to discard?' }
      submitBtnText={ 'Continue' }
      errorWithTimerTime={ errorWithTimerTime }
      errorWithTimerText={ errorWithTimerText }
      errorWithTimerEndAction={ onErrorTimerEnd }
      errorAfterField={ errorAfter }
      sendAgainTime={ sendAgainTime }
      sendAgainTimerEnd={ sendAgainTimerEnd }
      resetErrorAfterField={ () => setErrorAfter(null) }
      sendAgainAction={ onSendAgain }
      finishStepAction={ onFinish }
      cancelStepAction={ onCancelAction }/>
    <TuPowerBy/>
  </div>);
}
