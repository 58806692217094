import { FC } from 'react';
import { Modal } from 'antd';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './delete-favorite-modal.scss';

type TDeleteFavoriteModalProps = {
  open: boolean;
  title?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const DeleteFavoriteModal: FC<TDeleteFavoriteModalProps> = (
  {
    open = false,
    title = 'Are you sure you want to remove this counsellor from your favorites counsellors list?',
    onSubmit = () => {},
    onCancel = () => {}
  }: TDeleteFavoriteModalProps
) => {
  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = (): void => {
    onSubmit();
  }

  return (
    <Modal
      rootClassName="delete-favorite-modal"
      width={ '394px' }
      open={ open }
      closeIcon={ null }
      centered={ true }
      title={ title }
      footer={ [
        <CustomButton key="cancel" text={ 'No' } type={ 'text' } onClick={ handleCancel }/>,
        <CustomButton key="submit" text={ 'Yes' } onClick={ handleSubmit }/>
      ] }
      onCancel={ handleCancel }
    />
  )
};
