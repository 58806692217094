import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Radio } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  // CustomTooltip,
  // EIconColor,
  EIconName,
  InfoMessage,
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { fetchPaymentsTypes } from '../../../store/payments-types/payments-types.thunks';
import { getCallInfo, startCall, updateCall } from '../../../store/call/call.thunks';
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';

// Selectors
import { getPaymentsTypeData } from '../../../store/payments-types/payments-types.selectors';
import { getCallState } from '../../../store/call/call.selectors';

// Interfaces
// import { ICallPerSessionRate } from '../../../interfaces/payments-types.interfaces';
import { ICallProcess } from '../../../interfaces/call.interfaces';

// Styles
import './payment-type.scss';

const RadioGroup = Radio.Group;

export const PaymentType = () => {

  const [paymentsItemsForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const temporaryToken = localStorage.getItem('temporary_token');

  const { paymentsTypes, isLoad } = useSelector(getPaymentsTypeData);
  const { callProcess, loading } = useSelector(getCallState);

  const callSelectedRef = useRef<boolean>(false);

  const [paymentsTypesCategory, setPaymentsTypesCategory] = useState('pay_per_minute');
  const [paymentsRadioTypes, setPaymentsRadioTypes] = useState([
    { value: 'pay_per_minute', label: 'Pay per Minute' },
    // TODO changed logic, comments for future developing
    // { value: 'pay_per_session', label: 'Pay per Session' },
  ]);

  const [btnNextDisabled, setBtnNextDisabled] = useState(false);
  const [isSetupPage, setIsSetupPage] = useState(false);
  const [messageTime, setMessageTime] = useState<number | null>(null);

  const [payPerMinuteValue, setPayPerMinuteValue] = useState<string | null>(null);
  // TODO changed logic, comments for future developing
  // const [payPerSessionValues, setPayPerSessionValues] = useState<ICallPerSessionRate[] | null>(null);
  // const payPerSessionValuesSorted = !payPerSessionValues ? [] : [...payPerSessionValues]
  //   .sort((a, b) => (+a?.call_duration - +b?.call_duration));

  const worker = callProcess?.worker;

  useEffect(() => {
    const callId = localStorage.getItem('call_id');
    if (!callProcess?.call_prebooked_time && callId) dispatch(getCallInfo({
      call_id: +callId,
      temporary_token: temporaryToken ?? ''
    }));

    const workerId = localStorage.getItem('worker_id');
    if (!paymentsTypes && workerId) dispatch(fetchPaymentsTypes({
      workerId: +workerId,
      temporary_token: temporaryToken ?? ''
    }));
  }, [paymentsTypes, callProcess]);

  useEffect(() => {
    const callTypeStorage = localStorage.getItem('call_type');

    if (callSelectedRef.current) return;

    if (paymentsTypes && callTypeStorage && (callTypeStorage === 'audio_call' || callTypeStorage === 'video_call') && callProcess?.call_prebooked_time) {
      paymentsItemsForm.setFieldValue('rate_type', 'pay_per_minute');

      const payPerMinute = callTypeStorage === 'video_call' ? paymentsTypes.pay_per_minute.call_rate.text : paymentsTypes.pay_per_minute.audio_call_rate.text;
      const payPerMinuteValue = callTypeStorage === 'video_call' ? paymentsTypes.pay_per_minute.call_rate.value : paymentsTypes.pay_per_minute.audio_call_rate.value;

      if (payPerMinuteValue === 0) {
        // TODO changed logic, comments for future developing
        // setPaymentsRadioTypes(paymentsRadioTypes.filter(item => item.value !== 'pay_per_minute'));
        // setPaymentsTypesCategory('pay_per_session');
        // paymentsItemsForm.setFieldValue('rate_type', 'pay_per_session');
        setBtnNextDisabled(true);
      }
      setPayPerMinuteValue(payPerMinute);

      // TODO changed logic, comments for future developing
      // const payPerSession = callTypeStorage === 'video_call'
      //   ? (paymentsTypes.pay_per_session.call_per_session_rate.length ? paymentsTypes.pay_per_session.call_per_session_rate : [])
      //   : (paymentsTypes.pay_per_session.audio_call_per_session_rate.length ? paymentsTypes.pay_per_session.audio_call_per_session_rate : []);
      //
      // if (payPerSession.length === 0) {
      //   setPaymentsRadioTypes(paymentsRadioTypes.filter(item => item.value !== 'pay_per_session'));
      // }

      // setPayPerSessionValues(payPerSession);
      setMessageTime(callProcess.call_prebooked_time ?? 10);
      setIsSetupPage(true);
    }
  }, [paymentsTypes, callProcess]);

  const onChangePayments = (value: any) => {
    setPaymentsTypesCategory(value.target.value);
  };

  // TODO changed logic, comments for future developing
  // const selectPayPerSession = (value: string) => {
  //   paymentsItemsForm.setFieldValue('call_duration', value);
  //   setBtnNextDisabled(false);
  // }

  const onValuesChange = (changedValues: { rate_type: string },
                          allFormValues: { rate_type: string | undefined, call_duration: string | undefined }) => {
    if (allFormValues.rate_type !== undefined && allFormValues.rate_type !== '') {
      const callDuration = paymentsItemsForm.getFieldValue('call_duration');
      if (changedValues.rate_type === 'pay_per_session'
        && (callDuration === undefined || callDuration === '')) {
        setBtnNextDisabled(true);
      } else {
        setBtnNextDisabled(false);
      }
    } else {
      setBtnNextDisabled(true);
    }
  };

  const startCallAction = async (call_id: number): Promise<void> => {
    const { payload } = await dispatch(startCall({
      call_id,
      temporary_token: temporaryToken ?? ''
    }));

    if (!!payload?.error) {
      return;
    }

    navigate(`/call/outgoing`);
  }

  const nextStep = (): void => {
    if (btnNextDisabled) return;

    callSelectedRef.current = true;
    const { rate_type, call_duration } = paymentsItemsForm.getFieldsValue();

    const callId = localStorage.getItem('call_id');
    const callType = localStorage.getItem('call_type');

    if (callId && callType && (callType === 'audio_call' || callType === 'video_call')) {
      dispatch(updateCall({
        call_id: +callId,
        call_type: callType,
        rate_type: rate_type,
        call_duration: call_duration,
        temporary_token: temporaryToken ?? ''
      })).then((res: { payload: { call?: ICallProcess; error?: any } }) => {
        if (res.payload?.error) {
          // dispatch(openNotification({
          //   description: res.payload.error,
          //   type: 'error'
          // }));
          callSelectedRef.current = false;
          return;
        }

        const free_status = res.payload.call?.free_status;
        const is_required_prepay = res.payload.call?.is_required_prepay;

        if ((free_status === 'partly_free' && !is_required_prepay) || free_status === 'full_free') {
          startCallAction(+callId);
          return;
        }

        dispatch(getCallInfo({
          call_id: +callId,
          temporary_token: temporaryToken ?? ''
        })).then(() => navigate(`/call/checkout`));
      });
    } else {
      dispatch(openNotification({
        description: 'Call ID or Call Type are not correct',
        type: 'error'
      }));
    }
  }

  const cancelAction = (): void => {
    const workerId = localStorage.getItem('worker_id');
    const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;
    localStorage.removeItem('worker_id');
    localStorage.removeItem('call_type');
    localStorage.removeItem('call_id');
    localStorage.removeItem('audio_status');
    localStorage.removeItem('video_status');
    localStorage.removeItem('return_pathname');
    navigate(returnPathname);
  }

  const onBack = (): void => {
    navigate('/call/devices-settings');
  }

  return (<div className={ 'payments-types' }>
    <div className={ 'title' }><CustomIcon className={ 'back-icon' } name={ EIconName.ArrowLeft2 } onClick={ onBack }/>Select
      Payment Type
    </div>
    <div className={ 'inside-section' }>
      <div className={ 'payments-list' }>
        <div className={ 'payments-items' }>
          <Form
            form={ paymentsItemsForm }
            name="payments-items-form"
            onValuesChange={ onValuesChange }>

            { isSetupPage && <Form.Item
              name="rate_type">
              <RadioGroup className="item-category" onChange={ onChangePayments } options={ paymentsRadioTypes }/>
            </Form.Item> }

            { !isSetupPage && <SpinElement/> }

            { isSetupPage && paymentsTypesCategory === 'pay_per_minute' && <div>
              <div className={ `item-per-minute` }>
                { (!paymentsTypes?.pay_per_minute?.full_free_service_enabled && !paymentsTypes?.pay_per_minute?.free_minutes_enabled) && <>
                  <span>{ payPerMinuteValue }</span>/min</> }
                { (!!paymentsTypes?.pay_per_minute?.full_free_service_enabled) && <span>Free</span> }
                { (!!paymentsTypes?.pay_per_minute?.free_minutes_enabled && !paymentsTypes?.pay_per_minute?.full_free_service_enabled) && <>
                  <span>{ paymentsTypes?.pay_per_minute.free_service_minutes }</span>min
                  for <span>Free</span>next <span>{ payPerMinuteValue }</span>/min</> }
              </div>
              { (!paymentsTypes?.pay_per_minute?.full_free_service_enabled) && <InfoMessage
                infoMessage={ `Please note that a pre-authorization of funds will be applied for a <b>${ messageTime }-minutes</b> call
              duration. If the call is shorter, the funds will be refunded automatically.` }/> }
            </div> }

            {/*TODO changed logic, comments for future developing*/ }
            {/*{ isSetupPage && paymentsTypesCategory === 'pay_per_session' && payPerSessionValues*/ }
            {/*  &&*/ }
            {/*  <Form.Item*/ }
            {/*    name="call_duration">*/ }
            {/*    <RadioGroup className="item-per-session">*/ }
            {/*      {*/ }
            {/*        payPerSessionValuesSorted.map((item: ICallPerSessionRate, index: number) => {*/ }
            {/*          return (*/ }
            {/*            <div key={ index } className="radio-group-item"*/ }
            {/*                 onClick={ () => selectPayPerSession(item.call_duration) }>*/ }
            {/*              <div className="radio-group-item-icon">*/ }
            {/*                <CustomIcon size={ 'size-xll' } name={ EIconName.Schedule } color={ EIconColor.White }/>*/ }
            {/*              </div>*/ }
            {/*              <div className="radio-group-item-info">*/ }
            {/*                { (!item?.full_free_service_enabled && !item?.free_minutes_enabled) && <>*/ }
            {/*                  <span>{ item.call_duration }</span> min <span*/ }
            {/*                  className={ 'dash-space' }>&ndash;</span><span> { item.rates.text }</span>*/ }
            {/*                </> }*/ }

            {/*                { (!!item?.full_free_service_enabled) && <>*/ }
            {/*                  <span>{ item.call_duration }</span> min <span*/ }
            {/*                  className={ 'dash-space' }>&ndash;</span><span> Free</span>*/ }
            {/*                </> }*/ }

            {/*                { (!!item?.free_minutes_enabled && !item?.full_free_service_enabled) && <>*/ }
            {/*                  <span>{ item.call_duration }</span> min <span*/ }
            {/*                  className="tooltip-span">+ { item.free_service_minutes }<CustomTooltip*/ }
            {/*                  title={ `Session has ${ item.free_service_minutes } minutes for Free` }*/ }
            {/*                  placement={ 'top' } overlayClassName={ 'white-tooltip' }>*/ }
            {/*                  <CustomIcon name={ EIconName.Info } size={ 'size-sm' }/>*/ }
            {/*                </CustomTooltip></span>*/ }
            {/*                  <span className={ 'dash-space' }>&ndash;</span><span> { item.rates.text }</span>*/ }
            {/*                </> }*/ }
            {/*              </div>*/ }
            {/*              <div className="radio-group-item-actions">*/ }
            {/*                <Radio key={ index } value={ item.call_duration }/>*/ }
            {/*              </div>*/ }
            {/*            </div>*/ }
            {/*          )*/ }
            {/*        })*/ }
            {/*      }*/ }
            {/*    </RadioGroup>*/ }
            {/*  </Form.Item> }*/ }
          </Form>
        </div>

        <div className={ 'actions-section' }>
          <CustomButton type="primary" size={ 'medium' } onClick={ cancelAction }>
            Cancel
          </CustomButton>
          <CustomButton type="default" size={ 'medium' } onClick={ nextStep }
                        disabled={ btnNextDisabled || isLoad } loading={ loading }>
            { (!!worker?.full_free_service_enabled) ? 'Start' : 'Next' }
          </CustomButton>
        </div>
      </div>
    </div>
  </div>);
}
