import React from 'react';
import { useSelector } from "react-redux";

// Components
import { NetworkDisconnected } from '../modules/network-disconnected/network-disconnected';

// Styles
import './status-network-disconnected-counsellor.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const StatusNetworkDisconnectedCounsellor = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  return (<NetworkDisconnected title={ `Oops, something went wrong on the ${ businessTerms.Consultant } side` }/>);
}
