import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices
import { GlobalConfigSliceActions } from './global-config.slice';

// Models
import { ITerminologySettings } from "../../interfaces/keywords.interfaces";
import { IIllustrations } from "../../interfaces/illustrations.interfaces";
import { ELocalStorageKeys } from "../../enums/storage.enums";

// Utils
import { terminologyToKeywords } from "../../utils/terminology";

export enum EGlobalConfigThunks {
  SetBusinessTerms = 'GLOBAL_CONFIG/setBusinessTerms',
  SetIllustrations = 'GLOBAL_CONFIG/setIllustrations',
  UpdateBusinessTermsFromSocket = 'GLOBAL_CONFIG/updateBusinessTermsFromSocket',
  UpdateIllustrationsFromSocket = 'GLOBAL_CONFIG/updateIllustrationsFromSocket',
  ClearGlobalConfigState = 'GLOBAL_CONFIG/clearGlobalConfigState',
}

export const setBusinessTerms = createAsyncThunk(
  EGlobalConfigThunks.SetBusinessTerms,
  async (terms: ITerminologySettings, { dispatch }) => {
    const keywords = terminologyToKeywords(terms);
    localStorage.setItem(ELocalStorageKeys.BusinessTerms, JSON.stringify(keywords));
    dispatch(GlobalConfigSliceActions.updateBusinessTermsFromSocket(keywords));
    return keywords;
  }
);

export const setIllustrations = createAsyncThunk(
  EGlobalConfigThunks.SetIllustrations,
  async (illustrations: IIllustrations, { dispatch }) => {
    localStorage.setItem(ELocalStorageKeys.Illustrations, JSON.stringify(illustrations));
    dispatch(GlobalConfigSliceActions.updateIllustrationsFromSocket(illustrations));
    return illustrations;
  }
);

export const updateBusinessTermsFromSocket = createAsyncThunk(
  EGlobalConfigThunks.UpdateBusinessTermsFromSocket,
  async (terms: ITerminologySettings, { dispatch }) => {
    const keywords = terminologyToKeywords(terms);
    localStorage.setItem(ELocalStorageKeys.BusinessTerms, JSON.stringify(keywords));
    dispatch(GlobalConfigSliceActions.updateBusinessTermsFromSocket(keywords));
    return keywords;
  }
);

export const updateIllustrationsFromSocket = createAsyncThunk(
  EGlobalConfigThunks.UpdateIllustrationsFromSocket,
  async (illustrations: IIllustrations, { dispatch }) => {
    localStorage.setItem(ELocalStorageKeys.Illustrations, JSON.stringify(illustrations));
    dispatch(GlobalConfigSliceActions.updateIllustrationsFromSocket(illustrations));
    return illustrations;
  }
);

export const clearGlobalConfigState = createAsyncThunk(
  EGlobalConfigThunks.ClearGlobalConfigState,
  async (_, { dispatch }) => {
    dispatch(GlobalConfigSliceActions.clearGlobalConfigState());
    return true;
  }
);
