import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Shared UI Library Components
import { CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { TempUserSignUpModal } from '../../../../modals/temp-user-sign-up-modal/temp-user-sign-up-modal';

// Store Entities
import { getCoreState } from '../../../../store/core/core.selectors';
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";

// Models
import { IExpertProfile } from '../../../../interfaces/experts.interfaces';

// Constants
import { CExpertMenuList } from '../../../../configs/components/expert-menu.config';

// Styles
import './expert-profile-menu.scss';

// Models
import { TUserMenuActions } from "../../../../types/user-actions.types";

type TExpertProfileMenuProps = {
  expert: IExpertProfile;
}

export const ExpertProfileMenu: FC<TExpertProfileMenuProps> = (
  { expert }: TExpertProfileMenuProps
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');
  const temporaryToken = localStorage.getItem('temporary_token');

  const { path: { current: currentPath } } = useSelector(getCoreState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const params = useParams();
  const expertMenuList = useMemo(() => CExpertMenuList.map(menuItem => {
    return {
      ...menuItem,
      label: menuItem.label.replace('Consultation', businessTerms.Consultation),
      enabledTooltip: menuItem.enabledTooltip.replace('Consultation', businessTerms.Consultation),
      disabledTooltip: menuItem.disabledTooltip.replace('Consultation', businessTerms.Consultation).replace('counsellor', businessTerms.consultant),
      noBalanceTooltip: menuItem.noBalanceTooltip.replace('Consultation', businessTerms.Consultation),
    }
  }), [CExpertMenuList, businessTerms]);
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);
  const personalLink = useMemo(() => params?.personalLink, [params?.personalLink]);

  const [menuItems, setMenuItems] = useState(expertMenuList);
  const [tempUserSignUpModalOpen, setTempUserSignUpModalOpen] = useState(false);
  const [isMessagesAction, setIsMessagesAction] = useState(false);
  const [actionPath, setActionPath] = useState('');
  const [actionTypeValue, setActionTypeValue] = useState<TUserMenuActions>(null);

  useEffect(() => {
    if (expert) {
      const isVideoCallAvailable = (expert.call_rate.value !== 0) && expert.is_available && !expert.is_calling;

      const isAudioCallAvailable = (expert.audio_call_rate.value !== 0) && expert.is_available && !expert.is_calling;

      const isTextChatAvailable = (expert.conversation_rate.value !== 0) && expert.is_available && !expert.is_calling;

      const isMessagesAvailable = expert.message_rate.value !== 0;

      setMenuItems(expertMenuList.map((item) => {
        if (item.actionType && item.actionType === 'video_call') {
          item.disabled = !isVideoCallAvailable || !expert.has_available_minutes;
          item.hide = (!expert.is_video_call_enabled) || expert?.is_blocked || !expert?.is_active;
        }
        if (item.actionType && item.actionType === 'audio_call') {
          item.disabled = !isAudioCallAvailable || !expert.has_available_minutes;
          item.hide = (!expert.is_audio_call_enabled) || expert?.is_blocked || !expert?.is_active;
        }
        if (item.actionType && item.actionType === 'messages') {
          item.disabled = !isMessagesAvailable || !expert.has_available_requests;
          item.hide = !expert.is_messages_enabled || expert?.is_blocked || !expert?.is_active;
        }
        if (item.actionType && item.actionType === 'conversation') {
          item.disabled = !isTextChatAvailable || !expert.has_available_minutes;
          item.hide = !expert.is_conversation_enabled || expert?.is_blocked || !expert?.is_active;
        }
        if (item.actionType && item.actionType === 'book-a-call') {
          item.hide = expert?.is_blocked || !expert?.is_active;
        }
        // if (item.actionType && item.actionType === 'history') {
        //   item.disabled = !temporaryToken && !token;
        // }
        // if (item.actionType && item.actionType === 'transactions') {
        //   item.disabled = !temporaryToken && !token;
        // }
        return item;
      }));
    }
  }, [expert])

  const onMenuItemClickHandler = (path: string, actionTypeValue: string | null, disabled: boolean): void => {
    if (disabled) return;

    setActionTypeValue(actionTypeValue as any);

    if (actionTypeValue && actionTypeValue !== 'conversation') {
      localStorage.setItem('return_pathname', location.pathname);
      localStorage.setItem('call_type', actionTypeValue);
      localStorage.setItem('worker_id', expert.id + '');
    }

    if (actionTypeValue && actionTypeValue === 'conversation') {
      localStorage.setItem('return_pathname', location.pathname);
      localStorage.setItem('worker_id', expert.id + '');
    }

    if (actionTypeValue && !token && !temporaryToken) {
      setIsMessagesAction(false);
      if (actionTypeValue === 'messages') {
        path = `${ companyCode }/${ personalLink }/messages`;
        setIsMessagesAction(true);
      }
      if (actionTypeValue === 'book-a-call') {
        path = `${ companyCode }/${ personalLink }/book-appointment`;
        setIsMessagesAction(true);
      }
      if (actionTypeValue === 'profile') {
        return;
      }
      setActionPath(path);
      setTempUserSignUpModalOpen(true);
      return;
    }

    if (actionTypeValue && actionTypeValue === 'messages' && !token && temporaryToken) {
      path = `${ companyCode }/${ personalLink }/messages`;
    }

    if (actionTypeValue && actionTypeValue === 'book-a-call' && !token && temporaryToken) {
      path = `${ companyCode }/${ personalLink }/book-appointment`;
    }

    // if (actionTypeValue === 'profile' && !token && temporaryToken) {
    //   path = `${ companyCode }/${ personalLink }`;
    // }
    //
    // if (actionTypeValue === 'transactions' && !token && temporaryToken) {
    //   path = `${ companyCode }/${ personalLink }/transactions`;
    // }
    //
    // if (actionTypeValue === 'history' && !token && temporaryToken) {
    //   path = `${ companyCode }/${ personalLink }/history`;
    // }

    navigate(`/${ path }`);
  }

  const signUpModalSubmit = () => {
    setTempUserSignUpModalOpen(false);
    navigate(`/${ actionPath }`);

    // setMenuItems(CExpertMenuList.map((item) => {
    //   if (item.actionType && item.actionType === 'history') {
    //     item.disabled = false;
    //   }
    //   if (item.actionType && item.actionType === 'transactions') {
    //     item.disabled = false;
    //   }
    //   return item;
    // }));
  }

  const signUpModalCancel = () => {
    localStorage.removeItem('return_pathname');
    localStorage.removeItem('call_type');
    localStorage.removeItem('worker_id');
    setActionPath('');
    setTempUserSignUpModalOpen(false);
  }

  const getTooltipByStatus = (disabled: boolean, disabledTooltip: string, enabledTooltip: string, noBalanceTooltip: string, actionType: string): string => {
    const rateKey = `${ actionType === 'video_call' ? 'call' : actionType }_rate` as keyof IExpertProfile;
    if (
      (!expert?.has_available_minutes && actionType !== 'messages') ||
      (expert?.worker_status === 'worker_busy' && actionType !== 'messages') ||
      (expert?.[rateKey]?.value === 0 && actionType !== 'messages')
    ) {
      return noBalanceTooltip;
    }
    if ((!expert?.has_available_requests && actionType === 'messages') || (!expert?.message_rate?.value && actionType === 'messages')) {
      return noBalanceTooltip;
    }
    if (disabled) {
      return disabledTooltip;
    }
    return enabledTooltip;
  }

  return (
    <div className="expert-profile-menu">
      {
        (expert?.free_minutes_enabled && !!expert?.is_active && !expert?.is_blocked) && <div className="item-wrapper free-minutes">
          First { expert?.free_service_minutes } min FREE
        </div>
      }
      {
        menuItems.map(({
                         withBg,
                         icon,
                         activeIcon,
                         label,
                         path,
                         actionType,
                         disabled,
                         hide,
                         isSecure,
                         enabledTooltip,
                         disabledTooltip,
                         noBalanceTooltip,
                       }) => {
          const replacedPath = path.replace('$ID', `${ expert.id }`);
          const actionTypeValue = actionType ?? null;
          const splitPath = currentPath.split('/');
          const isActive = currentPath === replacedPath ||
            (actionType === 'messages' && currentPath.includes('messages')) ||
            (actionType === 'profile' && decodeURIComponent(splitPath[splitPath.length - 1]) === expert.personal_link) ||
            currentPath.includes(actionType);

          const showTempUser = !token ? !isSecure : true;
          return (!hide && showTempUser && <div className="item-wrapper" key={ label }>
            <div
              className={ `expert-profile-menu--item ${ disabled ? 'disabled-btn' : '' } ${ withBg ? 'item-bg' : '' } ${ isActive ? 'item-active' : '' } ${ actionTypeValue }` }
              onClick={ () => onMenuItemClickHandler(replacedPath, actionTypeValue, disabled) }>
              <CustomTooltip placement="bottom" title={
                getTooltipByStatus(disabled, disabledTooltip, enabledTooltip, noBalanceTooltip, actionType)
              }>
                { isActive ? activeIcon : icon }
              </CustomTooltip>
            </div>
            { (actionTypeValue === 'messages' || actionTypeValue?.includes('call') || actionTypeValue === 'conversation') ?
              <div className="expert-profile-menu--price">
                { actionTypeValue === 'video_call' &&
                  <CustomTooltip placement="bottom" title={ 'Video cost per minute' }>
                    { expert?.full_free_service_enabled ? <span className="bold-text">Free</span> : <>
                      <span className="bold-text">{ expert.call_rate.text }</span> /min
                    </> }
                  </CustomTooltip> }
                { actionTypeValue === 'audio_call' &&
                  <CustomTooltip placement="bottom" title={ 'Voice cost per minute' }>
                    { expert?.full_free_service_enabled ? <span className="bold-text">Free</span> : <>
                      <span className="bold-text">{ expert.audio_call_rate.text }</span> /min
                    </> }
                  </CustomTooltip> }
                { actionTypeValue === 'conversation' &&
                  <CustomTooltip placement="bottom" title={ 'Text Chat cost per minute' }>
                    { expert?.full_free_service_enabled ? <span className="bold-text">Free</span> : <>
                      <span className="bold-text">{ expert.conversation_rate.text }</span> /min
                    </> }
                  </CustomTooltip> }
                { actionTypeValue === 'messages' &&
                  <CustomTooltip placement="bottom" title={ 'Correspondence cost per request' }>
                    { expert?.full_free_service_enabled ? <span className="bold-text">Free</span> : <>
                      <span className="bold-text">{ expert.message_rate.text }</span> /rqst
                    </> }
                  </CustomTooltip>
                }
                { actionTypeValue === 'book-a-call' &&
                  <CustomTooltip placement="bottom" title={ 'Book a Call' }>
                    <span className="bold-text">Book</span>
                  </CustomTooltip>
                }
              </div> : '' }
          </div>)
        })
      }

      { companyCode
        && <TempUserSignUpModal
          type={ actionTypeValue }
          open={ tempUserSignUpModalOpen }
          companyCode={ companyCode }
          isMessagesAction={ isMessagesAction }
          onSubmit={ signUpModalSubmit }
          onCancel={ signUpModalCancel }/> }
    </div>
  );
};
