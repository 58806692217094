import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalCardFieldsProvider, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PayPalCardFieldsStateObject } from "@paypal/paypal-js/types/components/card-fields";

import { Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  DropdownElement,
  EIconColor,
  InfoMessage,
  RadioGroup,
  SpinElement,
  TElementMode
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './payment-form-paypal.scss';

// Services
import { PaymentsService } from "../../../services/payments.service";

// Store actions
import { createPaymentCard, fetchPaymentsCards, payExistingPaymentCard } from '../../../store/payments/payments.thunks';
import { getPaymentsData } from '../../../store/payments/payments.selectors';

// Components
import { CardFields } from "../card-fields/card-fields";
import { SubmitPaymentPaypal } from "../submit-payment-paypal/submit-payment-paypal";

// Models
import { TRateType } from '../../../interfaces/call.interfaces';
import { TCallType } from '../../../interfaces/call.interfaces';
import { ICurrency } from '../../../interfaces/company.interfaces';

// Configs
import { CPayPalScriptProviderOptions, CPayPalStylesOptions } from "../../../constantes/paypal.constantes";

const { Item: FormControl } = Form;

export interface IPaymentFormPaypalProps {
  consultation_type: TCallType;
  worker_id: string | number,
  currency: ICurrency,
  mode?: TElementMode;
  rate?: number,
  rate_type?: TRateType,
  call_duration?: number | string,
  btn_text?: string,
  cancelBtn?: ReactNode,
  onPaid?: (pi: string) => void;
}

export const PaymentFormPaypal: FC<IPaymentFormPaypalProps> = (
  {
    consultation_type,
    worker_id,
    currency,
    rate_type,
    rate = 0,
    mode = 'white',
    call_duration = '',
    btn_text = '',
    cancelBtn = '',
    onPaid = () => {}
  }: IPaymentFormPaypalProps
) => {
  const [form] = Form.useForm<{
    card?: string,
    cardType: string,
  }>();
  const token = localStorage.getItem('token');

  const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(true);
  const [hiddenPaymentElement, setHiddenPaymentElement] = useState<boolean>(true);

  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [cardState, setCardState] = useState<PayPalCardFieldsStateObject | null>(null);
  const [isPaymentProcess, setIsPaymentProcess] = useState<boolean>(false);

  const { cards, defaultCard, isLoad } = useSelector(getPaymentsData);

  const btnText = btn_text ? btn_text : `Pay ${ currency.symbol }${ rate } and Send Request`;

  const cardOptions = cards?.map(({ id, brand, last4 }) => ({
    value: id,
    label: `${ brand } **** ${ last4 }`
  }));

  const dispatch = useDispatch<any>();

  /**
   * Shows a payment status notification and performs actions based on the status.
   * @param {'error' | 'success'} status - The payment status.
   * @param {string} message - The message to display in the notification.
   * @param {string | undefined} pi - The payment intent ID.
   * @returns {void}
   */
  const showNotificationEndOfPayment = (status: 'error' | 'success', message: string, pi?: string): void => {
    setIsPaymentProcess(false);

    if (status === 'error' || !pi) {
      setPaymentError(message);
      return;
    }

    onPaid(pi);
  }

  /**
   * Handles payment using an existing payment card.
   * @returns {Promise<void>}
   */
  const payExistsCard = async (pm?: string): Promise<void> => {
    // Clear any previous payment errors.
    setIsPaymentProcess(true);
    setPaymentError(null);

    // Get the selected card from the form field.
    const payment_method = pm ? pm : await form.getFieldValue('card');

    // Dispatch an action to initiate payment using an existing payment card.
    const { payload: { error, data } } = await dispatch(payExistingPaymentCard({
      consultation_type,
      rate_type,
      call_duration,
      worker_id,
      payment_method
    }));

    // If there is an error or Stripe is not available, show an error notification.
    if (error) {
      showNotificationEndOfPayment('error', error ?? 'Something went wrong, payment failed, try again');
      return;
    }

    // Show a success notification for the completed payment.
    showNotificationEndOfPayment('success', 'Successful reserved!', data?.payment_intent_id);
  }

  /**
   * Handles changes in the selected card type.
   * @param {string} cardType - The selected card type.
   * @returns {void}
   */
  const cardTypeChangeHandler = (cardType: string): void => {
    setPaymentError('');
    setHiddenPaymentElement(cardType !== 'newCard');
  }

  /**
   * Loads payment cards and updates the component state.
   * @returns {void}
   */
  const loadPaymentsCards = async (isReload: boolean = false): Promise<void> => {
    setIsCardLoading(true);
    if (token) {
      const { payload } = await dispatch(fetchPaymentsCards());
      setHiddenPaymentElement(isReload ? false : !!payload?.payload?.cards?.length);
    } else {
      setHiddenPaymentElement(false);
    }
    setIsCardLoading(false);
  }

  const onCardChangeHandler = (): void => {
    setPaymentError(() => null);
  }

  /**
   * Function to request and create a Vault Setup Token from the server.
   * This token is needed for setting up the card with PayPal.
   * @returns {Promise<string>} - Vault Setup Token from PayPal
   */
  const createVaultSetupToken = async (): Promise<string> => {
    const { data, error } = await PaymentsService.getClientSecret({
      consultation_type,
      rate_type,
      call_duration,
      worker_id,
      is_remember_me: true,
      only_setup_intent: true,
      temporary_token: localStorage.getItem('temporary_token') ?? ''
    });

    if (!data) {
      showNotificationEndOfPayment('error', error || 'Something went wrong, failed to get payment confirmation, try again');
      throw new Error(error || 'Payment Secret not found.');
    }

    return data?.client_secret_intent;
  }

  /**
   * Handles the approval of a payment method.
   * This function dispatches the payment creation action and shows a notification.
   * @param {Object} data - Data returned from PayPal after the card is processed
   */
  const onApprove = async (data: any & { vaultSetupToken?: string }): Promise<void> => {
    if (!data?.vaultSetupToken) {
      setPaymentError('PayPal setup token not yet created');
      return;
    }
    setIsPaymentProcess(true);

    const { payload } = await dispatch(createPaymentCard({
      si: data?.vaultSetupToken
    }));

    if (!payload?.card) {
      showNotificationEndOfPayment('error', 'Something went wrong, payment failed, try again');
      return;
    }

    payExistsCard(payload?.card?.id);
  }

  /**
   * Handles errors during the PayPal process and sets an error message.
   * @param {Record<string, unknown>} error - Error object returned by PayPal SDK
   */
  const onError = async (error: Record<string, unknown>): Promise<void> => {
    console.log('error', error);
    setPaymentError(error?.error as string || 'Something went wrong, try again');
  }

  /**
   * Handles changes in the card fields, such as number, expiry, and CVV.
   * Updates the card state and checks if the form is complete and valid.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputEventsChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when an input changes
    setIsCardComplete(data.isFormValid);
    setCardState(data);
  }

  /**
   * Handles when a card field gets focus (e.g., the user clicks on a field).
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputFocusChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when a field gets focus
    // console.log('onFocus inputEvents', data);
  }

  /**
   * Handles when a card field loses focus (e.g., the user clicks out of a field).
   * Updates the card state.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputBlurChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when a field loses focus
    // console.log('onBlur inputEvents', data);
    setCardState(data);
  }

  /**
   * Handles when the card form is submitted.
   * Verifies if the card form is valid before submission.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputSubmitRequest = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // console.log('onInputSubmitRequest inputEvents', data);
    if (data.isFormValid) {
      // Submit the card form for the payer
    } else {
      // Inform payer that some fields are not valid
    }
  }

  useEffect(() => {
    loadPaymentsCards();
  }, []);

  return (
    <PayPalScriptProvider options={ CPayPalScriptProviderOptions }>
      {/*@ts-ignore*/ }
      <PayPalCardFieldsProvider
        onApprove={ onApprove }
        createVaultSetupToken={ createVaultSetupToken }
        onError={ onError }
        inputEvents={ {
          onChange: onInputEventsChange,
          onFocus: onInputFocusChange,
          onBlur: onInputBlurChange,
          onInputSubmitRequest: onInputSubmitRequest
        } }
        style={ CPayPalStylesOptions }
      >
        <Form form={ form } className={ `payment-form-paypal payment-form-paypal-${ mode }` }>
          <div className="payment-form--item">
            <InfoMessage mode={ mode }
                         infoMessage={ 'Unfortunately, The payment provider selected by the company does not support Apple Pay and Google Pay. To make purchases on this website, you will need to use alternative payment methods.' }/>
          </div>
          <div className="payment-form--item item-divider">
            Or pay with card
          </div>
          { (!!cards.length && !isCardLoading) && <div className="payment-form-paypal--item">
            <FormControl name="cardType" initialValue={ 'existingCard' }>
              <RadioGroup mode={ mode } onChange={ cardTypeChangeHandler } options={ [
                { value: 'existingCard', label: 'Use Existing Card' },
                { value: 'newCard', label: 'Use Different Card' },
              ] }/>
            </FormControl>
          </div> }
          <div className="payment-form-paypal--item item-payment-element" hidden={ hiddenPaymentElement }>
            <CardFields cardState={ cardState }/>
          </div>
          { isCardLoading && <div className="payment-form-paypal--item item-loader">
            <SpinElement/>
          </div> }
          { (!!cards.length && !isCardLoading) &&
            <div className="payment-form-paypal--item item-card-list" hidden={ !hiddenPaymentElement }>
              <FormControl name="card" initialValue={ !!defaultCard ? defaultCard : cardOptions[0]?.value }
                           valuePropName={ 'defaultValue' }>
                <DropdownElement loading={ isLoad } options={ cardOptions } withoutAll={ true } colorMode={ mode }
                                 placeholder={ 'Please select card' } onChange={ onCardChangeHandler }/>
              </FormControl>
            </div> }
          { paymentError && <div className="payment-form-paypal--error">
            <InfoMessage textSize={ 'big' } infoMessage={ paymentError } infoIconColor={ EIconColor.Error }
                         type={ 'error' }/>
          </div> }
          <div className="payment-form-paypal--item item-actions">
            { cancelBtn && cancelBtn }

            { hiddenPaymentElement && <CustomButton mode={ mode }
                                                    disabled={ isCardLoading || !!paymentError }
                                                    loading={ isPaymentProcess }
                                                    text={ btnText }
                                                    onClick={ () => payExistsCard() }
            /> }

            { !hiddenPaymentElement && <SubmitPaymentPaypal mode={ mode }
                                                            disabled={ isCardLoading || !isCardComplete || !!paymentError }
                                                            isProcess={ isPaymentProcess }
                                                            setIsProcess={ setIsPaymentProcess }
                                                            btnText={ btnText }
            /> }
          </div>
        </Form>
      </PayPalCardFieldsProvider>
    </PayPalScriptProvider>
  );
};
