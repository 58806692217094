import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Thunks
import { clearAuthState } from '../../../../store/auth/auth.thunks';

// Styles
import './form-bottom-actions.scss';

type Props = {
  infoText: string;
  isSignInLink?: boolean;
};

export const FormBottomActions = ({infoText, isSignInLink }: Props) => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const clickLink = (value: string) => {
    dispatch(clearAuthState()).then(() => navigate(value));
  }

  return (<div className="form-bottom-actions">
    <div className="info-text">{ infoText } {isSignInLink
      ? <a onClick={() => clickLink('/sign-in')}>Log In</a>
      : <a onClick={() => clickLink('/sign-up')}>Sign Up</a>}
    </div>
  </div>);
}
