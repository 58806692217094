import React from 'react';
import { useSelector } from "react-redux";

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-unavailable-no-payment.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const ChatStatusUnavailableNoPayment = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;

  return (<ConversationNotifications
    title={ 'Availability Issue' }
    text={ `The ${ businessTerms.Consultant } is no longer available. You may try to contact them later.` }
    navigateAction={ returnPathname }/>);
}
