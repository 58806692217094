import { FC } from 'react';

// Shared UI Library Components
import { TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './paypal-footer.scss';

type TPaypalFooterProps = {
  mode?: TElementMode;
}

export const PaypalFooter: FC<TPaypalFooterProps> = (
  { mode = 'white' }: TPaypalFooterProps
) => {

  return (
    <div className={ `paypal-footer paypal-footer-${ mode }` }>
      <div className="paypal-footer-item">Powered by <b className="bold-text">paypal</b></div>
      <div className="paypal-footer-item paypal-footer-item--divider"></div>
      <div className="paypal-footer-item">
        <a href="https://www.paypal.com/us/legalhub/useragreement-full" target="_blank">Terms</a>
      </div>
      <div className="paypal-footer-item">
        <a href="https://www.paypal.com/ua/legalhub/privacy-full" target="_blank">Privacy</a>
      </div>
    </div>
  );
};
