import { ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import { PayPalCardFieldsStyleOptions } from "@paypal/paypal-js/types/components/card-fields";

export const CPayPalScriptProviderOptions: ReactPayPalScriptOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
  components: ['card-fields', 'buttons'],
  // TODO ADD ENV
  environment: 'sandbox',
};

const computedStyle = getComputedStyle(document.documentElement);

export const CPayPalStylesOptions: Record<string, PayPalCardFieldsStyleOptions> = {
  body: {
    "padding": '3px',
  },
  input: {
    padding: '0.75rem',
    'font-family': '"Noto Sans", sans-serif',
    color: computedStyle.getPropertyValue('--primary'),
    'font-size': '1rem',
    outline: 'none',
    // @ts-ignore
    'border-radius': '10px',
    // @ts-ignore
    border: `0.0625rem solid #e6e6e6`,
  },
  'input:focus': {
    // @ts-ignore
    'box-shadow': `0 0 0 3px #BBD7F1`,
    // @ts-ignore
    border: `0.0625rem solid ${ computedStyle.getPropertyValue('--blue') }`,
  },
  'input.invalid': {
    color: `${ computedStyle.getPropertyValue('--red') } !important`,
    // @ts-ignore
    'box-shadow': `none`,
    border: `0.125rem solid ${ computedStyle.getPropertyValue('--red') } !important`,
  },
  'input.invalid:focus': {
    // @ts-ignore
    'box-shadow': `0 0 0 3px #BBD7F1 !important`,
  },
  'input.invalid:not(.display-icon)': {
    // @ts-ignore
    'box-shadow': `none !important`,
    border: `0.0625rem solid #e6e6e6 !important`,
  },
  ':focus': {
    outline: 'none',
  },
  'input.card-field-number.display-icon': {
    'padding-left': 'calc(1.2rem + 50px) !important'
  }
}
