import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './chat-checkout.scss';

// Components
import { ConsultationInfo, PaymentForm } from '../../../shared/payment-components';
import { FormProvider } from "../../../shared/paypal/form-provider/form-provider";

// Store entities
import { getChatInfo, startChat } from '../../../store/chat/chat.thunks';
import { getChatState } from '../../../store/chat/chat.selectors';
import { getCurrencyState } from '../../../store/currency/currency.selectors';
import { getUserState } from "../../../store/user/user.selectors";

export const ChatCheckout = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const temporaryToken = localStorage.getItem('temporary_token');

  const { chatProcess } = useSelector(getChatState);
  const { currentCurrency } = useSelector(getCurrencyState);
  const { user } = useSelector(getUserState);

  useEffect(() => {
    const conversation_id = localStorage.getItem('conversation_id');
    if (!chatProcess && conversation_id) dispatch(getChatInfo({
      conversation_id: +conversation_id,
      temporary_token: temporaryToken ?? ''
    }));
  }, [chatProcess])

  const onPaid = (payment_method_nonce: string): void => {
    const conversation_id = localStorage.getItem('conversation_id');
    if (!conversation_id) return;

    dispatch(startChat({
      conversation_id: +conversation_id,
      payment_method_nonce,
      temporary_token: temporaryToken ?? ''
    }))
      .then((res: { payload: any }) => {
        navigate(`/conversation/outgoing`);
      });
  }

  const onBack = (): void => {
    navigate('/conversation/chat-types');
  }

  return (<div className={ 'chat-checkout' }>
    <div className={ 'title' }><CustomIcon className={ 'back-icon' } name={ EIconName.ArrowLeft2 } onClick={ onBack }/>Checkout
    </div>
    { chatProcess && chatProcess.worker?.id && chatProcess.conversation_prebooked_time &&
      <div className="chat-checkout-inside">
        <div className="body-item body-item--info body-item--divider">
          <ConsultationInfo
            rate={ `${ chatProcess.worker_rate?.text }` }
            rate_time={ chatProcess.worker_rate?.call_duration ?? null }
            name={ `${ chatProcess.worker.first_name } ${ chatProcess.worker.last_name }` }
            type={ chatProcess.conversation_type }
            call_session_type={ chatProcess.conversation_session_type }
            prebooked_time={ chatProcess.conversation_prebooked_time }
            mode={ 'dark' }
            free_status={ chatProcess.free_status }
            free_service_minutes={ chatProcess.worker.free_service_minutes }
          />
        </div>
        <div className="body-item body-item--payment-form">
          { user?.payment_provider === 'stripe' && <PaymentForm
            currency={ currentCurrency }
            consultation_type={ chatProcess.conversation_type ?? 'video_call' }
            rate_type={ chatProcess.conversation_session_type }
            rate={ chatProcess.worker_rate?.value }
            call_duration={ chatProcess.conversation_session_type === 'pay_per_session' ? chatProcess.worker_rate?.call_duration : '' }
            worker_id={ chatProcess.worker.id }
            onPaid={ onPaid }
            btn_text={ `Pay ${ currentCurrency.symbol }${ ((chatProcess.amount || 0) / 100).toFixed(2) } and Start` }
            mode={ 'dark' }/> }

          { user?.payment_provider === 'paypal' && <FormProvider
            currency={ currentCurrency }
            consultation_type={ chatProcess.conversation_type ?? 'video_call' }
            rate_type={ chatProcess.conversation_session_type }
            rate={ chatProcess.worker_rate?.value }
            call_duration={ chatProcess.conversation_session_type === 'pay_per_session' ? chatProcess.worker_rate?.call_duration : '' }
            worker_id={ chatProcess.worker.id }
            onPaid={ onPaid }
            btn_text={ `Pay ${ currentCurrency.symbol }${ ((chatProcess.amount || 0) / 100).toFixed(2) } and Start` }
            mode={ 'dark' }/> }
        </div>
      </div> }
  </div>);
}
