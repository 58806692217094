import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

// Shared UI Library Components
import { CustomIcon, EIconName, InfoMessage } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './success-checkout.scss';

// Models
import { TCallType } from "../../../interfaces/call.interfaces";
import { IAppointmentRate } from "../../../interfaces/appointments.interfaces";
import { IKeywords } from "../../../interfaces/keywords.interfaces";

export type TSuccessCheckoutProps = {
  config: {
    businessTerms: IKeywords,
    name: string;
    date: string;
    duration: ReactNode;
    cost: string;
    period: number;
    rate: IAppointmentRate;
  };
  type?: TCallType;
}

export const SuccessCheckout: FC<TSuccessCheckoutProps> = (
  {
    config,
    type = 'video_call',
  }: TSuccessCheckoutProps
) => {

  const firstName = useMemo(() => (config?.name || '').split(' ')?.[0] || config.businessTerms.Consultant, [config]);

  const getTitleType = (): { icon: string, text: string } => {
    const types = {
      conversation: { icon: EIconName.Chat, text: 'Text Chat' },
      video_call: { icon: EIconName.CameraOn, text: `Video ${ config.businessTerms.Consultation }` },
      audio_call: { icon: EIconName.Call, text: `Voice ${ config.businessTerms.Consultation }` },
    };

    return types[type as keyof typeof types] || { icon: EIconName.Message, text: 'Correspondence' };
  }

  return (
    <div className="success-checkout">
      <div className="success-checkout--subtitle">
        You will receive an email notification as soon as { firstName } confirms/declines your request.
      </div>
      <div className="success-checkout--icon">
        <CustomIcon name={ EIconName.CheckCircle }/>
      </div>
      <div className="success-checkout--type">
        <CustomIcon name={ getTitleType().icon }/> { getTitleType().text }
      </div>
      <div className="success-checkout--with">
        with { config.name }
      </div>
      <div className="success-checkout--date">
        { config.date }
      </div>
      <div className="success-checkout--cost">
        { config.duration }
      </div>
      <div className="success-checkout--reminder">
        <InfoMessage
          infoMessage={ `The ${ config.businessTerms.Consultant } has a period of <b>${ config.period } minutes</b> to confirm the booking. If the confirmation is not made within this timeframe, the full amount of money will be refunded to you.` }
          mode={ 'white' }/>
      </div>
    </div>
  );
};
