// API
import { api } from '../configs/api/api';

// Models
import {
  ICreateDefaultPayment, ICreatedPaymentCard,
  ICreatePayment, IPaymentPaypalToken,
  IPaymentsInfo,
  IPaymentToken
} from '../interfaces/payments.interfaces';
import { ELocalStorageKeys } from "../enums/storage.enums";

export const PaymentsService = {
  fetchPaymentCards: async (): Promise<{ data?: IPaymentsInfo, error?: string }> => {
    try {
      const axiosResponse = await api.get('/users/stripe-cards');
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  selectPaymentCard: async (token: string): Promise<{
    selected_card?: string,
    message?: string,
    error?: string,
    status: string
  }> => {
    try {
      const axiosResponse = await api.post('/users/select-stripe-card', { token });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message, status: 'error' };
      }
      return { ...res };
    }
  },
  createPaymentCard: async (si: string): Promise<{
    card?: ICreatedPaymentCard,
    error?: string
  }> => {
    try {
      const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/create-stripe-card`, { token: si });
      return { card: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  deletePaymentCard: async (token: string): Promise<{
    selected_card?: string,
    message?: string,
    error?: string,
  }> => {
    try {
      const axiosResponse = await api.delete('/users/delete-stripe-card', {
        params: { token }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  getClientSecret: async (paymentInfo: ICreatePayment): Promise<{ data?: IPaymentToken, error?: string }> => {
    try {
      const axiosResponse = await api.get(`/${ paymentInfo.temporary_token ? 'temporary' : 'users' }/payment-token`, {
        params: {
          ...paymentInfo,
          is_remember_me: Number(paymentInfo.is_remember_me),
          is_wallet: Number(paymentInfo?.is_wallet ?? false),
          only_setup_intent: Number(paymentInfo?.only_setup_intent ?? false),
          payment_method_type: paymentInfo?.payment_method_type || 'card'
        }
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  getClientPaypalSecret: async (): Promise<{ data?: IPaymentPaypalToken, error?: string }> => {
    try {
      const axiosResponse = await api.get(`/users/paypal-setup-token`, {});
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  payExistingPaymentCard: async (paymentInfo: ICreateDefaultPayment): Promise<{
    data?: IPaymentToken,
    error?: string
  }> => {
    try {
      const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/default-payment-token`, {
        params: paymentInfo
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  }
}
