import { FC, useEffect, useState } from 'react';

import {
  CanMakePaymentResult, PaymentIntent,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeError
} from '@stripe/stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  EIconName,
  InfoMessage,
  SpinElement,
  TElementMode
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './wallet-button.scss';

// Service
import { PaymentsService } from '../../../services/payments.service';

// Models
import { TRateType, TCallType } from '../../../interfaces/call.interfaces';
import { IPaymentToken } from '../../../interfaces/payments.interfaces';
import { ICurrency } from '../../../interfaces/company.interfaces';

// Configs
import { CPaymentRequestConfig } from '../../../constantes/payment-form.constantes';

export interface IWalletButtonProps {
  stripe: Stripe | null;
  currency: ICurrency;
  rate_type?: TRateType,
  mode?: TElementMode;
  consultation_type?: TCallType,
  worker_id?: string | number,
  call_duration?: number | string,
  showNotificationEndOfPayment: (status: 'error' | 'success', message: string, pi?: string) => void;
}

export const WalletButton: FC<IWalletButtonProps> = (
  {
    stripe,
    currency,
    rate_type,
    mode = 'white',
    consultation_type,
    worker_id,
    call_duration,
    showNotificationEndOfPayment,
  }: IWalletButtonProps
) => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult | null>(null);

  /**
   * Handler for confirming a card payment using Stripe.
   * @param {PaymentRequestPaymentMethodEvent} ev - Payment request event.
   * @param paymentIntent
   * @param {StripeError|undefined} confirmError - Error from confirming payment.
   * @param {string} payment_intent_secret - Payment intent secret.
   * @returns {Promise<void>}
   */
  const confirmCardPaymentHandler = async (
    ev: PaymentRequestPaymentMethodEvent,
    paymentIntent: PaymentIntent | undefined,
    confirmError: StripeError | undefined,
    payment_intent_secret: string,
  ): Promise<void> => {
    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close the payment interface.
      showNotificationEndOfPayment('error', confirmError.message ?? 'Payment Error!');
      ev.complete('fail');
      return;
    }
    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    ev.complete('success');

    // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
    // handle the flow. If using an API version older than "2019-02-11"
    // instead check for: `paymentIntent.status === "requires_source_action"`. If the PaymentIntent requires an action (e.g., 3D Secure), handle it.
    if (paymentIntent?.status === "requires_action") {
      // Confirm the card payment with the provided payment_intent_secret.
      const paymentIntentResult = await stripe?.confirmCardPayment(payment_intent_secret);

      if (paymentIntentResult?.error) {
        // The payment failed -- ask your customer for a new payment method.
        showNotificationEndOfPayment('error', paymentIntentResult?.error?.message ?? 'Confirm Card Payment Error!');
      } else {
        // The payment has succeeded.
        showNotificationEndOfPayment('success', 'Successful reserved!', paymentIntent?.id as string);
      }
    } else {
      // The payment has succeeded.
      showNotificationEndOfPayment('success', 'Successful reserved!', paymentIntent?.id as string);
    }
  }

  /**
   * Handler for payment method event in PaymentRequest.
   * @param {PaymentRequestPaymentMethodEvent} ev - Payment request event.
   * @returns {Promise<void>}
   */
  const paymentMethodHandler = async (ev: PaymentRequestPaymentMethodEvent): Promise<void> => {
    const paymentToken = await fetchClientSecret();

    if (!stripe || !paymentToken) return;

    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      paymentToken.payment_intent_secret as string, { payment_method: ev.paymentMethod.id }, { handleActions: false }
    );

    confirmCardPaymentHandler(ev, paymentIntent, confirmError, paymentToken.payment_intent_secret as string);
  }

  /**
   * Fetches the client secret for the payment.
   * @param {boolean} is_wallet - Indicates if wallet payment is requested.
   * @returns {Promise<IPaymentToken|null>} - The payment token or null if not available.
   */
  const fetchClientSecret = async (is_wallet: boolean = false): Promise<IPaymentToken | null> => {
    if (!consultation_type || !worker_id) return null;

    const { data, error } = await PaymentsService.getClientSecret({
      rate_type,
      consultation_type,
      call_duration,
      worker_id,
      is_wallet,
      is_remember_me: false,
      payment_method_type: 'google',
      temporary_token: localStorage.getItem('temporary_token') ?? ''
    });

    if (!data) {
      showNotificationEndOfPayment('error', error ?? 'Something went wrong, failed to get payment confirmation, try again');
      return null;
    }

    return data;
  }

  /**
   * Updates the payment request with a new amount.
   * @param {PaymentRequest} pr - The PaymentRequest instance.
   * @param {number} amount - The new amount for payment.
   * @returns {Promise<void>}
   */
  const updatePaymentRequest = async (pr: PaymentRequest, amount: number): Promise<void> => {
    return pr?.update({
      total: {
        amount,
        label: `Pay for ${ consultation_type === 'message' ? 'message' : 'video_call' }`
      },
    });
  }

  /**
   * Opens the wallet payment request.
   * @returns {Promise<void>}
   */
  const openWalletPayment = async (): Promise<void> => {
    try {
      // Attempt to show the payment request.
      paymentRequest?.show();
    } catch (error: any) {
      // If an error occurs, show an error notification.
      showNotificationEndOfPayment('error', error ?? 'Something went wrong, failed to open wallet payment, try again');
    }
  }

  /**
   * Creates a wallet payment request and initializes payment options.
   * @returns {Promise<void>}
   */
  const createWalletPaymentRequest = async (): Promise<void> => {
    if (!stripe) return;

    // Create a PaymentRequest instance using stripe.paymentRequest.
    const pr: PaymentRequest = stripe.paymentRequest({
      ...CPaymentRequestConfig,
      currency: currency.name.toLowerCase(),
    });

    try {
      // Check the availability of the Payment Request API.
      const canMakeResult = await pr.canMakePayment();

      if (!canMakeResult) { // { "applePay": boolean, "googlePay": boolean, "link": boolean }
        // Update state to indicate that payment methods are not available.
        return setCanMakePayment({ applePay: false, googlePay: false, link: false });
      }

      // Fetch the payment token with wallet-specific details.
      const paymentToken = await fetchClientSecret(true);

      if (!paymentToken?.amount) {
        setCanMakePayment({ applePay: false, googlePay: false, link: false });
        return;
      }

      // Update the PaymentRequest with the payment amount and label.
      await updatePaymentRequest(pr, paymentToken?.amount ?? 0);

      // Update state with available payment methods and the PaymentRequest instance.
      setCanMakePayment(canMakeResult);
      setPaymentRequest(pr);
    } catch (error) {
      // If an error occurs, set available payment methods to false.
      setCanMakePayment({ applePay: false, googlePay: false, link: false });
    }
  }

  useEffect(() => {
    if (!paymentRequest) return;
    // Attach paymentMethodHandler to the payment method event.
    paymentRequest.on('paymentmethod', paymentMethodHandler);
  }, [paymentRequest]);

  useEffect(() => {
    // Create and initialize the wallet payment request.
    createWalletPaymentRequest();
  }, [stripe]);

  useEffect(() => {
    // Clean up event listener when unmounting.
    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', paymentMethodHandler);
      }
    };
  }, []);

  return (
    <div className={ `wallet-button wallet-button-${ mode }` }>
      { canMakePayment?.applePay && <div className="wallet-button--item">
        <CustomButton mode={ mode }
                      icon={ <CustomIcon size={ 'size-x' } name={ EIconName.ApplePay }/> }
                      iconPosition={ 'left' }
                      type="primary"
                      text={ 'Pay' }
                      onClick={ () => openWalletPayment() }
        />
      </div> }
      { canMakePayment?.googlePay && <div className="wallet-button--item">
        <CustomButton mode={ mode } icon={ <CustomIcon size={ 'size-x' } name={ EIconName.GooglePay }/> }
                      iconPosition={ 'left' }
                      type="primary"
                      text={ 'Pay' } onClick={ () => openWalletPayment() }/>
      </div> }
      { (canMakePayment !== null && !canMakePayment?.googlePay && !canMakePayment?.applePay) &&
        <div className="wallet-button--item">
          <InfoMessage mode={ mode }
                       infoMessage={ 'Unfortunately, your browser does not support Apple Pay and Google Pay. To make purchases on this website, you will need to use alternative payment methods.' }/>
        </div> }
      { (canMakePayment === null) && <div className="wallet-button--item">
        <SpinElement/>
      </div> }
    </div>
  );
};
