import axios, { AxiosError } from 'axios';

// API
import { api } from '../configs/api/api';

// Models
import {
  IAppointment, IAppointmentCallStarted,
  IAppointmentsConfig,
  IAppointmentsSettings,
  ICreateAppointment
} from "../interfaces/appointments.interfaces";
import { ELocalStorageKeys } from "../enums/storage.enums";

export const AppointmentsService = {
  fetchAppointmentsConfig: async ({ worker_id, appointment_day, call_type, call_duration }: {
    worker_id: number | string,
    appointment_day: string,
    call_type?: string,
    call_duration?: string
  }): Promise<{
    data?: IAppointmentsConfig,
    error?: any,
    status: number
  }> => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);
    return await api.get<IAppointmentsConfig>(`/${ temporary_token ? 'temporary' : 'users' }/appointments`, {
      params: {
        worker_id,
        appointment_day,
        ...(call_type && { call_type }),
        ...(call_duration && { call_duration }),
      }
    })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { error: data, status };
        }
        return {
          error: { message: 'An error has occurred with API processing' },
          status: 500
        };
      });
  },
  fetchActiveAppointments: async (): Promise<{
    data?: IAppointmentCallStarted[],
    error?: any,
    status: number
  }> => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);

    return await api.get<any>(`/${ temporary_token ? 'temporary' : 'users' }/incoming-calls`)
      .then(result => {
        const { data, status } = result;
        return { data: data.incoming_calls, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { error: data, status };
        }
        return {
          error: { message: 'An error has occurred with API processing' },
          status: 500
        };
      });
  },
  bookAppointment: async (appointment: ICreateAppointment): Promise<{
    appointment?: IAppointment,
    error?: any,
    status: number
  }> => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);

    return await api.post<any>(`/${ temporary_token ? 'temporary' : 'users' }/create-appointment`, appointment)
      .then(result => {
        const { data, status } = result;
        return { appointment: data.appointment, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { error: data, status };
        }
        return {
          error: { message: 'An error has occurred with API processing' },
          status: 500
        };
      });
  },
  fetchAppointmentsList: async (
    offset: number, limit: number, settings: IAppointmentsSettings,
  ): Promise<{
    appointments?: IAppointment[],
    total?: number,
    totalAll?: number,
    error?: string
  }> => {
    try {
      const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);

      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/appointments/list`, {
        params: {
          offset,
          limit,
          ...(settings.search && { search: settings.search }),
          ...(settings.search && { search: settings.search }),
          ...(settings.sort_field && {
            sort_field: settings.sort_field,
            sort_direction: settings.sort_direction || 'asc'
          }),
        }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  cancelAppointment: async (
    appointment_id: number, cancel_notes: string
  ): Promise<{ error?: string, appointment?: IAppointment, message?: string, }> => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken);

    try {
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/cancel-appointment`, {
        appointment_id,
        cancel_notes
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
}
