import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

import { Dropdown } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomTooltip,
  EIconName,
  Loader, tzDayjs,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { ViewElementByTime } from "../view-element-by-time/view-element-by-time";

// Styles
import './appointments-card.scss';

// Models
import { IAppointment } from "../../../../interfaces/appointments.interfaces";

interface IAppointmentsCardProps {
  appointment: IAppointment;
  loading?: boolean;
  cancelAppointment?: (appointment: IAppointment) => void;
  approveAppointment?: (appointment: IAppointment) => void;
  jointAppointment?: (call_id: number, call_type: string, worker_id: number) => void;
  navigateToCounsellor?: (worker: IAppointment["worker"]) => void;
}

export const AppointmentsCard: FC<IAppointmentsCardProps> = (
  {
    appointment,
    loading = false,
    cancelAppointment = () => {},
    approveAppointment = () => {},
    jointAppointment = () => {},
    navigateToCounsellor = () => {},
  }: IAppointmentsCardProps
): JSX.Element => {

  const duration = useMemo(() => {
    const format = appointment.max_duration >= 3600 ? 'H [h] m [min]' : 'm [min]';
    return dayjs.duration(appointment.max_duration, "seconds").format(format);
  }, [appointment]);

  const getAppointmentsStatus = (): {
    status: 'canceled' | 'awaiting' | 'approved' | 'past',
    text: string,
    canCancel?: boolean,
    canApprove?: boolean,
  } => {
    if (appointment.status === 'canceled' || appointment.status === 'rejected') {
      return { status: 'canceled', text: 'Canceled' };
    }

    if (appointment.status === 'finished') {
      return { status: 'past', text: 'Past' };
    }

    if (!appointment.approved_at && (dayjs().isBefore(dayjs(appointment.max_cancellation_at)) || dayjs().isBefore(dayjs(appointment.max_approval_at)))) {
      return {
        status: 'awaiting',
        text: 'Awaiting',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    if (!!appointment.approved_at && dayjs().isAfter(dayjs(appointment.started_at)) && dayjs().isBefore(dayjs(appointment.finished_at))) {
      return {
        status: 'approved',
        text: 'In progress',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    if (!!appointment.approved_at && dayjs().isBefore(appointment.finished_at)) {
      return {
        status: 'approved',
        text: 'Future',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    return { status: 'past', text: 'Past' };
  }

  const cancelAppointmentHandler = (): void => {
    cancelAppointment(appointment);
  }

  const jointAppointmentHandler = (): void => {
    jointAppointment(appointment.consultation_id, appointment.consultation_type, appointment.worker.id);
  }

  const appointmentPayload = useMemo(() => getAppointmentsStatus(), [appointment]);

  return (
    <div className={ `appointments-card ${ appointmentPayload.status }` }>
      <div className="appointments-card--header">
        <div className={ `header-status ${ appointmentPayload.status }` }>
          <div className={ `header-status--point ${ appointmentPayload.status } ` }/>
          <div className="header-status--name">
            { appointmentPayload.text }
          </div>
        </div>
        <div className="header-actions">
          { loading && <div className="header-actions--item loading">
            <Loader/>
          </div> }

          { (appointmentPayload.status === 'approved') &&
            <ViewElementByTime from={ appointment.started_at } to={ appointment.finished_at }>
              <div className="actions-wrapper--item">
                <CustomButton onClick={ jointAppointmentHandler }>Join</CustomButton>
              </div>
            </ViewElementByTime> }

          { (appointmentPayload.status === 'awaiting') &&
            <div className="header-actions--item">
              <Dropdown menu={ {
                items: [
                  {
                    key: '1',
                    label: (
                      <div className={ `cancel-appointment-item ${ !appointmentPayload.canCancel ? 'disabled' : '' }` }>
                        <CustomIcon name={ EIconName.Cancel } size={ 'size-md' }/>
                        Cancel Appointment
                      </div>),
                    disabled: !appointmentPayload.canCancel,
                    onClick: cancelAppointmentHandler
                  },
                ]
              } } trigger={ ['click'] } placement="bottomRight" arrow>
                <CustomIcon name={ EIconName.BurgerMenu }/>
              </Dropdown>
            </div> }
        </div>
      </div>

      <div className="appointments-card--body">
        <div className="body-row body-row--counsellor">
          <div className="body-row--item item-avatar" onClick={ () => navigateToCounsellor(appointment.worker) }>
            <UserAvatar sizeClass={ 'small' } avatarUrl={ appointment.worker?.photo || '' }/>
          </div>
          <div className="body-row--item item-name" onClick={ () => navigateToCounsellor(appointment.worker) }>
            <CustomTooltip title={ `${ appointment.worker.first_name } ${ appointment.worker.last_name }` }>
              { appointment.worker.first_name } { appointment.worker.last_name }
            </CustomTooltip>
          </div>
          { !appointment.additional_notes &&
            <CustomTooltip title={ appointment.additional_notes } className="body-row--item item-note">
              <CustomIcon name={ EIconName.Note }/>
            </CustomTooltip> }
        </div>
        <div className="body-row">
          <div className="body-row--item item-type">
            <div className="type-wrapper--icon">
              { appointment.consultation_type === 'video_call' &&
                <CustomIcon size={ 'size-md' } name={ EIconName.CameraOn }/> }
              { appointment.consultation_type === 'audio_call' &&
                <CustomIcon size={ 'size-md' } name={ EIconName.MicOn }/> }
              { appointment.consultation_type === 'conversation' &&
                <CustomIcon size={ 'size-md' } name={ EIconName.Chat }/> }
            </div>
            <div className="type-wrapper--name">
              { appointment.consultation_type === 'video_call' && 'Video Call' }
              { appointment.consultation_type === 'audio_call' && 'Audio Call' }
              { appointment.consultation_type === 'conversation' && 'Text Chat' }
            </div>
          </div>
          <div className="body-row--item item-today">
            { tzDayjs(appointment.started_at).format('DD MMM YYYY') }
          </div>
          <div className="body-row--item item-price">
            { appointment.price }
          </div>
        </div>
        <div className="body-row">
          <div className="body-row--item item-time">
            { tzDayjs(appointment.started_at).format('hh:mm A') } - { tzDayjs(appointment.finished_at).format('hh:mm A') }
          </div>
          <div className="body-row--item item-time">
            { duration }
          </div>
        </div>
      </div>
    </div>
  );
};
