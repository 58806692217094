import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';

import { ConfigProvider } from 'antd';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Shared UI Library Components
import {
  CAntdThemesConfig,
  PageLoader,
  setUpThemeColors,
  SpinElement,
  useNotifications
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './app.scss';

// Interceptor
import { useInterceptor } from '../hooks/useInterceptor';

// ServiceWorker
import { useServiceWorker } from '../hooks/useServiceWorker';

// Global WebSockets connection hook
import { useWebSockets } from '../hooks/useWebSockets';

// Global Route Change hook
import { useRouterHistory } from '../hooks/useRouterHistory';

// Firebase Notifications hook
import { useFirebaseNotifications } from '../hooks/useFirebaseNotification';

// Window height changes hook
import { useWindowHeight } from '../hooks/useWindowHeight';

// Date hook
import { useDate } from "../hooks/useDate";

// Modules
import { Messages } from './messages/messages';
import { Call } from './call/call';
import { ProfileSettings } from './profile-settings/profile-settings';
import { Auth } from './auth/auth';
import { SignIn } from './auth/sign-in/sign-in';
import { SignUp } from './auth/sign-up/sign-up';
import { SignUpConfirm } from './auth/sign-up-confirm/sign-up-confirm';
import { AccountVerification } from './auth/account-verification/account-verification';
import { Welcome } from './welcome/welcome';
import { History } from './history/history';
import { MainLayout } from '../modules/main-layout/main-layout';
import { Favorites } from './favorites/favorites';
import { Payments } from './profile-settings/payments/payments';
import { AuthGuard } from '../guards/auth.guard';
import { ForgotPassword } from './auth/forgot-password/forgot-password';
import { ResetPasswordResend } from './auth/reset-password-resend/reset-password-resend';
import { ResetPassword } from './auth/reset-password/reset-password';
import { Transactions } from './transactions/transactions';
import { PaymentType } from './call/payment-type/payment-type';
import { CallCheckout } from './call/call-checkout/call-checkout';
import { CallFinished } from './call/call-finished/call-finished';
import { NotificationsSettings } from './profile-settings/notifications-settings/notifications-settings';
import { Notifications } from './notifications/notifications';
import { StatusUnavailable } from './call/status-unavailable/status-unavailable';
import { StatusInCall } from './call/status-in-call/status-in-call';
import { StatusDeclined } from './call/status-declined/status-declined';
import { StatusNotAnswered } from './call/status-not-answered/status-not-answered';
import { CallOutgoing } from './call/call-outgoing/call-outgoing';
import { StatusNetworkDisconnected } from './call/status-network-disconnected/status-network-disconnected';
import { StatusUnavailableNoPayment } from './call/status-unavailable-no-payment/status-unavailable-no-payment';
import { RecoveryLinkExpired } from './auth/recovery-link-expired/recovery-link-expired';
import { StatusInCallNoPayment } from './call/status-in-call-no-payment/status-in-call-no-payment';
import { StatusCallMissed } from './call/status-call-missed/status-call-missed';
import { StayWithUs } from './call/stay-with-us/stay-with-us';
import { BusinessInformation } from './business-information/business-information';
import { PersonalData } from './profile-settings/personal-data/personal-data';
import { ChangePassword } from './profile-settings/change-password/change-password';
import { ChangeEmail } from './profile-settings/change-email/change-email';
import { PageNotFound } from './page-not-found/page-not-found';
import { TermsOfService } from './terms-of-service/terms-of-service';
import { PrivacyPolicy } from './privacy-policy/privacy-policy';
import { InactiveProfile } from './inactive-profile/inactive-profile';
import { CallLostConnection } from './call/call-lost-connection/call-lost-connection';
import { StatusRejoin } from './call/status-rejoin/status-rejoin';
import { RejoinCall } from './call/rejoin-call/rejoin-call';
import { CounsellorLayout } from '../modules/counsellor-layout/counsellor-layout';
import { ExpertRates } from '../shared/ui-components/expert/expert-rates/expert-rates';
import { ExpertAbout } from '../shared/ui-components/expert/expert-about/expert-about';
import { WidgetWrapper } from '../modules/widget-wrapper/widget-wrapper';
import { ChatConsultation } from './chat-consultation/chat-consultation';
import { ChatTypes } from './chat-consultation/chat-types/chat-types';
import { ChatCheckout } from './chat-consultation/chat-checkout/chat-checkout';
import { ChatOutgoing } from './chat-consultation/chat-outgoing/chat-outgoing';
import { ChatStatusUnavailable } from './chat-consultation/chat-status-unavailable/chat-status-unavailable';
import {
  ChatStatusUnavailableNoPayment
} from './chat-consultation/chat-status-unavailable-no-payment/chat-status-unavailable-no-payment';
import { ChatStatusInCall } from './chat-consultation/chat-status-in-call/chat-status-in-call';
import { ChatStatusCallMissed } from './chat-consultation/chat-status-call-missed/chat-status-call-missed';
import { ChatStatusDeclined } from './chat-consultation/chat-status-declined/chat-status-declined';
import {
  ChatStatusInCallNoPayment
} from './chat-consultation/chat-status-in-call-no-payment/chat-status-in-call-no-payment';
import { ChatStatusNotAnswered } from './chat-consultation/chat-status-not-answered/chat-status-not-answered';
import {
  ChatStatusNetworkDisconnected
} from './chat-consultation/chat-status-network-disconnected/chat-status-network-disconnected';
import { ChatStatusRejoin } from './chat-consultation/chat-status-rejoin/chat-status-rejoin';
import { ChatStayWithUs } from './chat-consultation/chat-stay-with-us/chat-stay-with-us';
import { ChatLostConnection } from './chat-consultation/chat-lost-connection/chat-lost-connection';
import { ChatInProgress } from './chat-consultation/chat-in-progress/chat-in-progress';
import { ChatFinished } from './chat-consultation/chat-finished/chat-finished';
import {
  StatusNetworkDisconnectedCounsellor
} from './call/status-network-disconnected-counsellor/status-network-disconnected-counsellor';
import { BookAppointment } from "../shared/ui-components/expert/book-appointment/book-appointment";
import { Appointments } from "./appointments/appointments";
import { ActiveAppointmentsQueue } from "../shared/ui-components/appointments";
import { LinksIn } from "../modules/links-in/links-in";

// Store entities
import {
  fetchCompanies,
  fetchCountries,
  fetchLanguages,
  fetchSpecialities,
  fetchUserCompany, updateCompanyBilling, updateCompanyStatus
} from '../store/core/core.thunks';
import { getUserProfile } from '../store/user/user.thunks';
import { fetchSocketToken } from '../store/socket/socket.thunks';
import { getStaticPages } from '../store/static-pages/static-pages.thunks';
import { notificationsUnreadCount } from '../store/notifications/notifications.thunks';
import { getLoaderState } from '../store/loader/loader.selectors';
import { getNotificationsData } from '../store/app-notifications/app-notifications.selectors';
import { getLastMessageData } from '../store/socket/socket.selectors';
import { fetchCurrentCurrency } from '../store/currency/currency.thunks';
import { fetchExpertsList } from '../store/experts/experts.thunks';
import { fetchOngoingCalls } from '../store/ongoing-consultation/ongoing-consultation.thunks';
import { clearCallStore, getCallInfo } from '../store/call/call.thunks';
import { setWidgetSettings } from '../store/widget/widget.thunks';
import { getCoreState } from '../store/core/core.selectors';
import { fetchTimezonesList } from "../store/date/date.thunks";
import { fetchActiveAppointments } from "../store/active-appointments/active-appointments.thunks";

// Services
import { requestForToken } from '../services/firebase.service';

// Models
import { CStripeOptions } from '../constantes/global.constants';
import { EWebSocketTypes } from '../enums/web-socket-types.enums';
import { TWidgetType } from '../types/widget.types';

// Utils
import { isWidgetAppType } from '../utils/widget.utils';

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);

const CallInProgress = loadable(() => import('./call/call-in-progress/call-in-progress'), {
  resolveComponent: (components) => components.CallInProgress,
});
const DevicesSettings = loadable(() => import('./call/devices-settings/devices-settings'), {
  resolveComponent: (components) => components.DevicesSettings,
});

export const App = () => {
  // set up http interceptor
  useInterceptor();
  // set up service worker
  useServiceWorker();
  // set up web sockets
  useWebSockets();
  // set up router history handler
  useRouterHistory();
  // set up window resize handler
  useWindowHeight();
  // set up firebase
  useFirebaseNotifications();
  // set up date
  useDate();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const { show, text } = useSelector(getLoaderState);
  const notificationsData = useSelector(getNotificationsData);
  const { companyInfo } = useSelector(getCoreState);

  const [indexPath, setIndexPath] = useState<string>('');
  const [rejoinCall, setRejoinCall] = useState<boolean>(false);
  const [doneThemeStyles, setDoneThemeStyles] = useState<boolean>(false);

  const lastMessage = useSelector(getLastMessageData);

  const token = localStorage.getItem('token');
  const temporaryToken = localStorage.getItem('temporary_token');

  const isWidget: boolean = isWidgetAppType();
  const AppWrapper = isWidget ? WidgetWrapper : React.Fragment;

  const redirectTo = (path: string): void => {
    if (path.includes('notifications') && temporaryToken) {
      return;
    }
    navigate(path);
  }

  // set up notifications
  const { contextHolder } = useNotifications({ notificationsState: notificationsData, redirectTo });

  const redirectAfterBlock = ['expert', 'history', 'favorites', 'business-information'];

  useEffect(() => {
    dispatch(getStaticPages());
    const companyName = companyInfo?.company?.company_profile?.url_code;

    setIndexPath(token ? '/home' : `/${ companyName ? companyName : 'sign-in' }`);
    if (token) {
      localStorage.removeItem('temporary_token');
      // Fetch static information
      // TODO rejoin-flow comments
      dispatch(fetchOngoingCalls());
      dispatch(fetchActiveAppointments());
      dispatch(notificationsUnreadCount());
      // TODO after api adding
      dispatch(fetchTimezonesList());
      dispatch(fetchLanguages({ company_code: null }));
      dispatch(fetchCountries({ company_code: null }));
      dispatch(fetchCurrentCurrency());
      dispatch(getUserProfile(false));
      dispatch(fetchSpecialities({ company_code: null }));
      dispatch(fetchCompanies());
      dispatch(fetchUserCompany());
      dispatch(fetchSocketToken());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (temporaryToken) {
      // Fetch static information
      // TODO rejoin-flow comments
      dispatch(fetchTimezonesList());
      dispatch(fetchOngoingCalls());
      dispatch(fetchActiveAppointments());
      dispatch(fetchSocketToken());
      dispatch(fetchCurrentCurrency());
      dispatch(getUserProfile(true));
    }
  }, [temporaryToken, dispatch]);

  useEffect(() => {
    if (token) {
      if (lastMessage?.type === EWebSocketTypes.CompanyBlocked || lastMessage?.type === EWebSocketTypes.CompanyUnblocked) {
        dispatch(updateCompanyStatus(lastMessage.data))
          .then(() => dispatch(fetchExpertsList({ filters: { offset: 0, limit: 20 }, type: 'all', isLoadMore: false })))
          .then(() => {
            if (redirectAfterBlock.some(v => window.location.href.includes(v))) navigate('home');
          });
      }
      if (lastMessage?.type === EWebSocketTypes.CompanyBillingUpdated) {
        dispatch(updateCompanyBilling(lastMessage.data))
      }
    } else if (lastMessage?.type === EWebSocketTypes.CompanyBlocked) {
      navigate('inactive-profile');
    }

    // TODO rejoin-flow comments
    if (lastMessage?.type === EWebSocketTypes.RejoinPopup && !['call'].some(v => window.location.href.includes(v))) {
      rejoinCallHandler(lastMessage.data)
    }

    if (lastMessage?.type === EWebSocketTypes.EndCall) {
      closeRejoinCall();
    }
  }, [lastMessage, token]);

  useEffect(() => {
    requestForToken();

    document.documentElement.style.setProperty("--outgoingHeight", '0px');

    if (isWidget) {
      const widgetURL = new URL(window.location.href);
      const searchParams = widgetURL.searchParams;
      const widgetType = searchParams.get('type') || localStorage.getItem('widgetType');
      const companyHash = searchParams.get('hash') || localStorage.getItem('companyHash');

      dispatch(setWidgetSettings({
        type: widgetType as TWidgetType || 'popup',
        companyHash: companyHash as string
      }));
      localStorage.setItem('widgetType', widgetType || 'popup');
      localStorage.setItem('companyHash', companyHash || '');
    }

    // Listen to service worker messages sent via postMessage()
    navigator?.serviceWorker?.addEventListener('message', (event) => {
      if (!event.data.action) {
        return
      }

      switch (event.data.action) {
        case 'notificationclick-messages':
          navigate('/notifications');
          break;
        case 'notificationclick-appointment':
          dispatch(fetchOngoingCalls());
          navigate('/appointments');
          break;
        // no default
      }
    })
  }, []);

  useLayoutEffect(() => {
    const themeConfig = localStorage.getItem('themeConfig')
    if (isWidget && themeConfig) {
      setDoneThemeStyles(false);
      setUpThemeColors(JSON.parse(themeConfig));

      const timerId = setTimeout(() => {
        setDoneThemeStyles(true);
        clearTimeout(timerId);
      }, 1500);
    } else if (isWidget) {
      setDoneThemeStyles(true);
    }
  }, []);

  const rejoinCallHandler = (data: any, pathname?: string): void => {
    dispatch(clearCallStore(true))
      .then(() => {
        localStorage.setItem('call_type', data.call_type);
        localStorage.setItem('call_id', data.call_id);
        localStorage.setItem('return_pathname', pathname ? pathname : location.pathname);
      })
      .then(() => dispatch(getCallInfo({ call_id: data.call_id, temporary_token: temporaryToken ?? '' })))
      .then(() => {
        setRejoinCall(true);
      })
  }

  const closeRejoinCall = () => {
    setRejoinCall(false);
  }

  if (isWidget && !doneThemeStyles) {
    return (<div className="app-loading-spinner widget-state-type">
      <SpinElement fullHeight={ true }/>
    </div>);
  }

  if (!indexPath) {
    return (<div className="app-loading-spinner">
      <SpinElement fullHeight={ true }/>
    </div>);
  }

  return (
    <ConfigProvider theme={ CAntdThemesConfig }>
      <PageLoader show={ show } text={ text }/>

      { contextHolder }

      <ActiveAppointmentsQueue/>

      { rejoinCall && <RejoinCall onClose={ closeRejoinCall }/> }
      <Elements stripe={ stripePromise } options={ CStripeOptions }>

        <AppWrapper>

          <Routes>
            <Route path="/" element={ <Navigate to="/sign-in" replace/> }/>
            <Route path=""
                   element={ <AuthGuard isProtectedRouter={ false } hasToken={ !!token } children={ <Auth/> }/> }>
              <Route path="sign-in" element={ <SignIn/> }/>
              <Route path="sign-up" element={ <SignUp/> }/>
              <Route path="sign-up-confirm" element={ <SignUpConfirm/> }/>
              <Route path="account-verification" element={ <AccountVerification/> }/>
              <Route path="forgot-password" element={ <ForgotPassword/> }/>
              <Route path="forgot-password-resend" element={ <ResetPasswordResend/> }/>
              <Route path="reset-password" element={ <ResetPassword/> }/>
              <Route path="recovery-link-expired" element={ <RecoveryLinkExpired/> }/>
            </Route>

            <Route path="inactive-profile" element={ <InactiveProfile/> }/>

            <Route path="" element={ <AuthGuard hasToken={ !!token } children={ <MainLayout/> }/> }>
              <Route path="home" element={ <Welcome/> }/>
              <Route path="favorites" element={ <Favorites/> }/>
              <Route path="history" element={ <History/> }/>
              <Route path="expert" element={ <CounsellorLayout/> }>
                <Route path=":id" element={ <ExpertAbout/> }/>
                <Route path=":id/rates" element={ <ExpertRates/> }/>
                <Route path=":id/book-appointment" element={ <BookAppointment/> }/>
                <Route path=":id/messages" element={ <Messages/> }/>
                <Route path=":id/history" element={ <History expertPage={ true }/> }/>
                <Route path=":id/transactions" element={ <Transactions expertPage={ true }/> }/>
              </Route>
              <Route path="profile-settings" element={ <ProfileSettings/> }>
                <Route index element={ <PersonalData/> }/>
                <Route path="change-password" element={ <ChangePassword/> }/>
                <Route path="change-email" element={ <ChangeEmail/> }/>
                <Route path="notification-settings" element={ <NotificationsSettings/> }/>
                <Route path="payment-methods" element={ <Payments/> }/>
              </Route>
              <Route path="transactions" element={ <Transactions/> }/>
              <Route path="notifications" element={ <Notifications/> }/>
              <Route path="appointments" element={ <Appointments/> }/>
              <Route path="business-information" element={ <BusinessInformation/> }/>
              <Route path="notifications-settings" element={ <NotificationsSettings/> }/>
              <Route path="terms-of-service" element={ <TermsOfService/> }/>
              <Route path="privacy-policy" element={ <PrivacyPolicy/> }/>
            </Route>

            <Route path="call" element={ <AuthGuard hasToken={ !!token || !!temporaryToken } children={ <Call/> }/> }>
              <Route path="devices-settings" element={ <DevicesSettings/> }/>
              <Route path="devices-settings/:id" element={ <DevicesSettings/> }/>
              <Route path="payments-type" element={ <PaymentType/> }/>
              <Route path="checkout" element={ <CallCheckout/> }/>
              <Route path="outgoing" element={ <CallOutgoing/> }/>
              <Route path="counsellor-unavailable" element={ <StatusUnavailable/> }/>
              <Route path="counsellor-unavailable-no-payment" element={ <StatusUnavailableNoPayment/> }/>
              <Route path="counsellor-in-call" element={ <StatusInCall/> }/>
              <Route path="counsellor-in-call-no-payment" element={ <StatusInCallNoPayment/> }/>
              <Route path="call-declined" element={ <StatusDeclined/> }/>
              <Route path="call-missed" element={ <StatusCallMissed/> }/>
              <Route path="not-answered" element={ <StatusNotAnswered/> }/>
              <Route path="network-disconnected" element={ <StatusNetworkDisconnected/> }/>
              <Route path="network-disconnected-counsellor" element={ <StatusNetworkDisconnectedCounsellor/> }/>
              <Route path="rejoin" element={ <StatusRejoin/> }/>
              <Route path="stay-with-us" element={ <StayWithUs/> }/>
              <Route path="lost-connection" element={ <CallLostConnection/> }/>
              <Route path=":id" element={ <CallInProgress/> }/>
            </Route>

            <Route path="call-finished/:id"
                   element={ <AuthGuard hasToken={ !!token || !!temporaryToken } children={ <Call/> }/> }>
              <Route index element={ <CallFinished/> }/>
            </Route>

            <Route path="conversation"
                   element={ <AuthGuard hasToken={ !!token || !!temporaryToken } children={ <ChatConsultation/> }/> }>
              <Route path="chat-types" element={ <ChatTypes/> }/>
              <Route path="checkout" element={ <ChatCheckout/> }/>
              <Route path="outgoing" element={ <ChatOutgoing/> }/>
              <Route path="counsellor-unavailable" element={ <ChatStatusUnavailable/> }/>
              <Route path="counsellor-unavailable-no-payment" element={ <ChatStatusUnavailableNoPayment/> }/>
              <Route path="counsellor-in-call" element={ <ChatStatusInCall/> }/>
              <Route path="counsellor-in-call-no-payment" element={ <ChatStatusInCallNoPayment/> }/>
              <Route path="call-declined" element={ <ChatStatusDeclined/> }/>
              <Route path="call-missed" element={ <ChatStatusCallMissed/> }/>
              <Route path="not-answered" element={ <ChatStatusNotAnswered/> }/>
              <Route path="network-disconnected" element={ <ChatStatusNetworkDisconnected/> }/>
              <Route path="rejoin" element={ <ChatStatusRejoin/> }/>
              <Route path="stay-with-us" element={ <ChatStayWithUs/> }/>
              <Route path="lost-connection" element={ <ChatLostConnection/> }/>
              <Route path=":id" element={ <ChatInProgress/> }/>
            </Route>

            <Route path="conversation-finished/:id"
                   element={ <AuthGuard hasToken={ !!token || !!temporaryToken } children={ <ChatConsultation/> }/> }>
              <Route index element={ <ChatFinished/> }/>
            </Route>

            <Route path=":companyCode"
                   element={ <AuthGuard isProtectedRouter={ false } hasToken={ false }
                                        children={ <MainLayout hiddenBeforeLoad={ true }/> }/> }>
              <Route path="" element={ <Welcome/> }/>
              <Route path="home" element={ <Welcome/> }/>
              <Route path="business-information" element={ <BusinessInformation/> }/>
              <Route path=":personalLink" element={ <CounsellorLayout/> }>
                <Route path="" element={ <ExpertAbout/> }/>
                <Route path="rates" element={ <ExpertRates/> }/>
                <Route path="book-appointment" element={ <BookAppointment/> }/>
                <Route path="messages" element={ <Messages/> }/>
                <Route path="history" element={ <History expertPage={ true }/> }/>
                <Route path="transactions" element={ <Transactions expertPage={ true }/> }/>
              </Route>
              <Route path=":personalLink/book-appointment" element={ <BookAppointment/> }/>
              <Route path=":personalLink/messages" element={ <Messages/> }/>
              <Route path=":personalLink/history" element={ <History expertPage={ true }/> }/>
              <Route path=":personalLink/transactions" element={ <Transactions expertPage={ true }/> }/>
            </Route>

            <Route path=":companyCode/:personalLink/in/:serviceType" element={ <LinksIn /> }/>

            <Route path="404" element={ <PageNotFound/> }/>
            <Route path="*" element={ <PageNotFound/> }/>
          </Routes>

        </AppWrapper>

      </Elements>
    </ConfigProvider>
  );
}
