import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './payments.scss';

// Components
import { AddPayment, PaymentMethods } from '../../../shared/payment-components';
import { AddPaymentPaypal } from "../../../shared/paypal/add-payment-paypal/add-payment-paypal";

// Store entities
import { fetchPaymentsCards } from '../../../store/payments/payments.thunks';
import { getUserState } from "../../../store/user/user.selectors";

type TPaymentsProps = {};

export const Payments: FC<TPaymentsProps> = ({}: TPaymentsProps) => {
  const { user } = useSelector(getUserState);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    loadPaymentsData();
  }, []);

  const loadPaymentsData = (): void => {
    dispatch(fetchPaymentsCards());
  }

  return (<div className="payments">
    <div className={ 'content-title' }>Payment Methods</div>
    <div className={ 'content-title-line' }></div>
    <div className={ 'content-inside' }>
      <PaymentMethods/>
      { user?.payment_provider === 'stripe' && <AddPayment/> }
      { user?.payment_provider === 'paypal' && <AddPaymentPaypal/> }
    </div>
  </div>);
}
