import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import { VerificationCode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Thunks
import { activateCode, clearAuthState, resendCode } from '../../../store/auth/auth.thunks';
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';

// Styles
import './account-verification.scss';

// Models
import { EVerificationCodeTypes } from '../../profile-settings/profile-settings.enums';

export const AccountVerification = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [errorWithTimerTime, setErrorWithTimerTime] = useState<string>('');
  const [errorWithTimerText, setErrorWithTimerText] = useState<string | null>(null);
  const [errorAfter, setErrorAfter] = useState<string | null>(null);
  const [sendAgainTime, setSendAgainTime] = useState('');

  useEffect(() => {
    localStorage.removeItem('signUp');
    const verificationAccessTime = localStorage.getItem('verificationAccessTime');
    const verificationAccessMessage = localStorage.getItem('verificationAccessMessage');
    if (verificationAccessTime && verificationAccessMessage) {
      setErrorWithTimerTime(verificationAccessTime);
      setErrorWithTimerText(verificationAccessMessage);
    }
  }, []);

  useEffect(() => {
    const resendAccess = localStorage.getItem('authVerificationResendAccess');
    if (resendAccess) {
      setSendAgainTime(resendAccess);
    }
  }, []);

  const onSendAgain = () => {
    const temporaryToken = localStorage.getItem('temporaryToken');
    if (temporaryToken) {
      dispatch(resendCode({temporary_token: temporaryToken, type: EVerificationCodeTypes.Authenticate}))
        .then((res: { payload: any }) => {
          if (res.payload && res.payload?.error) {
            localStorage.setItem('authVerificationResendAccess', res.payload.access_after)
            setSendAgainTime(res.payload.access_after);
            dispatch(openNotification({
              description: res.payload.error,
              type: 'error'
            }));
            return;
          }

          localStorage.setItem('authVerificationResendAccess', res.payload.resend_access)
          setSendAgainTime(res.payload.resend_access);
          dispatch(openNotification({
            description: res.payload.message,
            type: 'success'
          }));
        });
    }
  }

  const onErrorTimerEnd = () => {
    localStorage.removeItem('verificationAccessTime');
    localStorage.removeItem('verificationAccessMessage');
  }

  const sendAgainTimerEnd = () => {
    // localStorage.removeItem('authVerificationResendAccess');
  }

  const onCancel = () => {
    dispatch(clearAuthState());
    // localStorage.clear();
    localStorage.smartClear();
    navigate('/sign-in');
  }

  const onFinish = (code: string) => {
    const temporaryToken = localStorage.getItem('temporaryToken');
    if (temporaryToken) {
      dispatch(activateCode({
        temporary_token: temporaryToken,
        code,
        actionType: EVerificationCodeTypes.Authenticate,
        device_UDID: localStorage.getItem('device_UDID')
      }))
        .then((res: { payload: any }) => {
          if (res.payload.error && res.payload.access_after) {
            setErrorWithTimerTime(res.payload.access_after);
            setErrorWithTimerText(res.payload.error);
            localStorage.setItem('verificationAccessTime', res.payload.access_after);
            localStorage.setItem('verificationAccessMessage', res.payload.error);
            return;
          }

          if (res.payload && res.payload?.error) {
            setErrorAfter(res.payload.error);
            return;
          }

          localStorage.removeItem('verificationAccessTime');
          localStorage.removeItem('verificationAccessMessage');
          localStorage.removeItem('temporaryToken');
          localStorage.removeItem('authVerificationResendAccess');
          localStorage.removeItem('tokenError');
          localStorage.removeItem('temporaryData');
          navigate('/home');
      });
    }
  };

  return (<AuthLayout cardTitle="Enter Verification Code" rightBarIcon="auth">
    <VerificationCode
      discardModalText={'Are you sure you want to discard your Sign In?'}
      errorWithTimerTime={errorWithTimerTime}
      errorWithTimerText={errorWithTimerText}
      errorWithTimerEndAction={onErrorTimerEnd}
      errorAfterField={errorAfter}
      sendAgainTime={sendAgainTime}
      sendAgainTimerEnd={sendAgainTimerEnd}
      resetErrorAfterField={() => setErrorAfter(null)}
      sendAgainAction={onSendAgain}
      finishStepAction={onFinish}
      cancelStepAction={onCancel}/>
  </AuthLayout>);
}
