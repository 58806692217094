import React from 'react';

// Styles
import './tu-power-by.scss';

export const TuPowerBy = () => {

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  return (
    <div className={ 'tu-power-by' }>
      <div className="tu-power-by--inside" onClick={ navigateToLanding }>
        <div className={ 'text' }>powered by</div>
        <img src={ '/assets/images/logo.svg' } alt={ 'paypertok-logo' }/>
      </div>
    </div>)
};
