import React from 'react';
import { useSelector } from "react-redux";

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-in-call.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const StatusInCall = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;

  return (<CallNotifications
    title={ `${ businessTerms.Consultant } Unavailable` }
    text={ `Unfortunately, the ${ businessTerms.Consultant } has already started another call. You may try to contact them later. The payment for the ${ businessTerms.Consultation } will be refunded to you.` }
    navigateAction={ returnPathname }/>);
}
