import React, { FC, ReactNode } from 'react';

// Shared UI Library Components
import { TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './form-provider.scss';

// Components
// import { PaypalButtons } from "../paypal-buttons/paypal-buttons";
import { PaymentFormPaypal } from "../payment-form-paypal/payment-form-paypal";

// Models
import { TRateType } from '../../../interfaces/call.interfaces';
import { TCallType } from '../../../interfaces/call.interfaces';
import { ICurrency } from '../../../interfaces/company.interfaces';

// Configs

export interface IPaymentFormPaypalProps {
  consultation_type: TCallType;
  worker_id: string | number,
  currency: ICurrency,
  mode?: TElementMode;
  rate?: number,
  rate_type?: TRateType,
  call_duration?: number | string,
  btn_text?: string,
  cancelBtn?: ReactNode,
  onPaid?: (pi: string) => void;
}

export const FormProvider: FC<IPaymentFormPaypalProps> = (
  {
    consultation_type,
    worker_id,
    currency,
    rate_type,
    rate = 0,
    mode = 'white',
    call_duration = '',
    btn_text = '',
    cancelBtn = '',
    onPaid = () => {}
  }: IPaymentFormPaypalProps
) => {

  return (
    <div className="form-provider">
      {/*<div className="form-provider--item">*/}
      {/*  <PaypalButtons mode={ mode }*/}
      {/*                 call_duration={ call_duration }*/}
      {/*                 consultation_type={ consultation_type }*/}
      {/*                 rate_type={ rate_type }*/}
      {/*                 worker_id={ worker_id }*/}
      {/*                 onPaid={ onPaid }*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="form-provider--item">
        <PaymentFormPaypal consultation_type={ consultation_type }
                           rate={ rate }
                           rate_type={ rate_type }
                           call_duration={ call_duration }
                           currency={ currency }
                           btn_text={ btn_text }
                           worker_id={ worker_id }
                           onPaid={ onPaid }
                           mode={ mode }
                           cancelBtn={ cancelBtn }
        />
      </div>

    </div>
  );
};
