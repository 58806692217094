import React, { FC } from 'react';
import { useSelector } from "react-redux";

// Styles
import './history-base.scss';

// Store entities
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";

interface IHistoryBaseProps {
  isExistHistory: boolean;
}

export const HistoryBase: FC<IHistoryBaseProps> = (
  { isExistHistory }: IHistoryBaseProps
) => {
  const { businessTerms, illustrations } = useSelector(getGlobalConfigState);

  return (
    <div className="history-base">
      <div className="history-base--header">
        <div className="header-title">
          History
        </div>
        <div className="header-subtitle">
          { isExistHistory ? `Select an ${ businessTerms.Consultant } to see the history with them` : 'You don’t have history yet. Start communicating!' }
        </div>
      </div>
      <div className="history-base--img">
        <img src={ illustrations?.history } alt="history-icon"/>
      </div>
    </div>
  );
};
