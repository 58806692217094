import { useSelector } from 'react-redux';

// Styles
import './favorites.scss';

// Components
import { ExpertList } from '../../shared/ui-components/expert';

// Store entities
import { getExpertsState } from '../../store/experts/experts.selectors';
import { getGlobalConfigState } from "../../store/global-config/global-config.selectors";

export const Favorites = () => {
  const { experts: { total, total_all, isLoad } } = useSelector(getExpertsState);
  const { businessTerms, illustrations } = useSelector(getGlobalConfigState);

  return (
    <div className="favorites">
      <div className="favorites--desktop">
        <div className="desktop--header">
          <div className="header-title">
            Favorites
          </div>
          <div className="header-subtitle">
            { total !== 0 ? `Select a ${ businessTerms.Consultant } to see their profile and communicate!` : `Save your favorite ${ businessTerms.consultants } here!` }
          </div>
        </div>
        <div className="desktop--img">
          <img src={ illustrations?.favourites } alt="favorites-icon"/>
        </div>
      </div>

      <div className="favorites--mobile">
        <div className="mobile-body">
          { !!total_all && <ExpertList hidden={ false } type={ 'favorites' }/> }
          { (!total_all && !isLoad) && <>
            <div className="empty-list">
              <div className="empty-list--title">
                Save your favorite { businessTerms.consultants } here!
              </div>
              <div className="empty-list--img">
                <img src={ illustrations?.favourites } alt="favorites-icon"/>
              </div>
            </div>
          </> }
        </div>
      </div>
    </div>
  );
}
