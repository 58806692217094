import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

// Styles
import './tu-log-in.scss';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomInput,
  defaultValidateMessages,
  EIconName,
  emailPattern, InputPassword, passwordPattern, ServerErrors, ServerErrorsTimer,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from "../../../../pages/auth/auth-modules/auth-layout/auth-layout";
import { TuPowerBy } from "../modules/tu-power-by/tu-power-by";

// Thunks
import { getAuthState } from "../../../../store/auth/auth.selectors";
import { signInUser } from "../../../../store/auth/auth.thunks";
import { saveUserProfile } from "../../../../store/user/user.thunks";

// Models
import { ELocalStorageKeys } from "../../../../enums/storage.enums";

type TFormValue = {
  email: string;
  password: string;
};

type TTuLogInProps = {
  onChangeViewType?: () => void;
  onSubmit?: () => void;
}

export const TuLogIn: FC<TTuLogInProps> = (
  {
    onChangeViewType = () => {},
    onSubmit = () => {},
  }: TTuLogInProps
) => {
  const [form] = Form.useForm<TFormValue>();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  dayjs.extend(durationPlugin);
  dayjs.extend(utc);

  const currentData = dayjs().utc();

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);

  const [formDisabled, setFormDisabled] = useState(false);
  const [errorTimerTime, setErrorTimerTime] = useState<string>('');
  const [errorTimerText, setErrorTimerText] = useState<string | null>(null);

  const { loading } = useSelector(getAuthState);

  useEffect(() => {
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');
    localStorage.removeItem('beforeErrorTime');
    localStorage.removeItem('beforeErrorMessage');

    const signInAccessTime = localStorage.getItem('signInAccessTime');
    const signInAccessMessage = localStorage.getItem('signInAccessMessage');

    if (!signInAccessTime || !signInAccessMessage) return;

    const startedData = dayjs(signInAccessTime).utc();
    const totalSeconds = startedData.diff(currentData, "seconds");

    if (totalSeconds > 0) {
      form.resetFields();
      setBtnDisabled(true);
      setFormDisabled(true);
      setErrorTimerTime(signInAccessTime);
      setErrorTimerText(signInAccessMessage);
      return;
    }

    onErrorTimerEnd();
  }, []);

  const onFinish = async (): Promise<void> => {
    if (btnDisabled) return;
    const { email, password } = form.getFieldsValue();

    const temporaryToken = localStorage.getItem('temporary_token');
    const userData = {
      email,
      password,
      temporary_token: temporaryToken ?? '',
      device_UDID: localStorage.getItem('device_UDID')
    };

    const { payload } = await dispatch(signInUser(userData));

    if (payload?.error && payload?.access_after) {
      setErrorTimerTime(payload.access_after);
      setErrorTimerText(payload?.error);
      localStorage.setItem('signInAccessTime', payload.access_after);
      localStorage.setItem('signInAccessMessage', payload?.error);
      form.resetFields();
      setBtnDisabled(true);
      setFormDisabled(true);
      setServerError(null);
      return;
    }

    if (payload && payload?.error) {
      setBtnDisabled(true);
      setServerError(payload?.error);
      return;
    }

    localStorage.removeItem('signInAccessTime');
    localStorage.removeItem('signInAccessMessage');

    if (payload && payload?.redirect_to_verification) {
      localStorage.setItem('temporaryToken', payload.temporary_token);
      localStorage.setItem('authVerificationResendAccess', payload.resend_access);

      // TODO change if return verification
      navigate('/account-verification');
    } else if (payload && !payload?.redirect_to_verification) {
      localStorage.setItem(ELocalStorageKeys.Token, payload.token);
      dispatch(saveUserProfile(payload.user));

      // navigate('/home');
      onSubmit();
    }
  };

  const onErrorTimerEnd = (): void => {
    localStorage.removeItem('signInAccessTime');
    localStorage.removeItem('signInAccessMessage');

    setBtnDisabled(false);
    setFormDisabled(false);
    setServerError(null);
    setErrorTimerTime('');
    setErrorTimerText(null);
  };

  const onValuesChange = (
    _: { [key in keyof TFormValue]: string },
    { email, password }: TFormValue): void => {
    const isValid = password !== undefined
    && email !== undefined
    && password !== ''
    && email !== ''
    && new RegExp(passwordPattern).test(password)
    && new RegExp(emailPattern).test(email);

    setBtnDisabled(!isValid);
  };

  return (
    <div className="links-in-tu-log-in">
      <AuthLayout cardTitle="Log In" rightBarIcon="">
        <Form
          form={ form }
          disabled={ formDisabled }
          name="sign-in"
          onFinish={ onFinish }
          autoComplete="off"
          onValuesChange={ onValuesChange }
          validateMessages={ defaultValidateMessages }>

          { errorTimerText && errorTimerTime && (
            <ServerErrorsTimer
              serverError={ errorTimerText }
              errorTime={ errorTimerTime }
              onTimeEnd={ onErrorTimerEnd }
            />
          ) }

          { serverError && <ServerErrors serverError={ serverError }/> }

          <Form.Item
            name="email"
            messageVariables={ { fieldLabel: 'Email' } }
            rules={ [
              { required: true },
              { max: 60 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                    return Promise.resolve();
                  }
                  if (value.length > 60) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
                }
              })] }>
            <CustomInput disabled={ formDisabled } shouldTrim={ true } type={ 'text' } placeholder={ 'Email*' }
                         suffix={ <CustomIcon name={ EIconName.Mail }/> }/>
          </Form.Item>

          <Form.Item
            name="password"
            messageVariables={ { fieldLabel: 'Password' } }
            rules={ [{ required: true }] }>
            <InputPassword disabled={ formDisabled } placeholder={ 'Password*' }/>
          </Form.Item>

          <div className="form-bottom form-bottom-block">
            <div className={ 'actions' }>
              <CustomButton text="Go Back" type={ 'text' } onClick={ onChangeViewType }/>
              <CustomButton htmlType={ 'submit' } disabled={ loading || btnDisabled } text="Log In"/>
            </div>
          </div>
        </Form>

        <TuPowerBy/>
      </AuthLayout>
    </div>)
};
