import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StripeError,
  StripePaymentElement,
  StripePaymentElementChangeEvent
} from '@stripe/stripe-js';

import { Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  DropdownElement,
  EIconColor,
  InfoMessage,
  RadioGroup,
  SpinElement,
  TElementMode
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './payment-form.scss';

// Services
import { PaymentsService } from "../../../services/payments.service";

// Store actions
import { createPaymentCard, fetchPaymentsCards, payExistingPaymentCard } from '../../../store/payments/payments.thunks';
import { getPaymentsData } from '../../../store/payments/payments.selectors';

// Components
import { WalletButton } from '../wallet-button/wallet-button';

// Models
import { TRateType } from '../../../interfaces/call.interfaces';
import { TCallType } from '../../../interfaces/call.interfaces';
import { TPaymentElementState } from '../../../types/payments.types';
import { ICurrency } from '../../../interfaces/company.interfaces';

// Config
import { CPaymentElementOptions } from '../../../constantes/payment-form.constantes';

const { Item: FormControl } = Form;

export interface IPaymentFormProps {
  consultation_type: TCallType;
  worker_id: string | number,
  currency: ICurrency,
  mode?: TElementMode;
  rate?: number,
  rate_type?: TRateType,
  call_duration?: number | string,
  btn_text?: string,
  cancelBtn?: ReactNode,
  onPaid?: (pi: string) => void;
}

export const PaymentForm: FC<IPaymentFormProps> = (
  {
    consultation_type,
    worker_id,
    currency,
    rate_type,
    rate = 0,
    mode = 'white',
    call_duration = '',
    btn_text = '',
    cancelBtn = '',
    onPaid = () => {}
  }: IPaymentFormProps
) => {
  const [form] = Form.useForm<{
    card?: string,
    cardType: string,
  }>();
  const stripe = useStripe();
  const elements = useElements();
  const token = localStorage.getItem('token');

  const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(true);
  const [isPaymentProcess, setIsPaymentProcess] = useState<boolean>(false);
  const [hiddenPaymentElement, setHiddenPaymentElement] = useState<boolean>(true);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [elementStatus, setElementStatus] = useState<TPaymentElementState>('mounting');

  const { cards, defaultCard, isLoad } = useSelector(getPaymentsData);

  const btnText = btn_text ? btn_text : `Pay ${ currency.symbol }${ rate } and Send Request`;

  const cardOptions = cards?.map(({ id, brand, last4 }) => ({
    value: id,
    label: `${ brand } **** ${ last4 }`
  }));

  const dispatch = useDispatch<any>();

  /**
   * Shows a payment status notification and performs actions based on the status.
   * @param {'error' | 'success'} status - The payment status.
   * @param {string} message - The message to display in the notification.
   * @param {string | undefined} pi - The payment intent ID.
   * @returns {void}
   */
  const showNotificationEndOfPayment = (status: 'error' | 'success', message: string, pi?: string): void => {
    setIsPaymentProcess(false);

    if (status === 'error' || !pi) {
      setPaymentError(message);
      return;
    }

    // dispatch(openNotification({ description: message, type: 'success' }));
    onPaid(pi);
  }

  /**
   * Handles the 'ready' event when the Stripe payment element is ready.
   * @returns {void}
   * @param _
   */
  const onReadyHandler = (_: StripePaymentElement): void => {
    setElementStatus('success');

    elements?.update({
      setup_future_usage: token ? 'off_session' : null
    });
  }

  /**
   * Handles the 'change' event when the Stripe payment element is ready.
   * @param {StripePaymentElementChangeEvent} event - The Stripe payment element.
   * @returns {void}
   */
  const onChangeHandler = (event: StripePaymentElementChangeEvent): void => {
    setIsCardComplete(event.complete);
    if (event.complete) {
      setPaymentError(null);
    }
  }

  /**
   * Handles errors that occur when mounting the Stripe payment element.
   * @param {{ elementType: "payment"; error: StripeError; }} event - The error event.
   * @returns {void}
   */
  const onMountedErrorHandler = (event: { elementType: "payment"; error: StripeError; }): void => {
    setElementStatus('error');
    setPaymentError(event?.error?.message ?? '');
  }

  /**
   * Fetches the client secret for the payment based on certain parameters.
   * @returns {Promise<{ clientSecret?: string, error?: string }>}
   */
  const getClientSecret = async (): Promise<{ clientSecret?: string, error?: string }> => {
    const { data, error } = await PaymentsService.getClientSecret({
      consultation_type,
      rate_type,
      call_duration,
      worker_id,
      is_remember_me: true,
      only_setup_intent: true,
      temporary_token: localStorage.getItem('temporary_token') ?? ''
    });
    if (!data) {
      return { error };
    }
    return { clientSecret: data?.client_secret_intent };
  }

  /**
   * Handles payment using an existing payment card.
   * @returns {Promise<void>}
   */
  const payExistsCard = async (pm?: string): Promise<void> => {
    // Get the selected card from the form field.
    const payment_method = pm ? pm : await form.getFieldValue('card');

    // Dispatch an action to initiate payment using an existing payment card.
    const { payload: { error, data } } = await dispatch(payExistingPaymentCard({
      consultation_type,
      rate_type,
      call_duration,
      worker_id,
      payment_method
    }));

    // If there is an error or Stripe is not available, show an error notification.
    if (error) {
      showNotificationEndOfPayment('error', error ?? 'Something went wrong, payment failed, try again');
      return;
    }

    // Show a success notification for the completed payment.
    showNotificationEndOfPayment('success', 'Successful reserved!', data?.payment_intent_id);
  }

  /**
   * Handles changes in the selected card type.
   * @param {string} cardType - The selected card type.
   * @returns {void}
   */
  const cardTypeChangeHandler = (cardType: string): void => {
    setPaymentError('');
    setHiddenPaymentElement(cardType !== 'newCard');
  }

  /**
   * Handles form submission for making a payment.
   * @returns {Promise<void>}
   */
  const handleSubmit = async (): Promise<void> => {
    // Clear any previous payment errors.
    setPaymentError(null);

    // Get form values and determine whether to remember payment method.
    const formValue = form.getFieldsValue();

    // Set payment processing state to true.
    setIsPaymentProcess(true);

    // If the selected card is not a new card, proceed with payment using an existing card.
    if (formValue.cardType && formValue.cardType !== 'newCard') {
      payExistsCard();
      return;
    }

    // Check if Stripe.js and payment elements are loaded.
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showNotificationEndOfPayment('error', 'Stripe has not yet loaded');
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      showNotificationEndOfPayment('error', submitError.message ?? 'Submit payment form error!');
      return;
    }

    // Fetch the client secret for the payment.
    const { clientSecret, error } = await getClientSecret();
    if (!clientSecret) {
      // Show an error notification if the client secret is not obtained.
      showNotificationEndOfPayment('error', error || 'Something went wrong, failed to get payment confirmation, try again');
      return;
    }

    // Confirm the payment with Stripe using the obtained client secret.
    const { setupIntent, error: confirmError } = await stripe.confirmSetup({
      elements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url: window.location.origin,
      },
      redirect: 'if_required',
    });

    if (confirmError) {
      // Show error to your customer (for example, payment details incomplete)
      showNotificationEndOfPayment('error', confirmError.message ?? 'Confirm Card Payment Error!');
      return;
    }
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.

    // Save card and create payment method.
    const { payload } = await dispatch(createPaymentCard({
      si: setupIntent?.payment_method as string
    }));

    if (!payload) {
      // Show error to your customer (for example, payment details incomplete)
      showNotificationEndOfPayment('error', 'Something went wrong, payment failed, try again');
      return;
    }

    payExistsCard(setupIntent?.payment_method as string);
  };

  /**
   * Loads payment cards and updates the component state.
   * @returns {void}
   */
  const loadPaymentsCards = async (isReload: boolean = false): Promise<void> => {
    setIsCardLoading(true);
    if (token) {
      const { payload } = await dispatch(fetchPaymentsCards());
      setHiddenPaymentElement(isReload ? false : !!payload?.payload?.cards?.length);
    } else {
      setHiddenPaymentElement(false);
    }
    setIsCardLoading(false);
  }

  const onCardChangeHandler = (): void => {
    setPaymentError(null);
  }

  useEffect(() => {
    const computedStyle = getComputedStyle(document.documentElement);
    elements?.update({
      appearance: {
        variables: {
          colorBackground: '#ffffff',
          colorText: consultation_type !== 'message' && mode !== 'white' ? '#ffffff' : computedStyle.getPropertyValue('--primary'),
          colorTextPlaceholder: computedStyle.getPropertyValue('--primary-60'),
          colorDanger: computedStyle.getPropertyValue('--red'),
          fontFamily: '"Noto Sans", sans-serif',
        },
        rules: {
          '.Input': {
            borderColor: consultation_type !== 'message' && mode !== 'white' ? '#ffffff' : computedStyle.getPropertyValue('--primary-60'),
            color: computedStyle.getPropertyValue('--primary'),
          }
        }
      }
    });
  }, [consultation_type]);

  useEffect(() => {
    // Reset 'remember' state and load payment cards when component mounts.
    loadPaymentsCards();
  }, []);

  return (
    <Form form={ form } className={ `payment-form payment-form-${ mode }` } onFinish={ handleSubmit }>
      <div className="payment-form--item">
        <WalletButton stripe={ stripe }
                      currency={ currency }
                      mode={ mode }
                      call_duration={ call_duration }
                      showNotificationEndOfPayment={ showNotificationEndOfPayment }
                      consultation_type={ consultation_type }
                      rate_type={ rate_type }
                      worker_id={ worker_id }/>
      </div>
      <div className="payment-form--item item-divider">
        Or pay with card
      </div>
      { (!!cards.length && !isCardLoading) && <div className="payment-form--item">
        <FormControl name="cardType" initialValue={ 'existingCard' }>
          <RadioGroup mode={ mode } onChange={ cardTypeChangeHandler } options={ [
            { value: 'existingCard', label: 'Use Existing Card' },
            { value: 'newCard', label: 'Use Different Card' },
          ] }/>
        </FormControl>
      </div> }
      <div className="payment-form--item item-payment-element" hidden={ hiddenPaymentElement }>
        <PaymentElement options={ CPaymentElementOptions } onReady={ onReadyHandler } onChange={ onChangeHandler }
                        onLoadError={ onMountedErrorHandler }/>
      </div>
      { isCardLoading && <div className="payment-form--item item-loader">
        <SpinElement/>
      </div> }
      { (!!cards.length && !isCardLoading) &&
        <div className="payment-form--item item-card-list" hidden={ !hiddenPaymentElement }>
          <FormControl name="card" initialValue={ !!defaultCard ? defaultCard : cardOptions[0]?.value }
                       valuePropName={ 'defaultValue' }>
            <DropdownElement loading={ isLoad } options={ cardOptions } withoutAll={ true } colorMode={ mode }
                             placeholder={ 'Please select card' } onChange={ onCardChangeHandler }/>
          </FormControl>
        </div> }
      { paymentError && <div className="payment-form--error">
        <InfoMessage textSize={ 'big' } infoMessage={ paymentError } infoIconColor={ EIconColor.Error }
                     type={ 'error' }/>
      </div> }
      <div className="payment-form--item item-actions" hidden={ elementStatus !== 'success' && !hiddenPaymentElement }>
        { cancelBtn && cancelBtn }
        <CustomButton mode={ mode }
                      disabled={ (!isCardComplete && !hiddenPaymentElement) || isCardLoading || !!paymentError }
                      loading={ isPaymentProcess }
                      htmlType={ 'submit' }
                      text={ btnText }/>
      </div>
    </Form>
  );
};
