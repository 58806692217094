import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';

// Shared UI Library Components
import { CustomButton, InfoMessage } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './add-payment.scss';

// Components
import { StripeFooter } from '../stripe-footer/stripe-footer';

// Store entities
import { createPaymentCard } from '../../../store/payments/payments.thunks';
import { getPaymentsData } from "../../../store/payments/payments.selectors";
import { openNotification } from "../../../store/app-notifications/app-notifications.thunks";

// Models
import { CPaymentElementOptions } from '../../../constantes/payment-form.constantes';
import { TPaymentElementState } from '../../../types/payments.types';

type TAddPaymentProps = {};

export const AddPayment: FC<TAddPaymentProps> = (
  {}: TAddPaymentProps
) => {
  const paymentElementRef = useRef<HTMLDivElement>(null);

  const stripe = useStripe();
  const elements = useElements();

  const { client_secret_intent } = useSelector(getPaymentsData);

  const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [elementStatus, setElementStatus] = useState<TPaymentElementState>('mounting');

  const dispatch = useDispatch<any>();

  const onReadyHandler = (): void => {
    setElementStatus('success');
  }

  /**
   * Handles the 'change' event when the Stripe payment element is ready.
   * @param {StripePaymentElementChangeEvent} event - The Stripe payment element.
   * @returns {void}
   */
  const onChangeHandler = (event: StripePaymentElementChangeEvent): void => {
    setIsCardComplete(event.complete);
  }

  const onMountedErrorHandler = (event: { elementType: "payment"; error: StripeError }): void => {
    setElementStatus('error');
    setError(event?.error?.message ?? '');
  }

  const onAddPaymentHandler = async (): Promise<void> => {
    setError('');
    setIsCreating(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsCreating(false);
      setError('Stripe has not yet loaded');
      return;
    }

    if (!client_secret_intent) {
      setIsCreating(false);
      setError('Stripe key not yet loaded');
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    if (submitError) {
      setIsCreating(false);
      setError(submitError.message ?? '');
      return;
    }
    const { error: createError, setupIntent } = await stripe.confirmSetup({
      clientSecret: client_secret_intent,
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      }
    });

    if (createError) {
      // Show error to your customer (for example, payment details incomplete)
      setIsCreating(false);
      setError(createError.message ?? '');
      return;
    }

    const createdPayload = await dispatch(createPaymentCard({
      si: setupIntent?.payment_method as string
    }));

    if (createdPayload.payload?.card) {
      elements.getElement('payment')?.clear();
      dispatch(openNotification({ type: 'success', description: 'Payment method successfully added!' }));
    }
    setIsCreating(false);
  }

  useEffect(() => {
    const computedStyle = getComputedStyle(document.documentElement);
    elements?.update({
      appearance: {
        variables: {
          colorBackground: '#ffffff',
          colorText: computedStyle.getPropertyValue('--primary'),
          colorTextPlaceholder: computedStyle.getPropertyValue('--primary-60'),
          colorDanger: computedStyle.getPropertyValue('--red'),
          fontFamily: '"Noto Sans", sans-serif',
        },
        rules: {
          '.Input': {
            borderColor: computedStyle.getPropertyValue('--primary-60'),
            color: computedStyle.getPropertyValue('--primary'),
          }
        }
      }
    });
  }, []);

  return (
    <div className="add-payment">
      <div className="add-payment--header">
        <div className="header-title">
          Add Payment Method
        </div>
        <div className="header-subtitle">
          Please, enter Payment Method Details below.
        </div>
      </div>
      <div className="add-payment--form" id={ 'paymentElementRef' } ref={ paymentElementRef }>
        <PaymentElement options={ CPaymentElementOptions } onReady={ onReadyHandler } onChange={ onChangeHandler }
                        onLoadError={ onMountedErrorHandler }/>
      </div>
      <div className="add-payment--error" hidden={ !error }>
        <InfoMessage infoMessage={ error } type={ 'error' }/>
      </div>
      <div className="add-payment--actions" hidden={ elementStatus !== 'success' }>
        <CustomButton text={ 'Add' } loading={ isCreating } disabled={ !isCardComplete }
                      onClick={ onAddPaymentHandler }/>
      </div>
      <div className="add-payment--footer">
        <StripeFooter/>
      </div>
    </div>
  );
}
