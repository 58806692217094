import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from "dayjs";
import { Form } from "antd";
import { useLocation } from "react-router-dom";

// Shared UI Library Components
import {
  CalendarPicker, CustomIcon,
  CustomTextArea,
  CustomTooltip,
  DayAvailabilitySelect,
  DropdownElement,
  EIconName,
  IAvailabilityOption,
  ITimezoneOption,
  ServiceTypeToggle,
  TimezoneSelect,
  TServiceType,
  tzDayjs
} from '@ppmcore/seven-ppm-core-shared-components-react';
import {
  TDayAvailabilitySelectRef
} from "@ppmcore/seven-ppm-core-shared-components-react/dist/cjs/types/lib/components/day-availability-select/day-availability-select";

// Styles
import './book-appointment.scss';

// Store entities
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';
import { getDateState } from "../../../../store/date/date.selectors";
import { getBookAppointmentsState } from "../../../../store/appointments/appointments.selectors";
import { getGlobalConfigState } from "../../../../store/global-config/global-config.selectors";
import { setLoaderState } from "../../../../store/loader/loader.thunks";
import { setSelectedTimeZone } from "../../../../store/date/date.thunks";
import { bookAppointment, fetchAppointmentsConfig } from "../../../../store/appointments/appointments.thunks";
import { openNotification } from "../../../../store/app-notifications/app-notifications.thunks";

// Components
import {
  BookAppointmentCheckoutTrigger
} from "./children/book-appointment-checkout-trigger/book-appointment-checkout-trigger";

// Models
import { IAppointment, IAppointmentRate } from "../../../../interfaces/appointments.interfaces";
import { ELocalStorageKeys } from "../../../../enums/storage.enums";
import { TLinksInAppointmentsType } from "../../../../types/links-in.types";
import {
  CLinksInAppointmentsType,
  CLinksInAppointmentsTypeToServiceType
} from "../../../../constantes/link-in.constants";

type TFormValue = {
  timezone: ITimezoneOption;
  appointment_type: TServiceType | null;
  appointment_duration: any | null;
  date: Dayjs;
  availability: IAvailabilityOption | null,
  additional_notes: string,
};

type TBookAppointmentProps = {};

export const BookAppointment: FC<TBookAppointmentProps> = ({}: TBookAppointmentProps) => {
  const dayAvailabilitySelectRef = useRef<TDayAvailabilitySelectRef | null>(null);

  const [form] = Form.useForm<TFormValue>();
  const formValue = Form.useWatch((values) => ({
    timezone: values.timezone,
    appointment_type: values.appointment_type,
    appointment_duration: values.appointment_duration,
    date: values.date,
    availability: values.availability,
  }), form);

  const [formValid, setFormValid] = useState<boolean>(false);

  const location = useLocation();

  const { expert } = useSelector(getCurrentExpertState);
  const { timezonesOptions, selectedTimezone, localTimezone } = useSelector(getDateState);
  const { loading: bookAppointmentsLoading, appointmentsConfig } = useSelector(getBookAppointmentsState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const savedTimezone = sessionStorage.getItem(ELocalStorageKeys.Timezone) || localTimezone;

  const configValue = useMemo(() => {
    const key = `${ formValue?.appointment_type }_rates` as 'audio_call_rates' | 'video_call_rates';
    const rate = appointmentsConfig?.[key]?.find(rate => rate.call_duration === +formValue?.appointment_duration);
    let price: ReactNode = rate?.rates.text;
    if (rate?.full_free_service_enabled) {
      price = 'Free';
    }
    if (rate?.free_minutes_enabled) {
      price = <>{ rate?.rates.text }<CustomTooltip
        title={ `Session has ${ rate.free_service_minutes } minutes for Free` }>
        <CustomIcon name={ EIconName.Info }/></CustomTooltip></>;
    }
    return {
      businessTerms,
      cost: rate?.rates.text || '',
      duration: <>{ formValue?.appointment_duration } min | { price }</> || '',
      name: `${ expert?.first_name } ${ expert?.last_name }`,
      date: formValue?.availability?.from ? `${ tzDayjs(formValue?.availability?.from).format('dddd, MMM DD, hh:mm A') } - ${ tzDayjs(formValue?.availability?.to).format('hh:mm A') }` : '',
      period: appointmentsConfig?.max_approve_hours || 30,
      rate: rate as IAppointmentRate
    };
  }, [appointmentsConfig, formValue, businessTerms]);
  const defaultService = useMemo(() => {
    if (!location.hash) return null;

    const serviceType = location?.hash.slice(1) as TLinksInAppointmentsType;

    if (!CLinksInAppointmentsType.includes(serviceType)) return null;

    return CLinksInAppointmentsTypeToServiceType[serviceType];
  }, [location]);

  const getRateOptions = (rates: Array<IAppointmentRate>) => {
    return rates.map((rate) => {
      let price = rate.rates.text;
      if (rate.full_free_service_enabled) {
        price = 'Free';
      }
      if (rate.free_minutes_enabled) {
        price = `${ rate.rates.text }, Free ${ rate.free_service_minutes } min`;
      }
      return {
        label: `${ rate.call_duration } min | ${ price }`,
        value: `${ rate.call_duration }`,
      };
    }).sort((a, b) => (+a.value - +b.value));
  };

  const durationOptions = useMemo(() => {
    if (!formValue?.appointment_type || !appointmentsConfig) return [];

    if (formValue?.appointment_type === 'audio_call') {
      return getRateOptions(appointmentsConfig.audio_call_rates);
    }
    if (formValue?.appointment_type === 'video_call') {
      return getRateOptions(appointmentsConfig.video_call_rates);
    }
    return [];
  }, [appointmentsConfig, formValue?.appointment_type]);

  const disabledDates = useMemo(() => {
    if (!appointmentsConfig) return [];
    return appointmentsConfig.unavailable_days;
  }, [appointmentsConfig]);

  const enabledServices = useMemo(() => {
    if (!appointmentsConfig) return [];
    return [
      ...(!!appointmentsConfig?.video_call_rates?.length ? ['video_call'] : []),
      ...(!!appointmentsConfig?.audio_call_rates?.length ? ['audio_call'] : []),
    ] as TServiceType[];
  }, [appointmentsConfig]);

  const dispatch = useDispatch<any>();

  const bookAppointmentHandler = async (payment_method_nonce?: string): Promise<IAppointment | null> => {
    if (!expert) return null;

    const formValue = form.getFieldsValue();
    const { payload } = await dispatch(bookAppointment({
      worker_id: expert?.id,
      appointment_duration: formValue.appointment_duration,
      timezone: formValue.timezone.value,
      start_time: formValue.availability?.from as string,
      end_time: formValue.availability?.to as string,
      additional_notes: formValue.additional_notes,
      payment_method_nonce,
      save_payment_method: false,
      appointment_type: formValue.appointment_type as TServiceType,
    }));

    if (!payload) return null;
    loadAppointmentsConfig({ worker_id: expert?.id as number, appointment_day: tzDayjs(formValue.date).toISOString() });
    form.setFieldValue('additional_notes', null);
    dayAvailabilitySelectRef?.current?.reset();
    setFormValid(false);

    return payload;
  };

  const onTimeZoneChangeHandler = (timeZone: ITimezoneOption): void => {
    if (timeZone.value === selectedTimezone) return;
    if (!selectedTimezone) {
      dispatch(setSelectedTimeZone({ timeZone: timeZone.value }));
      return;
    }
    dispatch(setSelectedTimeZone({ timeZone: timeZone.value }));
    sessionStorage.setItem(ELocalStorageKeys.Timezone, timeZone.value);

    dispatch(setLoaderState({ show: true, text: 'Switching Time Zone, please wait for the process to end!' }));
    const timerID = setTimeout(() => {
      clearTimeout(timerID);
      dispatch(setLoaderState({ show: false, text: '' }));
      dispatch(openNotification({
        type: 'success',
        description: 'Time Zone Changed Successfully!'
      }));
    }, 1000);
  }

  const onValuesChangeHandler = (current: TFormValue, {
    timezone,
    appointment_type,
    appointment_duration,
    date,
    availability,
  }: TFormValue) => {
    if (current.hasOwnProperty('additional_notes')) {
      return;
    }
    if (['date', 'appointment_type', 'appointment_duration'].some(key => current.hasOwnProperty(key)) && formValue) {
      loadAppointmentsConfig({
        worker_id: expert?.id as number,
        appointment_day: tzDayjs(date).toISOString(),
        call_type: appointment_type as TServiceType || null,
        call_duration: current.hasOwnProperty('appointment_duration') ? appointment_duration : null
      });
    }
    if (current.hasOwnProperty('date') && formValue?.date) {
      dayAvailabilitySelectRef?.current?.reset();
      form.setFieldValue('appointment_duration', null);
      setFormValid(false);
      return;
    }
    if (current.hasOwnProperty('appointment_type') && formValue?.appointment_type) {
      dayAvailabilitySelectRef?.current?.reset();
      form.setFieldValue('appointment_duration', null);
      setFormValid(false);
      return;
    }
    if (current.hasOwnProperty('appointment_duration') && formValue?.appointment_duration) {
      dayAvailabilitySelectRef?.current?.reset();
      setFormValid(false);
      return;
    }
    setFormValid(
      !!timezone && !!appointment_type && !!appointment_duration && !!date && !!availability &&
      !disabledDates.includes(date.format('YYYY-MM-DD'))
    );
  }

  const loadAppointmentsConfig = (
    { worker_id, appointment_day = tzDayjs().toISOString(), call_type = '', call_duration = '', }: {
      worker_id: number | string,
      appointment_day?: string,
      call_type?: string,
      call_duration?: string
    }): void => {
    dispatch(fetchAppointmentsConfig({ worker_id, appointment_day, call_type, call_duration }));
  }

  useEffect(() => {
    if (!expert) {
      return;
    }
    loadAppointmentsConfig({ worker_id: expert.id });
  }, [expert]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  return (
    <Form form={ form } onValuesChange={ onValuesChangeHandler }
          className={ `book-appointment` }>

      <div className="book-appointment--header">
        <div className="header-item item-title">
          Book Appointment
        </div>
        <div className="header-item item-actions">
          { timezonesOptions.length && <Form.Item name={ 'timezone' } trigger={ 'onTimeZoneChange' }>
            <TimezoneSelect initialTimezone={ savedTimezone }
                            initialEmit={ true }
                            timezones={ timezonesOptions }
                            showLabel={ true }
                            onTimeZoneChange={ onTimeZoneChangeHandler }/>
          </Form.Item> }
        </div>
      </div>

      <div className="book-appointment--body">
        <div className="body-row flex-items">
          <div className="row-item">
            { !!enabledServices.length && <Form.Item name={ 'appointment_type' } trigger={ 'onServiceTypeChange' }>
              <ServiceTypeToggle defaultService={ defaultService } initialEmit={ true }
                                 enabledServices={ enabledServices }/>
            </Form.Item> }
          </div>
          <div className="row-item">
            <Form.Item name={ 'appointment_duration' }>
              <DropdownElement label={ 'Duration & Cost' }
                               placeholder={ 'Please select duration' }
                               withoutAll={ true }
                               options={ durationOptions }/>
            </Form.Item>
          </div>
        </div>

        <div className="body-row flex-items">
          <div className="row-item calendar-item">
            <Form.Item name={ 'date' } trigger={ 'onChange' }>
              <CalendarPicker disabledDatesFormat={ 'YYYY-MM-DD' }
                              disabledDates={ disabledDates }
                              maximumDays={ +(appointmentsConfig?.maximum_booking_days || 90) }/>
            </Form.Item>
          </div>
          <div className="row-item ">
            <Form.Item name={ 'availability' } trigger={ 'onAvailabilityChange' }>
              {

              }
              <DayAvailabilitySelect ref={ (ref) => dayAvailabilitySelectRef.current = ref }
                                     loading={ bookAppointmentsLoading }
                                     currentDate={ formValue?.date }
                                     timeSplit={ +(formValue?.appointment_duration || 10000) }
                                     availabilitySlots={ appointmentsConfig?.available_days || [] }
              />
            </Form.Item>
          </div>
        </div>

        <div className="body-row">
          <div className="row-item">
            <Form.Item name={ 'additional_notes' }>
              <CustomTextArea placeholder={ 'Add Note' }
                              disabled={ !formValid }
                              maxLength={ 500 }
                              showCount={ true }
                              autoSize={ {
                                maxRows: 5,
                              } }
              />
            </Form.Item>
          </div>
        </div>

        <div className="body-row">
          <div className="row-item">
            <BookAppointmentCheckoutTrigger disabled={ !formValid }
                                            type={ formValue?.appointment_type || 'video_call' }
                                            config={ configValue }
                                            loading={ bookAppointmentsLoading }
                                            expert={ expert }
                                            onBookAppointment={ bookAppointmentHandler }/>
          </div>
        </div>
      </div>
    </Form>
  );
};
