import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  InputPassword,
  CustomInput,
  EIconName,
  defaultValidateMessages,
  emailPattern,
  ServerErrors, ServerErrorsTimer
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';
import { FormBottomActions } from '../auth-modules/form-bottom-actions/form-bottom-actions';

// Thunks
import { clearAuthState, signInUser } from '../../../store/auth/auth.thunks';
import { saveUserProfile } from '../../../store/user/user.thunks';

// Selectors
import { getAuthState } from '../../../store/auth/auth.selectors';
import { getCoreState } from "../../../store/core/core.selectors";

// Styles
import './sign-in.scss';

export const SignIn = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  dayjs.extend(durationPlugin);
  dayjs.extend(utc);
  const currentData = dayjs().utc();

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);

  const [formDisabled, setFormDisabled] = useState(false);
  const [errorTimerTime, setErrorTimerTime] = useState<string>("");
  const [errorTimerText, setErrorTimerText] = useState<string | null>(null);

  const { loading } = useSelector(getAuthState);
  const { companyInfo } = useSelector(getCoreState);

  const clickLink = (value: string) => {
    dispatch(clearAuthState()).then(() => navigate(value));
  }

  const skipForNowHandler = () => {
    navigate(`/${ companyInfo.company?.company_profile?.url_code }`);
  }

  useEffect(() => {
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');
    localStorage.removeItem('beforeErrorTime');
    localStorage.removeItem('beforeErrorMessage');
    const signInAccessTime = localStorage.getItem('signInAccessTime');
    const signInAccessMessage = localStorage.getItem('signInAccessMessage');
    if (signInAccessTime && signInAccessMessage) {
      const startedData = dayjs(signInAccessTime).utc();
      const totalSeconds = startedData.diff(currentData, "seconds");
      if (totalSeconds > 0) {
        form.resetFields();
        setBtnDisabled(true);
        setFormDisabled(true);
        setErrorTimerTime(signInAccessTime);
        setErrorTimerText(signInAccessMessage);
        return;
      }
      onErrorTimerEnd();
    }
  }, []);

  const onFinish = () => {
    if (btnDisabled) return;
    form.validateFields().then((values) => {
      const temporaryToken = localStorage.getItem('temporary_token');
      const userData = {
        email: values.email,
        password: values.password,
        temporary_token: temporaryToken ?? '',
        device_UDID: localStorage.getItem('device_UDID')
      };

      dispatch(signInUser(userData)).then((res: { payload: any }) => {
        if (res.payload?.error && res.payload.access_after) {
          setErrorTimerTime(res.payload.access_after);
          setErrorTimerText(res.payload?.error);
          localStorage.setItem('signInAccessTime', res.payload.access_after);
          localStorage.setItem('signInAccessMessage', res.payload?.error);
          form.resetFields();
          setBtnDisabled(true);
          setFormDisabled(true);
          setServerError(null);
          return;
        }

        if (res.payload && res.payload?.error) {
          setBtnDisabled(true);
          setServerError(res.payload?.error);
          return;
        }

        localStorage.removeItem('signInAccessTime');
        localStorage.removeItem('signInAccessMessage');
        if (res?.payload && res?.payload?.redirect_to_verification) {
          localStorage.setItem('temporaryToken', res.payload.temporary_token)
          localStorage.setItem('authVerificationResendAccess', res.payload.resend_access)
          navigate('/account-verification');
        } else if (res?.payload && !res?.payload?.redirect_to_verification) {

          localStorage.setItem('token', res.payload.token);
          dispatch(saveUserProfile(res.payload.user));

          navigate('/home');
        }
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const onErrorTimerEnd = () => {
    localStorage.removeItem('signInAccessTime');
    localStorage.removeItem('signInAccessMessage');
    setBtnDisabled(false);
    setFormDisabled(false);
    setServerError(null);
    setErrorTimerTime("");
    setErrorTimerText(null);
  };

  const onValuesChange = (changedValues: { email: string } | { password: string },
                          allFormValues: { email: string | undefined, password: string | undefined }) => {
    if (allFormValues.password !== undefined
      && allFormValues.email !== undefined
      && allFormValues.password !== ''
      && allFormValues.email !== ''
      && new RegExp(emailPattern).test(allFormValues.email)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  return (<AuthLayout cardTitle="Sign In" rightBarIcon="auth">
    <Form
      form={ form }
      disabled={ formDisabled }
      name="sign-in"
      onFinish={ onFinish }
      autoComplete="off"
      onValuesChange={ onValuesChange }
      validateMessages={ defaultValidateMessages }>

      { errorTimerText && errorTimerTime && (
        <ServerErrorsTimer
          serverError={ errorTimerText }
          errorTime={ errorTimerTime }
          onTimeEnd={ onErrorTimerEnd }
        />
      ) }

      <FormBottomActions infoText={ 'Don’t have an account?' } isSignInLink={ false }/>

      { serverError && <ServerErrors serverError={ serverError }/> }

      <Form.Item
        name="email"
        messageVariables={ { fieldLabel: 'Email' } }
        rules={ [
          { required: true },
          { max: 60 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                return Promise.resolve();
              }
              if (value.length > 60) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
            }
          })] }>
        <CustomInput disabled={ formDisabled } shouldTrim={ true } type={ 'text' } placeholder={ 'Email*' }
                     suffix={ <CustomIcon name={ EIconName.Mail }/> }/>
      </Form.Item>

      <Form.Item
        name="password"
        messageVariables={ { fieldLabel: 'Password' } }
        rules={ [{ required: true }] }>
        <InputPassword disabled={ formDisabled } placeholder={ 'Password*' }/>
      </Form.Item>

      <div className="form-bottom form-bottom-block">
        <a onClick={ () => clickLink('/forgot-password') } className="forgot-password-link">Forgot Password?</a>
        <div className={ 'actions' }>
          { (companyInfo.company?.company_profile && !Array.isArray(companyInfo.company?.company_profile)) &&
            <CustomButton text="Skip for Now" type={ 'text' } onClick={ skipForNowHandler }/> }
          <CustomButton htmlType={ 'submit' } disabled={ loading || btnDisabled } text="Log In"/>
        </div>
      </div>
    </Form>
  </AuthLayout>);
}
