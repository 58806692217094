import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './call-notifications.scss';

interface ICallNotificationProps {
  title: string;
  text: string;
  navigateAction: string;
  navigateActionText?: string;
  additionAction?: string;
  additionActionText?: string;
  showPrimaryBtn?: boolean;
  isPrimaryBtnDisabled?: boolean;
  onNavigateAction?: () => void;
  onAdditionAction?: () => void;
}

export const CallNotifications: FC<ICallNotificationProps> = ({
    title,
    text,
    navigateAction,
    navigateActionText,
    additionAction,
    additionActionText,
    showPrimaryBtn = true,
    isPrimaryBtnDisabled = false,
    onNavigateAction = () => {},
    onAdditionAction = () => {} }: ICallNotificationProps ): JSX.Element => {

  const navigateBtn = () => {
    localStorage.removeItem('return_pathname');
    onNavigateAction();
    window.location.href = `${navigateAction}`;
  }

  const navigateAdditionBtn = () => {
    localStorage.removeItem('return_pathname');
    onAdditionAction();
    if (additionAction) window.location.href = `${additionAction}`;
  }

  return (<div className={'call-notification-page'}>
    <div className={'call-notification-page-inside'}>
      <div className={'notification-title'}>{title}</div>
      <div className={'notification-text'}>{text}</div>
      <div className={'actions'}>
        {additionAction && <CustomButton mode={'dark'} type={'link'} onClick={navigateAdditionBtn}>{additionActionText}</CustomButton>}
        {showPrimaryBtn && <CustomButton disabled={isPrimaryBtnDisabled} mode={'dark'} type={'primary'} onClick={navigateBtn}>{navigateActionText ?? 'OK'}</CustomButton>}
      </div>
    </div>
  </div>);
}
