import React from 'react';
import { useSelector } from "react-redux";

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-declined.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const StatusDeclined = () => {

  const { businessTerms } = useSelector(getGlobalConfigState);

  const workerId = localStorage.getItem('worker_id');

  return (<CallNotifications
    title={ `${ businessTerms.Consultant } Unavailable` }
    text={ `The ${ businessTerms.Consultant } declined the call. You may try to contact them later. The payment for the ${ businessTerms.Consultation } will be refunded to you.` }
    navigateAction={ `/expert/${ workerId }/history` }/>);
}
