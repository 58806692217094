import { FC } from 'react';
import { Statistic } from "antd";

// Styles
import './payment-failed-reminder-countdown.scss';

// Models
import { IPaymentFailed } from "../../../interfaces/payments.interfaces";

export type TPaymentFailedReminderCountdownProps = {
  paymentError: IPaymentFailed;
};

export const PaymentFailedReminderCountdown: FC<TPaymentFailedReminderCountdownProps> = (
  { paymentError }: TPaymentFailedReminderCountdownProps
) => {

  return (
    <div className="payment-failed-reminder-countdown">
      Call will conclude in <Statistic.Countdown value={ paymentError?.finished_at } format={ 'mm:ss' }/>
    </div>
  );
};
