import React from 'react';
import { useSelector } from "react-redux";

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-unavailable.scss';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

export const ChatStatusUnavailable = () => {

  const { businessTerms } = useSelector(getGlobalConfigState)

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;

  return (<ConversationNotifications
    title={ 'Availability Issue' }
    text={ `The ${ businessTerms.Consultant } is not available at the moment. You may try to contact them later. The payment for the Text Chat will be refunded to you.` }
    navigateAction={ returnPathname }/>);
}
