import React, { FC, useState } from 'react';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomInput,
  defaultValidateMessages,
  EIconName,
  emailPattern,
  stringPattern
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { StaticTextModal } from '../../static-text-modal/static-text-modal';
import { StaticPages } from '../../../modules/static-pages/static-pages';
import { TuPowerBy } from '../modules/tu-power-by/tu-power-by';
import { TuTitle } from '../modules/tu-title/tu-title';

// Store entities
import { signUpTempUser } from '../../../store/auth/auth.thunks';
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';
import { getCoreState } from "../../../store/core/core.selectors";

// Styles
import './tu-first-step.scss';

// Models
import { TUserMenuActions } from "../../../types/user-actions.types";

interface ITuFirstStepProps {
  type: TUserMenuActions;
  companyCode: string;
  isMessagesAction: boolean;
  onChangeViewType?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const TuFirstStep: FC<ITuFirstStepProps> = (
  {
    type,
    companyCode,
    isMessagesAction,
    onChangeViewType = () => {},
    onSubmit = () => {},
    onCancel = () => {}
  }: ITuFirstStepProps
) => {
  const dispatch = useDispatch<any>();
  const [form] = Form.useForm();

  const { companyInfo } = useSelector(getCoreState);

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [staticTermsModalOpen, setStaticTermsModalOpen] = useState(false);
  const [staticPrivacyModalOpen, setStaticPrivacyModalOpen] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const handleCancel = (): void => {
    form.resetFields();
    setEmailExists(false);
    onCancel();
  };

  const changeTypeToLogin = (): void => {
    onChangeViewType();
  }

  const handleSubmit = async (): Promise<void> => {
    if (btnDisabled) return;
    form.validateFields().then((values) => {
      const { first_name, last_name, email } = values;
      dispatch(signUpTempUser({ first_name, last_name, email, company_code: companyCode }))
        .then(((res: { payload: any }) => {
          if (res.payload?.error === 'The email has already been taken.') {
            setEmailExists(true);
            setBtnDisabled(true);
            form.setFields([
              {
                name: 'email',
                errors: [res.payload?.error],
              },
            ]);
            return;
          }

          if (res.payload && res.payload?.error) {
            setBtnDisabled(true);
            dispatch(openNotification({
              description: res.payload.error,
              type: 'error'
            }));
            return;
          }

          localStorage.setItem('temporaryData', JSON.stringify(values));

          setEmailExists(false);
          localStorage.setItem('tempSignUpToken', res.payload.temporary_token);
          localStorage.setItem('tempSignUpResendAccess', res.payload.resend_access);
          onSubmit();
        }));
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  }

  const onValuesChange = (changedValues: { first_name: string } | { last_name: string } | { email: string },
                          allFormValues: {
                            first_name: string | undefined,
                            last_name: string | undefined,
                            email: string | undefined
                          }) => {
    if (allFormValues.first_name !== undefined
      && allFormValues.last_name !== undefined
      && allFormValues.email !== undefined
      && allFormValues.first_name !== ''
      && allFormValues.last_name !== ''
      && allFormValues.email !== ''
      && allFormValues.first_name.length <= 30
      && allFormValues.last_name.length <= 30
      && allFormValues.email.length <= 60
      && new RegExp(emailPattern).test(allFormValues.email)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  return (
    <div className="tu-first-step">
      <TuTitle type={ type } currentStep={ '1' } totalSteps={ '2' }/>
      <Form
        form={ form }
        className="tu-first-step-form"
        onValuesChange={ onValuesChange }
        validateMessages={ defaultValidateMessages }>
        <div className="body-item">

          <div className="tu-first-step-form--redirect">
            Already have an account? <span className="redirect-action" onClick={ changeTypeToLogin }>Log In</span>
          </div>

          <Form.Item
            name="first_name"
            messageVariables={ { fieldLabel: 'First Name' } }
            rules={ [
              { required: true, max: 30 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || new RegExp(stringPattern).test(getFieldValue('first_name'))) {
                    return Promise.resolve();
                  }
                  if (value.length > 30) {
                    return Promise.resolve();
                  }
                  setBtnDisabled(true);
                  return Promise.reject(new Error(defaultValidateMessages.invalidFirstNameFormat));
                }
              })
            ] }>
            <CustomInput type={ 'text' } placeholder={ 'First Name*' }
                         suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
          </Form.Item>

          <Form.Item
            name="last_name"
            messageVariables={ { fieldLabel: 'Last Name' } }
            rules={ [
              { required: true, max: 30 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || new RegExp(stringPattern).test(getFieldValue('last_name'))) {
                    return Promise.resolve();
                  }
                  if (value.length > 30) {
                    return Promise.resolve();
                  }
                  setBtnDisabled(true);
                  return Promise.reject(new Error(defaultValidateMessages.invalidLastNameFormat));
                }
              })
            ] }>
            <CustomInput type={ 'text' } placeholder={ 'Last Name*' }
                         suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
          </Form.Item>

          <Form.Item
            name="email"
            messageVariables={ { fieldLabel: 'Email' } }
            rules={ [
              { required: true },
              { max: 60 },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                    return Promise.resolve();
                  }
                  if (value.length > 60) return Promise.resolve();
                  setBtnDisabled(true);
                  return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
                }
              })] }>
            <CustomInput type={ 'text' } shouldTrim={ true } placeholder={ 'Email*' }
                         suffix={ <CustomIcon name={ EIconName.Mail }/> }/>
          </Form.Item>
        </div>

        { emailExists &&
          <div className={ 'link-to-sign-in' }><Link to={ '/sign-in' }>Log In</Link> or use another email.</div> }

        { isMessagesAction && <div className={ 'info-section' } style={ { marginTop: emailExists ? '24px' : '0' } }>
          <CustomIcon name={ EIconName.Info }/>
          <div className={ 'info-section-text' }>Please make sure to provide your active email address as we will send
            you
            a response to it.
          </div>
        </div> }

        <div className={ 'temp-user-terms' }
             style={ { marginTop: isMessagesAction || emailExists ? '24px' : '24px' } }>By clicking Continue
          you agree to <a className={ 'dialog-link' } onClick={ () => setStaticTermsModalOpen(true) }>Terms of
            Service</a> and <a
            className={ 'dialog-link' } onClick={ () => setStaticPrivacyModalOpen(true) }>Privacy Policy</a>, and to { (companyInfo?.company?.company_profile?.payment_provider === 'stripe' || !companyInfo?.company?.company_profile?.payment_provider) && <>
            Stripe
            Press’s <a
            className={ 'dialog-link' } href={ 'https://stripe.com/legal/ssa' } target={ '_blank' }>Terms of
            Service</a> and <a
            className={ 'dialog-link' } href={ 'https://stripe.com/privacy' } target={ '_blank' }>Privacy Policy</a>
          </> }
          { companyInfo?.company?.company_profile?.payment_provider === 'paypal' && <>
            Paypal
            Press’s <a
            className={ 'dialog-link' } href={ 'https://www.paypal.com/us/legalhub/useragreement-full' }
            target={ '_blank' }>Terms of
            Service</a> and <a
            className={ 'dialog-link' } href={ 'https://www.paypal.com/ua/legalhub/privacy-full' } target={ '_blank' }>Privacy
            Policy</a>
          </> }
        </div>
      </Form>

      <StaticTextModal
        modalTitle="Terms of Service"
        open={ staticTermsModalOpen }
        showFooter={ false }
        onCancel={ () => setStaticTermsModalOpen(false) }>
        <StaticPages staticPageId={ 2 } isDialog={ true }/>
      </StaticTextModal>

      <StaticTextModal
        modalTitle="Privacy Policy"
        open={ staticPrivacyModalOpen }
        showFooter={ false }
        onCancel={ () => setStaticPrivacyModalOpen(false) }>
        <StaticPages staticPageId={ 1 } isDialog={ true }/>
      </StaticTextModal>

      <div className={ 'tu-footer' }>
        <CustomButton text={ 'Cancel' } type={ 'text' } onClick={ handleCancel }/>
        <CustomButton text={ 'Continue' } disabled={ btnDisabled } onClick={ handleSubmit }/>
      </div>

      <TuPowerBy/>
    </div>)
};
