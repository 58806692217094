import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@uidotdev/usehooks';

// Shared UI Library Components
import { CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './aside-menu.scss';

// Components
import { TempUserSignUpModal } from '../../../../modals/temp-user-sign-up-modal/temp-user-sign-up-modal';

// Store entities
import { getCoreState } from '../../../../store/core/core.selectors';
import { getUserState } from '../../../../store/user/user.selectors';

// Config
import { CCounsellorPageMenuList } from '../../../../configs/components/counsellors.config';

// Models
import { IExpertProfile } from '../../../../interfaces/experts.interfaces';
import { TUserMenuActions } from "../../../../types/user-actions.types";

type TAsideMenuProps = {
  expert: IExpertProfile,
}

export const AsideMenu: FC<TAsideMenuProps> = (
  { expert }: TAsideMenuProps
): JSX.Element => {

  const { width: windowWidth } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);
  const personalLink = useMemo(() => params?.personalLink, [params?.personalLink]);

  const { path: { current: currentPath } } = useSelector(getCoreState);
  const { user } = useSelector(getUserState);

  const [menuItems, setMenuItems] = useState(CCounsellorPageMenuList);
  const [tempUserSignUpModalOpen, setTempUserSignUpModalOpen] = useState<boolean>(false);
  const [isMessagesAction, setIsMessagesAction] = useState<boolean>(false);
  const [actionPath, setActionPath] = useState<string>('');
  const [actionTypeValue, setActionTypeValue] = useState<TUserMenuActions>(null);

  const updateTemporaryMenuList = (has_available_requests: boolean) => {
    setMenuItems(CCounsellorPageMenuList.map((item) => {
      if (item.actionType && item.actionType === 'messages') {
        item.disabled = !has_available_requests || false;
        item.disabledTooltip = (has_available_requests && !!expert.message_rate.value) ? item.disabledTooltip : 'Send Request is unavailable at this moment';
      }
      if (item.actionType && item.actionType === 'book-appointment') {
        item.disabled = false;
      }
      if (item.actionType && item.actionType === 'history') {
        item.disabled = false;
      }
      if (item.actionType && item.actionType === 'transactions') {
        item.disabled = false;
      }
      return item;
    }));
  }

  useEffect(() => {
    if (expert) {
      const temporaryToken = localStorage.getItem('temporary_token');
      const token = localStorage.getItem('token');

      if (user?.user_type === 'temporary') {
        updateTemporaryMenuList(expert.has_available_requests);
        return;
      }

      const isMessagesAvailable = expert.message_rate.value !== 0;

      setMenuItems(CCounsellorPageMenuList.map((item) => {
        if (item.actionType && item.actionType === 'messages') {
          item.disabled = !isMessagesAvailable || !expert.has_available_requests || (!temporaryToken && !token);
          item.hide = !expert.is_messages_enabled || expert?.is_blocked || !expert?.is_active;
          item.disabledTooltip = ((expert.has_available_requests && !!expert.message_rate.value) || !user) ? item.disabledTooltip : 'Send Request is unavailable at this moment';
        }
        if (item.actionType && item.actionType === 'book-appointment') {
          item.disabled = !temporaryToken && !token;
        }
        if (item.actionType && item.actionType === 'history') {
          item.disabled = !temporaryToken && !token;
        }
        if (item.actionType && item.actionType === 'transactions') {
          item.disabled = !temporaryToken && !token;
        }
        return item;
      }));
    }
  }, [expert, user]);

  const onMenuItemClickHandler = (path: string, actionTypeValue: string | null, disabled: boolean): void => {
    const temporaryToken = localStorage.getItem('temporary_token');
    const token = localStorage.getItem('token');

    if (disabled) return;

    setActionTypeValue(actionTypeValue as any);

    if (actionTypeValue) {
      localStorage.setItem('return_pathname', location.pathname);
      localStorage.setItem('call_type', actionTypeValue);
      localStorage.setItem('worker_id', expert.id + '');
    }

    if (actionTypeValue && !token && !temporaryToken && actionTypeValue === 'messages') {
      path = `${ companyCode }/${ personalLink }/messages`;
      setIsMessagesAction(true);
      setActionPath(path);
      setTempUserSignUpModalOpen(true);
      return;
    }

    if (actionTypeValue && !token && !temporaryToken && actionTypeValue === 'messages') {
      path = `${ companyCode }/${ personalLink }/book-appointment`;
      setIsMessagesAction(true);
      setActionPath(path);
      setTempUserSignUpModalOpen(true);
      return;
    }

    if (actionTypeValue && actionTypeValue === 'messages' && !token && temporaryToken) {
      path = `${ companyCode }/${ personalLink }/messages`;
    }

    if (actionTypeValue && actionTypeValue === 'book-appointment' && !token && temporaryToken) {
      path = `${ companyCode }/${ personalLink }/book-appointment`;
    }

    if (actionTypeValue === 'profile' && (!token || (!!companyCode && !!personalLink))) {
      path = `${ companyCode }/${ personalLink }`;
    }

    if (actionTypeValue === 'rates' && (!token || (!!companyCode && !!personalLink))) {
      path = `${ companyCode }/${ personalLink }/rates`;
    }

    if (actionTypeValue === 'transactions' && ((!token && temporaryToken) || (!!companyCode && !!personalLink))) {
      path = `${ companyCode }/${ personalLink }/transactions`;
    }

    if (actionTypeValue === 'history' && ((!token && temporaryToken) || (!!companyCode && !!personalLink))) {
      path = `${ companyCode }/${ personalLink }/history`;
    }

    navigate(`/${ path }`);
  }

  const signUpModalSubmit = () => {
    setTempUserSignUpModalOpen(false);
    navigate(`/${ actionPath }`);

    // setMenuItems(CExpertMenuList.map((item) => {
    //   if (item.actionType && item.actionType === 'history') {
    //     item.disabled = false;
    //   }
    //   if (item.actionType && item.actionType === 'transactions') {
    //     item.disabled = false;
    //   }
    //   return item;
    // }));
  }

  const signUpModalCancel = () => {
    localStorage.removeItem('return_pathname');
    localStorage.removeItem('call_type');
    localStorage.removeItem('worker_id');
    setActionPath('');
    setTempUserSignUpModalOpen(false);
  }

  return (
    <div className="aside-menu">

      { companyCode
        && <TempUserSignUpModal
          type={ actionTypeValue }
          open={ tempUserSignUpModalOpen }
          companyCode={ companyCode }
          isMessagesAction={ isMessagesAction }
          onSubmit={ signUpModalSubmit }
          onCancel={ signUpModalCancel }/> }

      {
        menuItems.map(({
          withBg,
          icon,
          activeIcon,
          label,
          path,
          actionType,
          disabled,
          disabledTooltip,
          hide,
          isSecure
        }) => {
          const token = localStorage.getItem('token');
          const replacedPath = path.replace('{id}', `${ expert.id }`);
          const actionTypeValue = actionType ?? null;
          const splitPath = currentPath.split('/');
          const isActive = currentPath === replacedPath ||
            (actionType === 'profile' && decodeURIComponent(splitPath[splitPath.length - 1]) === expert.personal_link) ||
            currentPath.includes(actionType);

          const showTempUser = !token ? !isSecure : true;
          return (!hide && showTempUser &&
            <CustomTooltip key={ label } placement={ (windowWidth && windowWidth <= 1024) ? 'bottom' : 'right' }
                           title={ disabled ? disabledTooltip : label }>
              <div
                className={ `aside-menu--item ${ disabled ? 'disabled-btn' : '' } ${ withBg ? 'item-bg' : '' } ${ isActive ? 'active-item' : '' }` }
                onClick={ () => onMenuItemClickHandler(replacedPath, actionTypeValue, disabled) }>
                <span className="icon-item">{ isActive ? activeIcon : icon }</span>
                <span className="text-item">{ label }</span>
              </div>
            </CustomTooltip>)
        })
      }
    </div>
  )
};
