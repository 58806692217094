export const CKeywordsDefault = {
  Consultation: 'Consultation',
  Consultations: 'Consultations',
  Consultations_S: 'Consultations\'',
  Consultant: 'Consultant',
  Consultants: 'Consultants',
  Consultants_S: 'Consultant\'s',

  consultation: 'consultation',
  consultations: 'consultations',
  consultations_S: 'consultations\'',
  consultant: 'consultant',
  consultants: 'consultants',
  consultants_S: 'consultant\'s',
};
