import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Shared UI Library Components
import {
  EIconColor,
  EIconName,
  CustomIcon,
  CustomButton,
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { clearChatFinishedStore, getChatFinishedInfo } from '../../../store/chat-finished/chat-finished.thunks';

// Selectors
import { getChatFinishedState } from '../../../store/chat-finished/chat-finished.selectors';
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";

// Styles
import './chat-finished.scss';

export const ChatFinished = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const temporaryToken = localStorage.getItem('temporary_token');

  const [errorText, setErrorText] = useState<string | null>(null);

  const { chatFinished, error } = useSelector(getChatFinishedState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  useEffect(() => {
    if (!id) return;
    dispatch(getChatFinishedInfo({ conversation_id: +id, temporary_token: temporaryToken ?? '' }))
      .then((res: any) => {
        if (res.payload && res.payload.error) {
          setErrorText(res.payload.error);
        }
      });
  }, [id]);

  const finishCall = async () => {
    if (!token) {
      dispatch(clearChatFinishedStore()).then(() => navigate(`/conversation/stay-with-us`));
      return;
    }
    localStorage.removeItem('return_pathname');
    if (chatFinished) {
      dispatch(clearChatFinishedStore()).then(() => navigate(`/expert/${ chatFinished?.worker?.id }/history`));
      return;
    }
    navigate(`/home`);
  }

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  useEffect(() => {
    return () => {
      dispatch(clearChatFinishedStore());
    }
  }, []);

  return (<div className={ `chat-finished` }>
    { !chatFinished && <SpinElement/> }
    { error && errorText &&
      <ConversationNotifications title={ 'Call is not exists' } text={ errorText ?? '' } navigateAction={ `/home` }/> }
    { chatFinished && !error && <div className={ 'chat-finished-inside' }>
      <div className={ 'title' }>{ businessTerms.Consultation } Finished</div>
      <div
        className={ 'chat-with' }>Text Chat
        with { chatFinished.worker?.first_name } { chatFinished.worker?.last_name }</div>
      <div className={ 'chat-result-info' }>
        <CustomIcon name={ EIconName.Chat } color={ EIconColor.White }/>
        <div className={ 'chat-result-text' }>{ chatFinished.conversation_duration }</div>
        <div className={ 'chat-divider' }></div>
        <div className={ 'chat-result-text' }>{ chatFinished.conversation_total }</div>
      </div>
      <div className={ 'actions-section' }>
        <CustomButton text="OK" mode={ 'dark' } onClick={ finishCall }/>
      </div>
    </div> }
    <div className={ 'power-by' }>
      <div className={ 'power-by-inside' } onClick={ navigateToLanding }>
        <div className={ 'text' }>powered by</div>
        <img src={ '/assets/images/footer-logo.svg' } alt={ 'footer-logo' }/>
      </div>
    </div>
  </div>);
}
