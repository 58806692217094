import React, { FC } from 'react';
import { useSelector } from "react-redux";

// Styles
import './tu-title.scss';

// Store Entities
import { getCurrentExpertState } from "../../../../../store/currentExpert/currentExpert.selectors";

type TTuTitleProps = {
  currentStep: string;
  totalSteps: string;
}

export const TuTitle: FC<TTuTitleProps> = ({ currentStep, totalSteps }: TTuTitleProps) => {
  const {} = useSelector(getCurrentExpertState);

  return (
    <div className={ 'links-in-tu-title' }>
      <div className={ 'text' }>Sign Up</div>
      <div className={ 'title-steps' }><span>{ currentStep }</span>/{ totalSteps }</div>
    </div>)
};
