import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { fetchOngoingCalls } from '../../../store/ongoing-consultation/ongoing-consultation.thunks';
import { getCallInfo } from '../../../store/call/call.thunks';

// Store entities
import { getGlobalConfigState } from "../../../store/global-config/global-config.selectors";
import { getCallState } from '../../../store/call/call.selectors';
import { getLastMessageData } from '../../../store/socket/socket.selectors';

// Enums
import { EWebSocketTypes } from '../../../enums/web-socket-types.enums';

// Styles
import './status-rejoin.scss';

export const StatusRejoin = () => {

  const dispatch = useDispatch<any>();

  const workerId = localStorage.getItem('worker_id');
  const callId = localStorage.getItem('call_id');

  const lastMessage = useSelector(getLastMessageData);
  const { callProcess, loading } = useSelector(getCallState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const [contentText, setContentText] = useState<string>('');
  const [additionActionLink, setAdditionActionLink] = useState<string>(localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`);

  useEffect(() => {
    if (!callProcess && callId) {
      dispatch(getCallInfo({ call_id: +callId, temporary_token: localStorage.getItem('temporary_token') ?? '' }));
    }
  }, [callId]);

  useEffect(() => {
    if (callProcess) {
      setContentText(`We lost connection with ${ callProcess?.worker?.first_name } ${ callProcess?.worker?.last_name }. Please use the button below to try to rejoin the ${ businessTerms.Consultation }. You will be connected to the same ${ businessTerms.Consultation } session.`);
      if (callProcess.call_status === 'finished') setAdditionActionLink(`/call-finished/${ callProcess.call_id }`);
    }
  }, [callProcess]);

  useEffect(() => {
    if (lastMessage?.type === EWebSocketTypes.EndCall) {
      setAdditionActionLink(`/call-finished/${ lastMessage.data.call_id }`);
    }
  }, [lastMessage]);

  const skipRejoin = () => {
    dispatch(fetchOngoingCalls());
  }

  return (loading ? <div className={ 'call-notifications-spinner' }><SpinElement/></div>
    : <CallNotifications
      title={ `Oops, something went wrong on the ${ businessTerms.Consultant } side` }
      text={ contentText }
      navigateAction={ `/call/${ callId }` }
      navigateActionText={ 'Rejoin' }
      additionAction={ additionActionLink }
      additionActionText={ 'Skip' }
      onAdditionAction={ skipRejoin }/>);
}
