export enum EWebSocketTypes {
  ApproveCall = 'approve-call',
  CallRejected = 'call-rejected',
  CallNotAnswered = 'call-not-answered',
  NeedUpdatePayment = 'need-to-update-call-payment',
  UpdatePaymentFailed = 'charge-payment-failed',
  NeedToChargeSessionCallPayment = 'need-to-charge-session-call-payment',
  CallSuccessCharge = 'call-success-charge',
  EndCall = 'end-call',
  ApproveConversation = 'approve-conversation',
  ConversationRejected = 'conversation-rejected',
  ConversationNotAnswered = 'conversation-not-answered',
  NeedConversationUpdatePayment = 'need-to-update-conversation-payment',
  ConversationSuccessCharge = 'conversation-success-charge',
  EndConversation = 'end-conversation',
  CompanyBlocked = 'company-blocked',
  CompanyUnblocked = 'company-unblocked',
  CompanyBillingUpdated = 'company-billing-updated',
  RejoinPopup = 'rejoin-popup',
  CallReconnected = 'call-reconnected',
}
