import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import {
  PayPalScriptProvider,
  PayPalCardFieldsProvider,
} from "@paypal/react-paypal-js";
import { PayPalCardFieldsStateObject } from "@paypal/paypal-js/types/components/card-fields";

// Shared UI Library Components
import { InfoMessage } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './add-payment-paypal.scss';

// Components
import { SubmitPaymentPaypal } from "../submit-payment-paypal/submit-payment-paypal";
import { CardFields } from "../card-fields/card-fields";
import { PaypalFooter } from "../paypal-footer/paypal-footer";

// Services
import { PaymentsService } from "../../../services/payments.service";

// Store entities
import { createPaymentCard } from "../../../store/payments/payments.thunks";
import { openNotification } from "../../../store/app-notifications/app-notifications.thunks";

// Configs
import {
  CPayPalScriptProviderOptions,
  CPayPalStylesOptions
} from "../../../constantes/paypal.constantes";

type TAddPaymentProps = {};

export const AddPaymentPaypal: FC<TAddPaymentProps> = ({}: TAddPaymentProps) => {

  // States for form validation, errors, card state, and creation process
  const [isCardComplete, setIsCardComplete] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [cardState, setCardState] = useState<PayPalCardFieldsStateObject | null>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  /**
   * Function to request and create a Vault Setup Token from the server.
   * This token is needed for setting up the card with PayPal.
   * @returns {Promise<string>} - Vault Setup Token from PayPal
   */
  const createVaultSetupToken = async (): Promise<string> => {
    const { data, error } = await PaymentsService.getClientPaypalSecret();
    if (!data) {
      throw new Error(error || 'Payment Secret not found.');
    }
    return data?.token;
  };

  /**
   * Handles the approval of a payment method.
   * This function dispatches the payment creation action and shows a notification.
   * @param {Object} data - Data returned from PayPal after the card is processed
   */
  const onApprove = async (data: any & { vaultSetupToken?: string }): Promise<void> => {
    if (!data?.vaultSetupToken) {
      setError('PayPal setup token not yet created');
      return;
    }

    setError(() => '');

    const createdPayload = await dispatch(createPaymentCard({
      si: data?.vaultSetupToken
    }));

    if (createdPayload.payload?.card) {
      dispatch(openNotification({ type: 'success', description: 'Payment method successfully added!' }));
    }
    setIsCreating(false);
  }

  /**
   * Handles errors during the PayPal process and sets an error message.
   * @param {Record<string, unknown>} error - Error object returned by PayPal SDK
   */
  const onError = async (error: Record<string, unknown>): Promise<void> => {
    console.log('error', error);
    setError(error?.error as string || 'Something went wrong, try again');
  }

  /**
   * Handles changes in the card fields, such as number, expiry, and CVV.
   * Updates the card state and checks if the form is complete and valid.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputEventsChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when an input changes
    setIsCardComplete(data.isFormValid);
    setCardState(data);
  }

  /**
   * Handles when a card field gets focus (e.g., the user clicks on a field).
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputFocusChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when a field gets focus
    // console.log('onFocus inputEvents', data);
  }

  /**
   * Handles when a card field loses focus (e.g., the user clicks out of a field).
   * Updates the card state.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputBlurChange = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // Do something when a field loses focus
    // console.log('onBlur inputEvents', data);
    setCardState(data);
  }

  /**
   * Handles when the card form is submitted.
   * Verifies if the card form is valid before submission.
   * @param {PayPalCardFieldsStateObject} data - State of the card fields
   */
  const onInputSubmitRequest = async (data: PayPalCardFieldsStateObject): Promise<void> => {
    // console.log('onInputSubmitRequest inputEvents', data);
    if (data.isFormValid) {
      // Submit the card form for the payer
    } else {
      // Inform payer that some fields are not valid
    }
  }

  return (
    <PayPalScriptProvider options={ CPayPalScriptProviderOptions }>
      {/*@ts-ignore*/ }
      <PayPalCardFieldsProvider
        onApprove={ onApprove }
        createVaultSetupToken={ createVaultSetupToken }
        onError={ onError }
        inputEvents={ {
          onChange: onInputEventsChange,
          onFocus: onInputFocusChange,
          onBlur: onInputBlurChange,
          onInputSubmitRequest: onInputSubmitRequest
        } }
        style={ CPayPalStylesOptions }
      >
        <div className="add-payment-paypal">
          <div className="add-payment-paypal--header">
            <div className="header-title">
              Add Payment Method
            </div>
            <div className="header-subtitle">
              Please, enter Payment Method Details below.
            </div>
          </div>
          <div className="add-payment-paypal--form">
            <CardFields cardState={ cardState }/>
          </div>
          <div className="add-payment-paypal--error" hidden={ !error }>
            <InfoMessage infoMessage={ error } type={ 'error' }/>
          </div>
          <div className="add-payment-paypal--actions">
            <SubmitPaymentPaypal disabled={ !isCardComplete } isProcess={ isCreating } setIsProcess={ setIsCreating }/>
          </div>
          <div className="add-payment-paypal--footer">
            <PaypalFooter/>
          </div>
        </div>
      </PayPalCardFieldsProvider>
    </PayPalScriptProvider>
  );
}
