import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

// Styles
import './messages.scss';

// Components
import { MessagesBody } from '../../shared/ui-components/messages';

// Store entities
import { clearMessages, fetchChatInfo } from '../../store/messages/messages.thunks';
import { getCurrentExpertState } from '../../store/currentExpert/currentExpert.selectors';

export const Messages = () => {
  const [currentId, setCurrentId] = useState<string | undefined>('');

  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  // const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);
  // const token = localStorage.getItem('token');

  const { expert } = useSelector(getCurrentExpertState);

  const loadChatInfo = (): void => {
    if (!currentId) {
      return;
    }
    dispatch(fetchChatInfo({
      worker_id: currentId,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      ...(searchParams.has('after') && { limit: (Number(searchParams.get('after')) || 15) + 5 }),
    }));
  }

  useEffect(() => {
    // if (params?.id === currentId) return;
    // if (!token && companyCode && expert?.id) {
    //   setCurrentId('' + expert.id);
    //   return;
    // }
    // setCurrentId(params?.id);
    setCurrentId(params?.id || `${ expert?.id }`);
  }, [params, expert]);

  useEffect(() => {
    loadChatInfo();
  }, [currentId]);

  useEffect(() => {
    return () => {
      dispatch(clearMessages());
    }
  }, []);

  return (<div className="messages">
    <MessagesBody />
  </div>);
}
