import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

// Styles
import './links-in.scss';

// Shared UI Library Components
import { CustomButton, Loader, UserAvatar } from "@ppmcore/seven-ppm-core-shared-components-react";

// Components
import { AuthSteps } from "./auth-steps/auth-steps";
// import { AppointmentsIn } from "./appointments-in/appointments-in";
// import { Requests } from "./requests/requests";
// import { InstantCalls } from "./instant-calls/instant-calls";

// Store entities
import { assignNewCompanyThunk, fetchTempUserCompany, validateUserToken } from "../../store/core/core.thunks";
import { fetchExpertTempUserData } from "../../store/currentExpert/currentExpert.thunks";
import { getCurrentExpertState } from "../../store/currentExpert/currentExpert.selectors";
import { getUserProfile, updateLastCompany } from "../../store/user/user.thunks";
import { getGlobalConfigState } from "../../store/global-config/global-config.selectors";

// Models
import { TLinksInAppointmentsType, TLinksInType } from "../../types/links-in.types";
import { ELocalStorageKeys } from "../../enums/storage.enums";
import { TCallType } from "../../interfaces/call.interfaces";
import { IExpertProfile } from "../../interfaces/experts.interfaces";
import { ISingleCompany } from "../../interfaces/core.interfaces";

// Mapping for link types to corresponding call types
const keyTypes: { [key in TLinksInType]: TCallType | '' } = {
  voice: 'audio_call',
  video: 'video_call',
  'text-chat': 'conversation',
  'appointments': '',
  'messages': 'message',
};

type TLinksInProps = {};

/**
 * Main LinksIn component
 * This component handles user authorization, routing to different consultation types, and renders UI components
 * based on the user's authentication status.
 */
export const LinksIn: FC<TLinksInProps> = (
  {}: TLinksInProps
) => {
  const companyRef = useRef<ISingleCompany | null>(null);
  const expertRef = useRef<IExpertProfile | null>(null);

  const [authorizeState, setAuthorizeState] = useState<boolean | null>(null);
  const [unavailableService, setUnavailableService] = useState<TLinksInType | null>(null);

  const { expert } = useSelector(getCurrentExpertState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const token = localStorage.getItem(ELocalStorageKeys.Token);
  const temporaryToken = localStorage.getItem(ELocalStorageKeys.TemporaryToken);

  const params = useParams<{ companyCode: string, personalLink: string, serviceType: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  // TODO for future ui updates
  // const ContentBlock = useMemo(() => {
  //   if (!linkInType) return <></>;
  //   if (linkInType === 'appointments') return <AppointmentsIn/>;
  //   if (linkInType === 'messages') return <Requests/>;
  //   return <InstantCalls/>
  // }, [linkInType]);

  // Memoized title based on the expert's name and service type
  const redirectTitle = useMemo(() => {
    if (!expert || !params?.serviceType) return `Start a Paid ${ businessTerms.Consultation }`;
    const expertName = `${ expert.first_name } ${ expert.last_name }`;
    const serviceTitles: { [key in TLinksInType]: () => string } = {
      video: () => `Start a Paid Video ${ businessTerms.Consultation } with ${ expertName }`,
      voice: () => `Start a Paid Voice ${ businessTerms.Consultation } with ${ expertName }`,
      'text-chat': () => `Start a Paid Text Chat ${ businessTerms.Consultation } with ${ expertName }`,
      messages: () => `Start a Paid Request with ${ expertName }`,
      appointments: () => `Book an Appointment with ${ expertName }`,
    };
    return serviceTitles[params?.serviceType as TLinksInType]?.();
  }, [params, expert, businessTerms]);

  // Memoized title based on the service type and service availability
  const unavailableTitle = useMemo(() => {
    if (!unavailableService || !expert) return '';
    const expertName = `${ expert.first_name } ${ expert.last_name }`;
    const unavailableTitles: { [key in TLinksInType]: () => string } = {
      video: () => `Start a Paid Video ${ businessTerms.Consultation } temporarily unavailable with ${ expertName }`,
      voice: () => `Start a Paid Voice ${ businessTerms.Consultation } temporarily unavailable with ${ expertName }`,
      'text-chat': () => `Start a Paid Text Chat ${ businessTerms.Consultation } temporarily unavailable with ${ expertName }`,
      messages: () => `Start a Paid Request temporarily unavailable with ${ expertName }`,
      appointments: () => `Sorry, booking an appointment in this category with ${ expertName } is temporarily unavailable`,
    };
    return unavailableTitles[unavailableService]?.();
  }, [expert, unavailableService]);

  /**
   * Validate if the service type is correct.
   * @param type - service type from the route parameter
   * @returns boolean - if the type is valid or not
   */
  const isValidLinkInType = (type: string | undefined): boolean => {
    if (!type) return false;
    return ['video', 'voice', 'text-chat', 'messages', 'appointments']
      .some((linkIn) => linkIn === type);
  }

  /**
   * Check if the appointment type is valid.
   * @param type - appointment type as a URL parameter
   * @returns boolean - if the type is valid
   */
  const isValidAppointmentType = (type: string | undefined): boolean => {
    if (!type) return false;
    return ['video', 'voice', 'text-chat']
      .some((linkIn) => linkIn === type);
  }

  /**
   * Check if the appropriate service is available based on the type of service provided.
   */
  const isServiceAvailable = (linkInType: TLinksInType): boolean => {
    if (!expertRef.current) return false;

    const expert = expertRef.current;

    const typeCheckMap: { [key in TLinksInType]: () => boolean } = {
      voice: () => expert.is_audio_call_enabled,
      video: () => expert.is_video_call_enabled,
      'text-chat': () => expert.is_conversation_enabled,
      messages: () => expert.is_messages_enabled,
      appointments: () => {
        const urlParam = (location.hash || '').slice(1);
        const appointmentType = isValidAppointmentType(urlParam) ? urlParam as TLinksInAppointmentsType : '';
        const appointmentChecks: { [key in TLinksInAppointmentsType]: () => boolean } = {
          voice: () => !!expert.session_rates?.audio_call_per_session_rate?.length,
          video: () => !!expert.session_rates?.call_per_session_rate?.length,
          'text-chat': () => false // TODO for future updates
        };
        return appointmentType ? appointmentChecks[appointmentType]() : false;
      }
    };

    return typeCheckMap[linkInType] ? typeCheckMap[linkInType]() : true;
  }

  /**
   * Navigate to the expert profile.
   */
  const navigateToExpertProfile = (): void => {
    navigate(`/${ params.companyCode }/${ params.personalLink }`);
  }

  /**
   * Navigate to the appropriate service based on the provided service type.
   */
  const navigateToService = (): void => {
    const linkInType = params?.serviceType as TLinksInType;
    const isAvailable = isServiceAvailable(linkInType);

    if (!isAvailable) {
      setUnavailableService(linkInType);
      return;
    }

    let replacedUrl = '';

    // Save parameters in localStorage for session continuity
    if (['video', 'voice', 'text-chat'].includes(linkInType)) {
      localStorage.setItem('return_pathname', `/${ params.companyCode }/${ params.personalLink }`);
      localStorage.setItem('call_type', keyTypes[linkInType]);
      localStorage.setItem('worker_id', expertRef.current?.id + '');
    }

    // Navigate to expert profile
    if (['video', 'voice', 'text-chat'].includes(linkInType) && !expertRef.current?.id) {
      return navigate(`/${ params.companyCode }/${ params.personalLink }`);
    }

    // Navigate to devices settings
    if (['video', 'voice'].includes(linkInType)) {
      return navigate('/call/devices-settings');
    }

    // Navigate to chat type selection if it’s a text chat
    if (linkInType === 'text-chat') {
      localStorage.removeItem('call_type');
      return navigate('/conversation/chat-types');
    }

    // Determine appropriate URL for other types
    if (linkInType === 'messages') {
      replacedUrl = 'messages';
    }

    // Appointment type handling with optional hash parameter
    if (linkInType === 'appointments') {
      const urlParam = (location.hash || '').slice(1);
      const appointmentType = isValidAppointmentType(urlParam) ? urlParam as TLinksInAppointmentsType : '';
      replacedUrl = `book-appointment${ appointmentType ? `#${ appointmentType }` : '' }`;
    }

    // Navigate to the appropriate route
    navigate(`/${ params.companyCode }/${ params.personalLink }/${ replacedUrl }`);
  }

  /**
   * Assigns the current company to the user profile.
   * @returns boolean - if the assignment was successful
   */
  const assignCurrentCompanyToUser = async (): Promise<boolean> => {
    if (!companyRef.current?.company_profile) return false;

    await dispatch(assignNewCompanyThunk({ company_code: params.companyCode as string }));

    const { payload: user } = await dispatch(getUserProfile(false));

    if (user?.last_used_company === companyRef.current?.company_profile.id) return false;

    await dispatch(updateLastCompany(companyRef.current?.company_profile.id));
    return true;
  }

  /**
   * Checks the user authorization state by validating token availability.
   */
  const checkUserAuthorizeState = async (): Promise<void> => {
    const isTokenAvailable = !!token;
    const isTemporaryTokenAvailable = !!temporaryToken;

    let isValidByServer = false;

    // Token validation with server
    if (isTokenAvailable || isTemporaryTokenAvailable) {
      const { payload } = await dispatch(validateUserToken());
      isValidByServer = payload;
    }

    // Assign company if token is valid
    if (isTokenAvailable && isValidByServer) {
      await assignCurrentCompanyToUser();
    }

    const authorizeState = (isTokenAvailable || isTemporaryTokenAvailable) && isValidByServer;

    setAuthorizeState(authorizeState);

    if (!authorizeState) {
      return;
    }

    // If authorized, proceed with service navigation after a delay
    const timerId = setTimeout(() => {
      navigateToService();
      clearTimeout(timerId);
    }, 2000);
  }

  /**
   * Retrieves company and expert data based on URL parameters.
   */
  const getLinkInParams = async (): Promise<void> => {
    if (!params?.serviceType || !isValidLinkInType(params?.serviceType)) return navigate('/404');

    // Fetch data for company and expert in parallel
    const [companyRes, expertRes] = await Promise.allSettled([
      dispatch(fetchTempUserCompany({
        company_code: params?.companyCode as string
      })),
      dispatch(fetchExpertTempUserData({
        company_code: params?.companyCode as string,
        worker_code: params?.personalLink as string
      }))
    ]);

    // Update references with fetched data
    if (expertRes.status === 'fulfilled') {
      expertRef.current = expertRes.value?.payload;
    }

    if (companyRes.status === 'fulfilled') {
      companyRef.current = companyRes.value?.payload;
    }

    // Check authorization state after data retrieval
    checkUserAuthorizeState();
  }

  // Load parameters on initial render
  useEffect(() => {
    getLinkInParams();
  }, []);

  return (<div className="links-in">
    <div className="links-in--header">
      <img className="logo" src="/assets/images/logo.svg" alt="paypertok-logo"/>
    </div>

    { expert && <div className="links-in--counsellor">
      <UserAvatar avatarUrl={ expert.photo } sizeClass={ 'large' }/>
    </div> }

    { (expert && !unavailableService) && <div className="links-in--title">
      { redirectTitle }
    </div> }

    { unavailableService && <div className="links-in--title">
      { unavailableTitle }
    </div> }

    <div className="links-in--body">
      { (authorizeState === false && params.companyCode) &&
        <AuthSteps companyCode={ params.companyCode }
                   onRedirectToService={ navigateToService }
        />
      }

      { (authorizeState !== false && params.companyCode && !unavailableService) &&
        <Loader size={ 'large' }/>
      }

      { (unavailableService) &&
        <CustomButton text={ `Go to ${ businessTerms.Consultants_S } profile` }
                      type={ 'primary' }
                      onClick={ navigateToExpertProfile }/>
      }

      {/* TODO for future ui updates*/ }
      {/*{ authorizeState === true && ContentBlock }*/ }
    </div>
  </div>);
}
