import { IIllustrations } from "../interfaces/illustrations.interfaces";

export const CIllustrationDefault: IIllustrations = {
  main: '/assets/icons/big/welcome-body.svg',
  notifications: '/assets/icons/big/notifications-body.svg',
  favourites: '/assets/icons/big/favorites-body.svg',
  history: '/assets/icons/big/history-body.svg',
  counsellor_history: '/assets/icons/big/history-body.svg',
  transactions: '/assets/icons/big/no-transactions.svg',
  correspondence: '/assets/icons/big/correspondence.svg',
  rates: '/assets/icons/big/rates-empty.svg',
  profile: '/assets/icons/big/about-empty.svg',
  business_information: '/assets/icons/big/business-info-empty.svg',
  welcome: '/assets/icons/big/auth.svg',
}
