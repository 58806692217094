import { ELocalStorageKeys } from "../enums/storage.enums";

/**
 * Extends the `localStorage` object with a `smartClear` method.
 * This method clears all items from localStorage except for those specified in the `keysToKeep` array.
 * By default, it also retains predefined keys such as `Illustrations`, `BusinessTerms`, and `Company`.
 */
export const extendSmartClear = () => {
  // Check if `smartClear` is already defined in Storage.prototype to avoid redefinition
  if ('smartClear' in Storage.prototype) return;

  /**
   * Adds the `smartClear` method to the Storage prototype.
   * The method clears all keys in `localStorage`, except for the ones specified in the `keysToKeep` parameter and the predefined ones.
   *
   * @param {string | string[]} [keysToKeep] - A string representing one key or an array of keys to keep in localStorage.
   * If no argument is provided, it will retain only predefined keys.
   */
  Object.defineProperty(Storage.prototype, 'smartClear', {
      value: function (keysToKeep?: string | string[]): void {
        // Get all keys from localStorage
        const allKeys = Object.keys(localStorage);

        // If no parameters are passed, default to an empty array, meaning no user-specified keys to keep
        if (!keysToKeep) {
          keysToKeep = [];
        }

        // If a single string key is passed, convert it to an array for consistent processing
        if (typeof keysToKeep === 'string') {
          keysToKeep = [keysToKeep];
        }

        // Merge the user-specified keys with the predefined ones
        keysToKeep = keysToKeep.concat(
          ELocalStorageKeys.Illustrations,
          ELocalStorageKeys.BusinessTerms,
          ELocalStorageKeys.Company
        );

        // Iterate over all localStorage keys
        allKeys.forEach((key) => {
          // If the current key is in the keysToKeep array, skip deletion
          if (keysToKeep!.includes(key)) return;

          // Remove the key from localStorage
          localStorage.removeItem(key);
        });
      },
      writable: true, // Allow the method to be overwritten if necessary
      configurable: true, // Allow the property to be reconfigured or deleted
      enumerable: false // Prevent the method from showing up in property enumerations
    }
  )
}
