import React, { FC, HTMLProps, ReactNode } from 'react';
import dayjs from 'dayjs';

import { Statistic } from 'antd';

// Shared UI Library Components
import { CustomButton, CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './consultation.scss';

// Components
import { MessagesList } from '../../chat';

// Models
import { IHistory } from '../../../../interfaces/history.interfaces';
import { IKeywords } from "../../../../interfaces/keywords.interfaces";

const { Countdown } = Statistic;

type TConsultationProps = HTMLProps<HTMLDivElement> & {
  history: IHistory;
  businessTerms: IKeywords;
}

export const Consultation: FC<TConsultationProps> = (
  {
    history: {
      id,
      consultation_type,
      chat_messages,
      created_at,
      call_duration,
      total,
      user,
      worker,
      call_status,
      can_join_call,
      finished_after,
      free_status,
      free_duration,
    }, businessTerms, ...props
  }: TConsultationProps
) => {

  const isEmptyMessages = !chat_messages?.length;

  const getTitleOfType = (): ReactNode => {
    if (consultation_type === 'video_call') {
      return <>
        <CustomIcon name={ EIconName.CameraOn }
                    size={ 'size-md' }/>{ can_join_call ? `Ongoing ${ businessTerms.Consultation }` : `Video ${ businessTerms.Consultation }` }
      </>;
    }
    if (consultation_type === 'audio_call') {
      return <>
        <CustomIcon name={ EIconName.MicOn }
                    size={ 'size-md' }/>{ can_join_call ? `Ongoing ${ businessTerms.Consultation }` : `Voice ${ businessTerms.Consultation }` }
      </>;
    }
    return <>
      <CustomIcon name={ EIconName.Message }
                  size={ 'size-md' }/>Correspondence { businessTerms.Consultation }
    </>;
  }

  const getStatusField = (): ReactNode => {
    if (call_status === 'not_answered') {
      return 'No answer';
    }
    if (call_status === 'canceled') {
      return 'Canceled';
    }
    if (call_status === 'rejected') {
      return 'Rejected';
    }
    return call_duration;
  }

  const rejoinActionHandler = (): void => {
    if (!can_join_call) return;
    window.location.assign(`/call/${ id }`);
  }

  return (
    <div className={ `consultation ${ isEmptyMessages ? 'empty-messages' : '' }` } { ...props }>
      <div className="consultation--header">
        <div className="header-item">
          { dayjs(created_at).format('hh:mm A') }
        </div>
        <div className="header-item has-divider">
          <span>
            { getTitleOfType() }
          </span>
        </div>
        <div className="header-item has-divider">
          { getStatusField() }
        </div>
        <div className={ `header-item ${ free_status !== 'not_free' ? 'has-divider' : '' }` }>
          { total ?? '-' }
        </div>
        { free_status !== 'not_free' && <div className="header-item">
          Free: { free_duration }
        </div> }
      </div>
      { !isEmptyMessages && <div className="consultation--chat">
        <MessagesList messages={ chat_messages } user={ user } worker={ worker }/>
      </div> }
      {/*TODO rejoin-flow comments */ }
      { can_join_call && <div className="consultation--join">
        <div className="join-message">
          You have <Countdown value={ finished_after } format="HH:mm:ss"/> to rejoin { businessTerms.Consultation }
        </div>
        <div className="join-action">
          <CustomButton onClick={ rejoinActionHandler }>Rejoin</CustomButton>
        </div>
      </div> }
    </div>
  );
};
