import React, { useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './counsellor-layout.scss';

// Components
import { AsideMenu } from './components/aside-menu/aside-menu';
import { ExpertProfileHeader } from '../../shared/ui-components/expert';

// Store Entities
import { getCurrentExpertState } from '../../store/currentExpert/currentExpert.selectors';
import {
  clearCurrentExpertState,
  fetchExpertData,
  fetchExpertTempUserData
} from '../../store/currentExpert/currentExpert.thunks';
import { getSidebarState } from '../../store/sidebar/sidebar.selectors';
import { getGlobalConfigState } from "../../store/global-config/global-config.selectors";

type TMainLayoutProps = {};

export const CounsellorLayout = ({}: TMainLayoutProps) => {

  const { expert, loading } = useSelector(getCurrentExpertState);
  const { type: sidebarType } = useSelector(getSidebarState);
  const { businessTerms } = useSelector(getGlobalConfigState);

  const params = useParams<{ id: string, companyCode?: string, personalLink?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const currentId = useMemo(() => params?.id, [params?.id]);
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);
  const personalLink = useMemo(() => params?.personalLink, [params?.personalLink]);

  const loadExpertData = (id: string): void => {
    dispatch(fetchExpertData(id))
      .then((res: any) => {
        const statusCode = res?.payload?.response?.status;
        if (statusCode === 422) return navigate('/inactive-profile');
        if (statusCode && (statusCode < 200 || statusCode >= 300)) return navigate('/404');
      });
  }

  const loadExpertTempUserData = (companyCode: string, personalLink: string): void => {
    dispatch(fetchExpertTempUserData({ company_code: companyCode, worker_code: personalLink }))
      .then((res: any) => {
        const statusCode = res?.payload?.response?.status;
        if (statusCode === 422) return navigate('/inactive-profile');
        if (statusCode && (statusCode < 200 || statusCode >= 300)) return navigate('/404');
      });
  }

  useEffect(() => {
    if (currentId) {
      loadExpertData(currentId);
    } else if (companyCode && personalLink) {
      loadExpertTempUserData(companyCode, personalLink);
    }
  }, [currentId, companyCode, personalLink]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentExpertState());
    }
  }, []);

  return (
    <div className="counsellor-layout">

      <div className="counsellor-layout--header">
        { expert && <ExpertProfileHeader expert={ expert } businessTerms={ businessTerms } sidebarType={ sidebarType }/> }
      </div>

      {
        loading &&
        <div className={ `counsellor-layout--loader counsellor-layout--loader-${ loading ? 'show' : 'hide' }` }>
          <SpinElement fullHeight={ true }/>
        </div>
      }

      <div className="counsellor-layout--content">
        <div className="content-aside">
          { expert && <AsideMenu expert={ expert }/> }
        </div>
        { !loading && expert && <div className="content-body">
          <Outlet/>
        </div> }
      </div>

    </div>
  );
}
