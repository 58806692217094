import React from 'react';

// Components
import { Footer } from '../../modules/footer/footer';

// Styles
import './inactive-profile.scss';

export const InactiveProfile = () => {

  return (<div className={ 'inactive-profile' }>
    <div className={ 'inactive-profile-inside' }>
      <div className={ 'logo-section' }>
        <img className={ 'logo' } src={ '/assets/images/logo.svg' } alt={ 'paypertok-logo' }/>
      </div>
      <div className={ 'inactive-profile-content' }>
        <div className={ 'subtitle' }>The profile you are trying to open is currently Inactive</div>
        <div className={ 'text' }></div>
      </div>
    </div>
    <Footer/>
  </div>);
}
